import { Filters } from '@/static/enums/case';
import TagApi from '../../api/Tag';

export default {
  name: 'FilterList',
  props: {
    currentFilter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filterList: Object.values(Filters),
      Filters,
    };
  },
  created() {
    this.updateCounts();
  },
  methods: {
    applyFilter(type) {
      this.$router.push({ name: 'Dashboard', params: { filter: type } });
    },
    updateCounts() {
      TagApi.fetchTagCounts().then((response) => {
        const { data } = response;
        let count;
        this.filterList = this.filterList.map((filterItem) => {
          count = data.find((tagItem) => {
            if (filterItem.tag === undefined) {
              return tagItem.value === 0;
            }
            return tagItem.value === filterItem.tag;
          });
          return {
            ...filterItem,
            count: count === undefined ? 0 : count.count,
          };
        });
      });
    },
  },
};
