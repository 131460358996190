import req from '../lib/req';
import { TYPE_TARN_LIST } from '../../static/enums/query';

export function tarnEdit(id) {
  return req.get(
    `/query/tarn-edit?where={"enforcementCase": "${id}"}&order[createdAt]=desc`
  );
}
export function queryReceivedResponses() {
  return req.post('/query/received-responses');
}

// eslint-disable-next-line camelcase
export function documentTemplate(type, caseId, text, bank_id, file_type) {
  return req.post('/query/document-template', {
    enforcementCase: caseId,
    request: {
      html: text,
      type,
      bank_id,
      file_type,
    },
  });
}

export function getQueryInfo(type) {
  return req.get(`/info/query/${type}`);
}

export function findByIds(idList) {
  return req.get('/query', {
    params: {
      idList,
    },
  });
}
export function retry(id) {
  return req.post(`query/${id}/retry`);
}
export function fetchByParams(params) {
  return req.get('/query', {
    params,
  });
}
export function findTarnCases() {
  return req.post('/query/tarn', {
    type: TYPE_TARN_LIST,
  });
}
