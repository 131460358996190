import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';
import Semantic from 'semantic-ui-vue';
import VueGmaps from 'vue-gmaps';
import vue2Dropzone from 'vue2-dropzone';
import Multiselect from 'vue-multiselect';
import ToggleButton from 'vue-js-toggle-button';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import TreeView from 'vue-json-tree-view';
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import ScrollPanel from 'primevue/scrollpanel';
import TabMenu from 'primevue/tabmenu';
import Menu from 'primevue/menu';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea/Textarea';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from 'primevue/confirmpopup';
import router from './router';
import store from './store';
import App from './App.vue';
import Page from './components/Page/Page.vue';
import SidePanel from './components/SidePanel/SidePanel.vue';
import Funds from './components/Funds/Funds.vue';
import Search from './components/Search/Search.vue';
import ColumnHeader from './components/ColumnHeader/ColumnHeader.vue';
import translationsEE from './static/translations/translations_EE.json';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import './assets/styles/layout.scss';
import './styles/table.css';
import 'vue2-datepicker/index.css';
import 'primeflex/primeflex.css';

Vue.config.productionTip = false;
Vue.use(PrimeVue, {
  locale: {
    accept: 'Nõustu',
    reject: 'Keeldu',
    choose: 'Vali',
    upload: 'Lae üles',
    cancel: 'Katkesta',
    dayNames: [
      'Pühapäev',
      'Esmaspäev',
      'Teisipäev',
      'Kolmapäev',
      'Neljapäev',
      'Reede',
      'Laupäev',
    ],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
    today: 'Täna',
    clear: 'Puhasta',
    weekHeader: 'Wk',
    firstDayOfWeek: 1,
    dateFormat: 'dd.mm.yy',
    weak: 'Nõrk',
    medium: 'Keskmine',
    strong: 'Tugev',
    passwordPrompt: 'Sisestage parool',
    monthNames: [
      'Jaanuar',
      'Veebruar',
      'Märts',
      'Aprill',
      'Mai',
      'Juuni',
      'Juuli',
      'August',
      'September',
      'Oktoober',
      'November',
      'Detsember',
    ],
    monthNamesShort: [
      'jaan',
      'veebr',
      'märts',
      'apr',
      'mai',
      'juuni',
      'juuli',
      'aug',
      'sept',
      'okt',
      'nov',
      'dets',
    ],
  },
});
Vue.use(Semantic);
Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.use(ConfirmationService);
Vue.use(ToggleButton);
Vue.use(TreeView);
Vue.use(ToastService);

Vue.component(Page.name, Page);
Vue.component(SidePanel.name, SidePanel);
Vue.component(Funds.name, Funds);
Vue.component(Search.name, Search);
Vue.component(ColumnHeader.name, ColumnHeader);
Vue.component(VueGmaps.name, VueGmaps);
Vue.component('VueDropzone', vue2Dropzone);
Vue.component('Button', Button);
Vue.component('Calendar', Calendar);
Vue.component('Column', Column);
Vue.component('DataTable', DataTable);
Vue.component('ConfirmPopup', ConfirmPopup);
Vue.component('Dialog', Dialog);
Vue.component('Divider', Divider);
Vue.component('Dropdown', Dropdown);
Vue.component('InputNumber', InputNumber);
Vue.component('InputSwitch', InputSwitch);
Vue.component('Multiselect', Multiselect);
Vue.component('InputText', InputText);
Vue.directive('tooltip', Tooltip);
Vue.component('TabMenu', TabMenu);
Vue.component('Checkbox', Checkbox);
Vue.component('ScrollPanel', ScrollPanel);
Vue.component('Panel', Panel);
Vue.component('Menu', Menu);
Vue.component('TextArea', Textarea);
Vue.component('Toast', Toast);
Vue.use(VueGmaps, {
  key: 'AIzaSyAUfKvTGLdzXU35_fXZRHOZPWcvUAsnusU',
  loadGoogleApi: false,
});
Vue.filter('str_limit', (value, size) => {
  if (!value) return '';
  const newValue = value.toString();

  if (newValue.length <= size) {
    return newValue;
  }
  return `${newValue.substr(0, size)}...`;
});

export const i18n = new VueI18n({
  locale: 'ee',
  messages: {
    ee: translationsEE,
  },
});

export const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
});

export default app;
