export function validateClaim(data) {
  if (
    data.sumCharged.cents &&
    data.sumFull.cents &&
    data.sumCharged.cents > data.sumFull.cents
  ) {
    return 'validation.sumChargedBiggerThanSumFull';
  }
  if (data.type === null || data.type === undefined) {
    return 'validation.claim.typeNull';
  }

  return '';
}
