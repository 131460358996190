import req from '../lib/req';

export function findById(id) {
  return req.get(`/address/${id}`);
}

export function getAddresses(idList) {
  return req.get('/address', {
    params: {
      id: idList,
    },
  });
}

export function create(data) {
  return req.post('/address', data);
}

export function update(id, data) {
  return req.put(`/address/${id}`, data);
}
