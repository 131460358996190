import req from './lib/req';

export default {
  fetch(id) {
    return req.get(`/bureau/${id}`);
  },
  update(id, data) {
    return req.put(`/bureau/${id}`, data);
  },
};
