// @flow

export default {
  name: 'UiSidePanel',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
};
