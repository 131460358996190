import ClassifierApi from '../../api/classifier';

const FETCHING_CLASSIFIERS = 'FETCHING_CLASSIFIERS';
const FETCHING_CLASSIFIERS_SUCCESS = 'FETCHING_CLASSIFIERS_SUCCESS';
const FETCHING_CLASSIFIERS_ERROR = 'FETCHING_CLASSIFIERS_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    [FETCHING_CLASSIFIERS](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_CLASSIFIERS_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCHING_CLASSIFIERS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit(FETCHING_CLASSIFIERS);
      try {
        const { data } = await ClassifierApi.findAll();
        commit(FETCHING_CLASSIFIERS_SUCCESS);
        return data;
      } catch (e) {
        commit(FETCHING_CLASSIFIERS_ERROR, e);
        return null;
      }
    },
    async findOne({ commit }, id) {
      commit(FETCHING_CLASSIFIERS);
      try {
        const { data } = await ClassifierApi.findOne(id);
        commit(FETCHING_CLASSIFIERS_SUCCESS);
        return data;
      } catch (e) {
        commit(FETCHING_CLASSIFIERS_ERROR, e);
        return null;
      }
    },
  },
};
