<template>
  <div>
    <DataTable
      id="arrestTable"
      :value="arrests"
      :expanded-rows.sync="expandedRows"
      data-key="id"
      :selection.sync="selectedRows"
      :loading="loading"
      :lazy="true"
      :row-class="rowClass"
    >
      <template #expansion="slotProps">
        <div class="errorColor">
          <i
            v-tooltip.right="{
              value: `Viga arestimisel või aresti muutmisel asutusse &quot;${slotProps.data.bank_name}&quot;`,
            }"
            class="pi pi-exclamation-circle"
          ></i>
          &nbsp;
          <strong>{{ slotProps.data.lastTransitionError }}</strong>
        </div>
      </template>
      <Column :selection-mode="'multiple'" header-style="width: 3em">
        <template #body="slotProps">
          <Checkbox
            v-model="slotProps.data.selected"
            v-tooltip="{
              value: getUnArrestableTooltip(slotProps.data),
              disabled: !arrestActionInProgress(slotProps.data),
            }"
            :disabled="arrestActionInProgress(slotProps.data)"
            :binary="true"
          />
        </template>
      </Column>
      <Column :header="$t('case.properties.establishment')">
        <template #body="slotProps">
          {{ slotProps.data.bank_name || '[ määramata ]' }}
        </template>
      </Column>
      <Column v-if="isRoleKoda">
        <template #body="slotProps">
          <Button
            v-if="
              slotProps.data.id &&
              slotProps.data.target === ArrestTarget.EARREST
            "
            label="Muuda"
            @click="openChangeArrestDialog(slotProps.data)"
          />
        </template>
      </Column>
      <Column v-if="editMode" :header="$t('case.properties.person')">
        <template #body="slotProps">
          {{ slotProps.data.person }}
        </template>
      </Column>
      <Column v-if="editMode" :header="$t('case.properties.status')">
        <template #body="slotProps">
          <ArrestStatusColumn :row-data="slotProps.data" />
        </template>
      </Column>
      <Column :header="$t('case.properties.totalSum')">
        <template #body="slotProps">
          <CurrencyInputNumber
            :value="slotProps.data.arrest_sum"
            @input="updateArrestSums"
          />
        </template>
      </Column>
      <Column :header="$t('case.properties.amountOfMinWages')">
        <template #body="slotProps">
          <div
            v-tooltip="{
              value: $t('arrest.table.field_not_usable'),
              disabled: !isEpisArrest(slotProps.data),
            }"
          >
            <Dropdown
              v-model="slotProps.data.amount_of_min_wages"
              :options="getMinWagesOptions(slotProps.data)"
              place-holder="Vali või sisesta"
              :disabled="isEpisArrest(slotProps.data)"
              option-label="key"
              option-value="id"
              data-key="id"
              :filter="true"
              style="width: 100%"
              @input="handleAmountMinWagesNumber(slotProps.data)"
              @filter="handleMinWageInput"
            />
          </div>
        </template>
      </Column>
      <Column :header="$t('case.properties.sumNotArrested')">
        <template #body="slotProps">
          <CurrencyInputNumber
            v-model="slotProps.data.sum_not_arrested"
            v-tooltip="{
              value: $t('arrest.table.field_not_usable'),
              disabled: !isEpisArrest(slotProps.data),
            }"
            :disabled="isEpisArrest(slotProps.data)"
          />
        </template>
      </Column>
      <Column :header="$t('arrest.pre_arrest')">
        <template #body="slotProps">
          <Checkbox
            v-model="slotProps.data.preArrest"
            v-tooltip="{
              value: getPreArrestTooltip(slotProps.data),
              disabled: applicableForPreArrest(slotProps.data),
            }"
            :disabled="!applicableForPreArrest(slotProps.data)"
            :binary="true"
          />
        </template>
      </Column>
      <Column :header="$t('case.properties.curArrestSum')">
        <template #body="slotProps">
          <p style="margin-bottom: 0">
            {{ getPreviousArrestSum(slotProps.data) }}
          </p>
          <p
            v-if="slotProps.data.arrest_type === TYPE_WITHOUT_PAYMENT_ORDER"
            class="td-sub"
          >
            {{ slotProps.data.arrest_type }}
          </p>
        </template>
      </Column>
      <Column :header="$t('case.properties.amountOfMinWages')">
        <template #body="slotProps">
          {{ getAmountOfMinWages(slotProps.data) }}
        </template>
      </Column>
      <Column :header="$t('case.properties.sumNotArrested')">
        <template #body="slotProps">
          {{ getSumNotArrested(slotProps.data) }}
        </template>
      </Column>
      <Column :header="''">
        <template #body="slotProps">
          <Button
            v-if="slotProps.data.query_ids && slotProps.data.query_ids.length > 0"
            label="Päringud"
            @click="toggleQueriesModal(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <arrest-query-modal
      v-if="displayQueriesModal"
      :arrest="selectedArrest"
      :visible="displayQueriesModal"
      @toggle="toggleQueriesModal"
    />
    <UpdateArrestModal
      v-if="displayUpdateModal && selectedArrest"
      :arrest="selectedArrest"
      :visible="displayUpdateModal"
      @refetchArrests="$emit('refetchArrests')"
      @close="closeChangeArrestDialog"
    />
  </div>
</template>

<script>
import CurrencyInputNumber from '../../../../../components/CurrencyInputNumber.vue';
import {
  Errors,
  ActiveStatuses,
  TYPE_WITHOUT_PAYMENT_ORDER,
  ArrestState,
  ArrestActionInProgressStates,
} from '@/static/enums/arrest';
import { getFormattedDateTime, getZonedDateTime } from '@/mixins/dateTime';
import ArrestQueryModal from '../../../../../components/Case/ArrestQueryModal.vue';
import UpdateArrestModal from '../../../../../components/Case/UpdateArrestModal.vue';
import Vue from 'vue';
import { ArrestTarget } from '@/static/enums/bank';
import ArrestStatusColumn from '@/components/Case/ArrestTable/ArrestStatusColumn';
import api from '@/api';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ArrestTableLegacy',
  components: {
    ArrestStatusColumn,
    ArrestQueryModal,
    CurrencyInputNumber,
    UpdateArrestModal,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ArrestTarget,
      selectedRows: [],
      expandedRows: [],
      amountOfMinWagesValues: [],
      loading: false,
      TYPE_WITHOUT_PAYMENT_ORDER,
      originalData: [],
      queries: null,
      displayQueriesModal: false,
      displayUpdateModal: false,
      selectedArrest: null,
      minWages: [
        { key: '0', id: '0', custom: false },
        { key: '0.5', id: '0.5', custom: false },
        { key: '1', id: '1', custom: false },
        { key: '1.33333', id: '1.33333', custom: false },
        { key: '1.66666', id: '1.66666', custom: false },
        { key: '2', id: '2', custom: false },
        { key: '2.33333', id: '2.33333', custom: false },
        { key: '2.66666', id: '2.66666', custom: false },
        { key: '3', id: '3', custom: false },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['isRoleKoda']),
    arrests: {
      get() {
        return [...this.data];
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  watch: {
    /**
     * arrest.selected value will we checked when actually sending requests to api.
     * Only rows with selected=1 will be sent
     * @param newValue
     */
    selectedRows(newValue) {
      const selected = newValue.map((selectRow) => selectRow.bank_id);
      this.arrests = this.arrests.map((arrest) => {
        if (ArrestActionInProgressStates.includes(arrest.state)) {
          return arrest;
        }
        arrest.selected = selected.includes(arrest.bank_id);
        return arrest;
      });
    },
  },
  mounted() {
    this.originalData = JSON.parse(JSON.stringify(Object.assign(this.data)));
    api.cases.fetchArrestSums(this.caseId).then((response) => {
      this.arrests = this.data.map((item) => {
        const arrestSum = response.find((sum) => sum.bank.id === item.bank_id);
        if (arrestSum) {
          return {
            ...item,
            arrest_sum: arrestSum.amount,
          };
        }
      });
    });
    this.data.map((item) => {
      if (item.lastTransitionError) {
        this.expandedRows.push(item);
      }
    });
  },
  methods: {
    /**
     * Updates arrest sums for all arrest rows.
     */
    updateArrestSums(newSum) {
      this.arrests = this.arrests.map((row) => ({
        ...row,
        arrest_sum: newSum,
      }));
    },
    /**
     * Allows adding new values dynamically to dropdown.
     * If user search for some value and it doesn't exist, new row will be added
     * @param event
     */
    handleMinWageInput(event) {
      const exists = this.minWages.filter((minWage) => minWage.key === event.value)
        .length;
      if (exists) {
        return;
      }
      this.minWages = [
        ...this.minWages.filter((minWage) => !minWage.custom),
        { key: event.value, id: event.value, custom: true },
      ];
    },
    /**
     * Check if given value is added by user.
     * If yes, then add it as a non-custom to allow different new values in other arrests
     * @param item
     */
    handleAmountMinWagesNumber(item) {
      let value = item.amount_of_min_wages.toString();
      value = value.replace(/,/g, '.');
      const isOriginal = this.minWages.filter(
        (minWage) => minWage.key === value && !minWage.custom
      ).length;
      if (isOriginal) {
        return;
      }
      this.minWages = [
        ...this.minWages.filter((minWage) => !minWage.custom),
        {
          key: value,
          id: value,
          custom: false,
        },
      ];
    },
    toggleQueryModal(query) {
      this.$emit('toggleQueryViewModal', query.id);
    },
    getPreviousArrestSum(item) {
      if (!this.originalData) {
        return 0;
      }
      if (
        ActiveStatuses.indexOf(item.status) > -1 ||
        item.state === ArrestState.ACTIVE ||
        item.state === ArrestState.UPDATING
      ) {
        const originalItem = Object.values(this.originalData).find(
          (original) => original.id === item.id
        );
        if (originalItem === undefined) {
          return '';
        }
        return originalItem.arrest_sum ? originalItem.arrest_sum.formatted : '';
      }
      return '';
    },
    getAmountOfMinWages(item) {
      if (!this.originalData || this.isEpisArrest(item)) {
        return '';
      }
      if (
        ActiveStatuses.indexOf(item.status) > -1 ||
        item.state === ArrestState.ACTIVE ||
        item.state === ArrestState.UPDATING
      ) {
        const originalItem = Object.values(this.originalData).find(
          (original) => original.id === item.id
        );
        if (originalItem === undefined) {
          return '';
        }

        return originalItem.amount_of_min_wages ? originalItem.amount_of_min_wages : '';
      }
      return '';
    },
    getMinWagesOptions(item) {
      if (item.amount_of_min_wages === undefined) {
        return this.minWages;
      }
      item.amount_of_min_wages = item.amount_of_min_wages.toString();
      const exists = this.minWages.filter(
        (minWage) => minWage.key === item.amount_of_min_wages
      ).length;
      if (!exists) {
        return [
          ...this.minWages,
          {
            key: item.amount_of_min_wages,
            id: item.amount_of_min_wages,
            custom: true,
          },
        ];
      }
      return this.minWages;
    },
    getSumNotArrested(item) {
      if (!this.originalData || this.isEpisArrest(item)) {
        return '';
      }
      if (
        ActiveStatuses.indexOf(item.status) > -1 ||
        item.state === ArrestState.ACTIVE ||
        item.state === ArrestState.UPDATING
      ) {
        const originalItem = Object.values(this.originalData).find(
          (original) => original.id === item.id
        );
        if (originalItem === undefined) {
          return '';
        }
        return originalItem.sum_not_arrested
          ? originalItem.sum_not_arrested.formatted
          : '';
      }
      return '';
    },
    rowClass(item) {
      if (item.target === ArrestTarget.EARREST) {
        if (Errors.indexOf(item.status) > -1) {
          return 'arrest-error';
        }
        if (ActiveStatuses.indexOf(item.status) > -1) {
          return 'arrest-active';
        }
      }
      if (this.isEpisArrest(item)) {
        if (item.state === ArrestState.ACTIVE) {
          if (item.stopped) {
            return 'arrest-stopped';
          }
          return 'arrest-active';
        }
      }

      return '';
    },
    toggleQueriesModal(arrest) {
      this.displayQueriesModal = !this.displayQueriesModal;
      this.selectedArrest = arrest;
    },
    /**
     * Currently only banks who use EPIS arrest system can make preArrests.
     * @param item
     * @returns {boolean}
     */
    applicableForPreArrest(item) {
      if (!this.isEpisArrest(item)) {
        return false;
      }
      return (
        (item.state !== ArrestState.ACTIVE && !item.preArrest) ||
        (item.state === ArrestState.ACTIVE && item.preArrest)
      );
    },
    /**
     * Check if arrest can be marked for new actions
     */
    arrestActionInProgress(item) {
      if (!this.isEpisArrest(item)) {
        return false;
      }
      return ArrestActionInProgressStates.includes(item.state);
    },
    isEpisArrest(item) {
      return item.target === ArrestTarget.EPIS;
    },
    getUnArrestableTooltip(item) {
      return `Aresti, mis on olekus "${this.$t(
        `arrest.state.${item.state}`
      )}", ei saa muuta.`;
    },
    getPreArrestTooltip(item) {
      if (!this.isEpisArrest(item)) {
        return this.$t('arrest.table.field_not_usable');
      }
      return this.$t('arrest.table.arrest_to_pre_arrest_error');
    },
    getFormattedDateTime,
    getZonedDateTime,
    openChangeArrestDialog(arrest) {
      this.selectedArrest = arrest;
      this.displayUpdateModal = true;
    },
    closeChangeArrestDialog() {
      this.selectedArrest = null;
      this.displayUpdateModal = false;
    },
  },
});
</script>
<style>
#arrestTable .arrest-error {
  background: #fff6f6;
  color: #9f3a38;
}
#arrestTable .arrest-active {
  color: green;
  background: #d6f599;
}
#arrestTable .arrest-stopped {
  background: #eae572d8;
}
</style>
