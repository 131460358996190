import Papa from 'papaparse';

import api from '@/api';
import { CaseSelection } from '@/types/CaseSelection';
import { Id } from '@/types';
import { ColumnFieldNumberHeaderLabel } from '@/api/caseTable/ColumnFieldNumberHeaderLabel';

/**
 * Function to fetch case number from each csv row and include in a final
 * array where case ids are grouped as units of 1000.
 */
function getCaseNumbersPaged(csvRows: string[][], indexOfNumberColumn: number): Id[][] {
  const caseNumbersPaged: string[][] = [];

  const pageSize = 1000;
  let page = 0;
  for (let i = 0; i < csvRows.length - 1; i++) {
    if (i % pageSize === 0 && i > 0) {
      // Set to next page of case ids.
      page++;
    }

    caseNumbersPaged[page] = [
      ...(caseNumbersPaged[page] || []),
      csvRows[i][indexOfNumberColumn],
    ];
  }

  return caseNumbersPaged;
}

/**
 * Creates a case selection to be used to filter out a selection of cases.
 *
 * @return Id of created case selection.
 */
export async function createCaseSelectionEntity(
  caseNumbersPaged: string[][],
  progress: (p: number, t: number) => void,
  reject: (reason: unknown) => void
): Promise<Id> {
  let caseSelectionId: Id | null = null;

  try {
    const pages = caseNumbersPaged.length;
    for (let page = 0; page < pages; page++) {
      if (page === 0) {
        // Create selection.
        caseSelectionId = await api.caseSelection.create();

        // Add first page of cases to selection.
        await api.caseSelection.addCases(
          caseSelectionId,
          caseNumbersPaged[page]
        );
      }

      if (page > 0 && caseSelectionId) {
        await api.caseSelection.addCases(
          caseSelectionId,
          caseNumbersPaged[page]
        );
      }

      progress(page, pages);
    }
  } catch (e) {
    if (caseSelectionId) {
      await api.caseSelection.remove(caseSelectionId);
    }

    reject(new Error('Toimkute valimi loomine ebaõnnestus.'));
  }

  if (!caseSelectionId) {
    throw new Error('Loodud toimikute valimit ei leitud');
  }

  return caseSelectionId;
}

/**
 * Creates a case selection entity from an imported file that can be used to
 * filter out events from that selection.
 */
export function createCaseSelectionFromFile(
  file: File,
  progress: (p: number, t: number) => void
): Promise<CaseSelection> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: async ({ data: rows }: { data: string[][] }): Promise<void> => {
        // Header is always given as first element in csv rows array.
        const headerRow = rows[0];
        // Remove columns header.
        rows.shift();

        if (!headerRow.includes(ColumnFieldNumberHeaderLabel)) {
          reject(
            new Error(
              `Valitud eksport failis puudub "${ColumnFieldNumberHeaderLabel}" tulp. Kasutage faili mis hõlmab seda tulpa.`
            )
          );
          return;
        }

        const id = await createCaseSelectionEntity(
          getCaseNumbersPaged(
            rows,
            headerRow.indexOf(ColumnFieldNumberHeaderLabel)
          ),
          progress,
          reject
        );

        if (!id) {
          reject(new Error('Loodud valimit ei leitud.'));
          return;
        }

        resolve({ id });
      },
      error: (err) => {
        reject(new Error('Viga faili lugemisel. ' + err));
      },
    });
  });
}
