import SettingApi from '../../api/setting';
import { SettingType } from '@/static/enums/settingType';

const getSetting = (state, name) => {
  if (Object.values(state.settings).length > 0) {
    return state.settings.find((setting) => setting.name === name);
  }
  return null;
};

const FETCHING_SETTINGS = 'FETCHING_SETTINGS';
const FETCHING_SETTINGS_SUCCESS = 'FETCHING_SETTINGS_SUCCESS';
const FETCHING_SETTINGS_ERROR = 'FETCHING_SETTINGS_ERROR';
const FETCHING_SETTING = 'FETCHING_SETTING';
const FETCHING_SETTING_SUCCESS = 'FETCHING_SETTING_SUCCESS';
const FETCHING_SETTING_ERROR = 'FETCHING_SETTING_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    settings: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasSettings(state) {
      return state.settings.length > 0;
    },
    settings(state) {
      return state.settings;
    },
    partialEndTemplates(state) {
      const templates = getSetting(state, SettingType.DOCUMENT_LAYOUT_ARREST);
      return templates.value.filter(
        (template) => template.value.file_type === 35
      );
    },
    caseResolvers: (state) => getSetting(state, SettingType.CASE_RESOLVER),
    caseTypes: (state) => getSetting(state, SettingType.CASE_SOLUTION_TYPE),
    claimMainTypes: (state) => getSetting(state, SettingType.CLAIM_TYPE),
    claimDueDates: (state) => getSetting(state, SettingType.CLAIM_DUE_DATE),
    claimSurchargeTypes: (state) =>
      getSetting(state, SettingType.CLAIM_SURCHARGE_TYPE),
    representativeType: (state) =>
      getSetting(state, SettingType.REPRESENTATIVE_TYPE),
    resolvingTypes: (state) => getSetting(state, SettingType.RESOLVING_TYPE),
    minWage: (state) => getSetting(state, SettingType.MIN_WAGE),
    eArrestFee: (state) => getSetting(state, SettingType.E_ARREST_FEE),
    defaultDeadline: (state) =>
      getSetting(state, SettingType.DEFAULT_DEADLINE),
    voluntaryFulfillmentDeadline: (state) =>
      getSetting(state, SettingType.VOLUNTARY_FULFILLMENT_DEADLINE),
    documentTypes: (state) => getSetting(state, SettingType.DOCUMENT_TYPE),
    documentLayoutArrest: (state) =>
      getSetting(state, SettingType.DOCUMENT_LAYOUT_ARREST),
    documentStatusReasons: (state) =>
      getSetting(state, SettingType.DOCUMENT_STATUS_REASON),
    transferReasons: (state) => getSetting(state, SettingType.TRANSFER_REASON),
    workarea: (state) => getSetting(state, SettingType.WORKAREA),
    allowedTMS: (state) => getSetting(state, SettingType.ALLOWED_TMS),
    arrestChangeDefaultPeriod: (state) =>
      getSetting(state, SettingType.ARREST_CHANGE_DEFAULT_PERIOD),
    defaultCloseTemplate: (state) =>
      getSetting(state, SettingType.DEFAULT_CLOSE_TEMPLATE),
    defaultExpireCloseTemplate: (state) =>
      getSetting(state, SettingType.DEFAULT_EXPIRE_CLOSE_TEMPLATE),
    defaultStopBasis: (state) =>
      getSetting(state, SettingType.DEFAULT_STOP_BASIS),
  },
  mutations: {
    [FETCHING_SETTINGS](state) {
      state.isLoading = true;
      state.error = null;
      state.settings = [];
    },
    [FETCHING_SETTINGS_SUCCESS](state, settings) {
      state.isLoading = false;
      state.error = null;
      state.settings = settings;
    },
    [FETCHING_SETTINGS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.settings = [];
    },
    [FETCHING_SETTING](state, index) {
      state.isLoading = true;
      state.error = null;
      state.settings[index] = [];
    },
    [FETCHING_SETTING_SUCCESS](state, { idx, data }) {
      state.isLoading = false;
      state.error = null;
      // eslint-disable-next-line prefer-destructuring
      state.settings[idx] = data[0];
    },
    [FETCHING_SETTING_ERROR](state, index, error) {
      state.isLoading = false;
      state.error = error;
      state.settings[index] = [];
    },
    importSettings(state, res) {
      state.settings = res.data || {};
      return state.settings;
    },
    clearSettings(state) {
      state.settings = [];
    },
  },
  actions: {
    async getSettings({ commit }, id) {
      return SettingApi.findById(id).then((res) => {
        commit('importSettings', res);
        return res;
      });
    },
    clearSettings({ commit }) {
      commit('clearSettings');
    },
    async fetchByType({ commit, state }, { id, type }) {
      let index = 0;
      if (Object.values(state.settings).length > 0) {
        index = state.settings.indexOf(
          state.settings.find((setting) => setting.name === type)
        );
      }
      commit(FETCHING_SETTING, index);
      try {
        const response = await SettingApi.fetchByType(id, type);
        commit(FETCHING_SETTING_SUCCESS, { idx: index, data: response.data });
        return response.data;
      } catch (e) {
        commit(FETCHING_SETTING_ERROR, index, e);
        return null;
      }
    },
  },
};
