import { i18n, app } from '@/app';
import { NotificationInput } from '@/types';

export const showSuccessNotification = ({
  title = 'notification.success',
  text = '',
  duration = 3000,
}: NotificationInput): void => {
  app.$toast.add({
    severity: 'success',
    summary: i18n.t(title),
    detail: text ? i18n.t(text.toString()) : '',
    life: duration,
    closable: true,
  });
};

export const showInfoNotification = ({
  text = '',
  duration = 6000,
}: NotificationInput): void => {
  app.$toast.add({
    severity: 'info',
    summary: text,
    life: duration,
    closable: true,
  });
};

export const showErrorNotification = ({
  title = 'notification.error',
  text = '',
  duration = 6000,
}: NotificationInput) => {
  app.$toast.add({
    severity: 'error',
    summary: i18n.t(title),
    detail: text ? i18n.t(text.toString()) : '',
    life: duration,
    closable: true,
    group: 'error',
  });
};
