/**
 * Enum for PersonType representing whether the given person is
 * either "Füüsiline" or "Juriidiline". Note that term "Person"
 * can be either one of those types in an enforcement case.
 * Term "Person" implies more as "toimiku osapool" or
 * "side representative of case".
 */
export enum PersonType {
  'PRIVATE' = 1,
  'JURIDICAL' = 2,
}
