import req from './lib/req';

export default {
  fetchByType(bureauId, type) {
    return req.get('/setting', {
      params: {
        tenant_id: bureauId,
        type,
      },
    });
  },
  findById(id) {
    return req.get(`/setting?tenant_id=${id}`);
  },
  update(id, value) {
    return req.put(`/setting/${id}`, value);
  },
};
export function getAddresses(idList) {
  const params = {
    id: idList,
  };

  return req.get('/address', {
    params: {
      where: JSON.stringify(params),
    },
  });
}
