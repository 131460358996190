<template>
  <div>
    <prime-editor v-model="model" :editor-style="editorStyle">
      <template #toolbar>
        <span class="ql-formats">
          <select class="ql-header" defaultValue="0">
            <option value="H1">Heading 1</option>
            <option value="H2">Heading 2</option>
            <option value="H3">Heading 3</option>
            <option value="H4">Heading 4</option>
            <option value="H5">Heading 5</option>
            <option value="H6">Heading 6</option>
          </select>
          <select class="ql-font">
            <option value="Times-New-Roman" selected>Times</option>
            <option value="serif"></option>
            <option value="sans-serif"></option>
            <option value="monospace"></option>
          </select>
          <select title="Size" class="ql-size">
            <option value="10px">10px</option>
            <option value="12px" selected>12px</option>
            <option value="16px">16px</option>
            <option value="18px">18px</option>
            <option value="32px">32px</option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-bold" type="button"></button>
          <button class="ql-italic" type="button"></button>
          <button class="ql-underline" type="button"></button>
        </span>
        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered" type="button"></button>
          <button class="ql-list" value="bullet" type="button"></button>
          <select class="ql-align">
            <option defaultValue></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-link" type="button"></button>
          <button class="ql-image" type="button"></button>
          <button class="ql-code-block" type="button"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-clean" type="button"></button>
        </span>
      </template>
    </prime-editor>
  </div>
</template>
<script>
import Quill from 'quill';
export default {
  name: 'Editor',
  components: {
    'prime-editor': () => import('primevue/editor'),
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    editorStyle: {
      type: Object,
      default: () => {
        return { height: '320px' };
      },
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
    const BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle, true);
    const ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);
    const DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle, true);
    const FontStyle = Quill.import('attributors/style/font');
    Quill.register(FontStyle, true);
    FontStyle.whitlelist = ['sans serif', 'serif', 'monospace'];
    const SizeStyle = Quill.import('attributors/style/size');
    SizeStyle.whitelist = ['10px', '12px', '16px', '18px', '32px'];
    Quill.register(SizeStyle, true);
    this.setDefaultMargins();
  },
  methods: {
    setDefaultMargins() {
      const Block = Quill.import('blots/block');
      class Pblock extends Block {
        static create(value) {
          let node = super.create();
          node.setAttribute(
            'style',
            'margin-top:0in;margin-right:0in;margin-bottom:0.0pt;margin-left:0in;line-height:100%;'
          );
          return node;
        }
      }
      Quill.register(Pblock, true);
    },
  },
};
</script>
<style scoped>
.ql-editor .ql-font-Times-New-Roman {
  font-family: 'Times New Roman', serif;
}
</style>
