import req from '../lib/req';

export function findById(id) {
  return req.get(`/contact/${id}`);
}

export function getContacts(idList) {
  const params = {
    id: idList,
  };

  return req.get('/contact', {
    params: {
      where: JSON.stringify(params),
    },
  });
}

export function create(data) {
  return req.post('/contact', data);
}

export function change(id, data) {
  return req.put(`/contact/${id}`, data);
}
