import req from '../lib/req';

export function upload(file) {
  return req.post('/document', file, {
    headers: {
      'Koda-File-Name': file.name,
      'Content-Type': file.type,
    },
  });
}

export function updateDocument(id, data) {
  return req.put(`/document/${id}`, data);
}

export function deleteDocument(id) {
  return req.delete(`/document/${id}`);
}

export function getDocuments(idList) {
  const params = {
    id: idList,
  };

  return req.get('/document', {
    params: {
      where: JSON.stringify(params),
    },
  });
}

export function fetchCaseDocuments(caseId) {
  return req.get(`/case/${caseId}/documents`, {
    params: {
      'order[createdAt]': 'DESC',
    },
  });
}

export function exportCsv(cases) {
  return req.post('/export/csv', { list: cases }, { responseType: 'blob' });
}
export function exportExcel(cases) {
  return req.post('/export/xls', { list: cases }, { responseType: 'blob' });
}

export function download(id) {
  return req.get(`/document/${id}/download`, { responseType: 'blob' });
}
