import api from '../api';
import QueryAPI from '../api/query';

export function removeEmptyValues(obj) {
  const result = { ...obj };
  const keys = Object.keys(result);

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    if (result[key] === undefined || result[key] === null) {
      delete result[key];
    }
  }

  return result;
}

export function deepCopy(arr) {
  return JSON.parse(JSON.stringify(arr));
}

const LIMIT = 100;

export async function getPersons(ids, includeOthers) {
  if (ids.length === 0) {
    return [];
  }
  if (ids.length > LIMIT) {
    const breakPoint = Math.ceil(ids.length / 2);

    const ids1 = ids.slice(0, breakPoint);
    const ids2 = ids.slice(breakPoint);

    const [persons1, persons2] = await Promise.all([
      getPersons(ids1, includeOthers),
      getPersons(ids2, includeOthers),
    ]);

    return persons1.concat(persons2);
  }
  const persons = await api.person.getPersonListByIds(ids, includeOthers);
  return persons.data;
}

export async function getTags(ids) {
  if (ids.length === 0) {
    return [];
  }
  if (ids.length > LIMIT) {
    const breakPoint = Math.ceil(ids.length / 2);

    const ids1 = ids.slice(0, breakPoint);
    const ids2 = ids.slice(breakPoint);

    const [tags1, tags2] = await Promise.all([getTags(ids1), getTags(ids2)]);

    return tags1.concat(tags2);
  }
  const tags = await api.tags.getTagListByIds(ids);
  return tags.data;
}

export async function getTenants(ids) {
  if (ids.length === 0) {
    return [];
  }
  if (ids.length > LIMIT) {
    const breakPoint = Math.ceil(ids.length / 2);
    const ids1 = ids.slice(0, breakPoint);
    const ids2 = ids.slice(breakPoint);

    const [tenants1, tenants2] = await Promise.all([
      getTenants(ids1),
      getTenants(ids2),
    ]);

    return tenants1.concat(tenants2);
  }

  const tenants = await api.tenantLegacy.findByIds(ids);
  return tenants.data;
}

export async function getQueries(ids) {
  if (ids.length === 0) {
    return [];
  }

  if (ids.length > LIMIT) {
    const breakPoint = Math.ceil(ids.length / 2);
    const ids1 = ids.slice(0, breakPoint);
    const ids2 = ids.slice(breakPoint);
    const [queries1, queries2] = await Promise.all([
      getQueries(ids1),
      getQueries(ids2),
    ]);

    return queries1.concat(queries2);
  }

  const queries = await QueryAPI.findByIds(ids);
  return queries.data.items;
}

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
