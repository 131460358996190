import req from '../lib/req';
import { CaseItem } from '@/api/caseTable/CaseItem';
import { Collection } from '@/types/ApiPlatform/Collection';
import { Column } from '@/api/caseTable/Column';
import { Filters } from '@/api/caseTable/Filters';
import { Request } from '@/api/caseTable/Request';
import { stringifyFilters } from '@/api/caseTable/stringifyFilters';

/**
 * Fetch column options for the case table.
 */
export async function findColumns(): Promise<Column[]> {
  return req
    .get('/v2/enforcement_cases/columns')
    .then(({ data }: { data: Collection<Column> }) => data['hydra:member']);
}

/**
 * Fetch enforcement cases by only requested fields.
 */
export function findCasesPaged(
  request: Request
): Promise<Collection<CaseItem>> {
  return req
    .post('/v2/enforcement_cases/data_table', {
      ...request,
      filters: stringifyFilters(request.filters),
    })
    .then(({ data }: { data: Collection<CaseItem> }) => data);
}

/**
 * Fetch total count of cases found by given filters.
 */
export async function findCasesCount(filters: Filters): Promise<number> {
  return req
    .post('/v2/enforcement_cases/case_table_cases_count', {
      filters: stringifyFilters(filters),
    })
    .then(({ data }: { data: { body: { contents: string } } }) => {
      return parseInt(data.body.contents, 10);
    });
}

export async function fetchCasesAsCsv(request: Request): Promise<string> {
  return await req.post(
    '/v2/enforcement_cases/data_table',
    {
      ...request,
      filters: stringifyFilters(request.filters),
    },
    { headers: { Accept: 'text/csv' } }
  ).then(({ data }: { data: string }) => data);
}
