import { AxiosResponse } from 'axios';
import req from '../lib/req';
import { IssuerFeeTableRow } from '@/types';

export function update(
  row: IssuerFeeTableRow
): Promise<AxiosResponse<IssuerFeeTableRow>> {
  return req.put(`/issuer-fee-row/${row.id}`, row);
}

export function create(
  row: IssuerFeeTableRow
): Promise<AxiosResponse<IssuerFeeTableRow>> {
  return req.post('/issuer-fee-row', row);
}

export function deleteRow(
  row: IssuerFeeTableRow
): Promise<AxiosResponse<IssuerFeeTableRow>> {
  return req.delete(`/issuer-fee-row/${row.id}`);
}
