import req from '../lib/req';

export function getAll(params) {
  return req.get('/log?order[createdAt]=desc', {
    params,
  });
}

export function getCaseLogs(id, params) {
  return req.get('/log', {
    params,
  });
}
