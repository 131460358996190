import req from '../lib/req';
import { ClaimType, ClaimTypeSaved, PaginatedAxiosResponse } from '@/types';
import { AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require('qs');

export function findAll(): Promise<ClaimTypeSaved[]> {
  return req
    .get('/v2/claim_types')
    .then((resp: AxiosResponse) => resp.data['hydra:member']);
}
export function findByParams(
  params: unknown
): PaginatedAxiosResponse<ClaimType> {
  return req.get(`/claim-type?${qs.stringify(params)}`);
}
export function update(claimType: ClaimType): Promise<ClaimType> {
  return req
    .put(`/v2/claim_types/${claimType.id}`, claimType)
    .then((resp: AxiosResponse<ClaimType>) => resp.data);
}
export function create(claimType: ClaimType): Promise<ClaimType> {
  return req
    .post('/v2/claim_types', claimType)
    .then((resp: AxiosResponse<ClaimType>) => resp.data);
}
export function deleteClaimType(claimType: ClaimType): Promise<ClaimType> {
  return req.delete(`/v2/claim_types/${claimType.id}`);
}
