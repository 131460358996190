/**
 * Valid comparison operator values for Api Platform's RangeFilter filter.
 * More at: https://api-platform.com/docs/v2.5/core/filters/#range-filter
 */
export enum RangeFilterComparison {
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  BETWEEN = 'between',
}
