import VueI18n from 'vue-i18n';

import { BulkActionStatus } from '@/static/enums/bulkActionStatus';

/**
 * Option type for filter to allow filtering
 * bulk action tasks by a certain status.
 */
export type TasksFilterOption = {
  label: string;
  value: BulkActionStatus | null;
};

/**
 * Returns filtering options used in BulkActionTasksFilterPicker.
 */
export function getFilterOptions(i18n: VueI18n): TasksFilterOption[] {
  return [
    {
      label: i18n.t('bulkActionTasksFilterPicker.statusAny').toString(),
      value: null,
    },
    {
      label: i18n.t('bulkActionTasksFilterPicker.statusFailed').toString(),
      value: BulkActionStatus.FAILED,
    },
    {
      label: i18n.t('bulkActionTasksFilterPicker.statusOk').toString(),
      value: BulkActionStatus.OK,
    },
    {
      label: i18n.t('bulkActionTasksFilterPicker.statusWip').toString(),
      value: BulkActionStatus.WIP,
    },
    {
      label: i18n.t('bulkActionTasksFilterPicker.statusCancelled').toString(),
      value: BulkActionStatus.CANCELLED,
    },
  ];
}
