<template>
  <sui-form @submit.prevent="">
    <sui-form-field>
      <label>{{ $t('case.table.status') }}</label>
      <Select v-model="filters.status" :options="statusOptions" />
    </sui-form-field>

    <sui-form-field>
      <label>{{ $t('case.table.caseNumber') }}</label>
      <InputText v-model="filters.number" @keyup.enter="enterPressed" />
    </sui-form-field>

    <sui-form-field>
      <label>{{ $t('case.table.claimType') }}</label>
      <Select v-model="filters.main_claim_type" :options="claimTypes" />
    </sui-form-field>

    <sui-form-field>
      <label>{{ $t('case.info.dateOpened') }}</label>

      <label class="sub-label">{{ $t('from') }}</label>
      <DatePicker v-model="filters.date_opened_from" />

      <label class="sub-label">{{ $t('until') }}</label>
      <DatePicker v-model="filters.date_opened_until" />
    </sui-form-field>

    <sui-form-field>
      <sui-checkbox
        v-model="filters.archived"
        :label="$t('case.table.archived')"
      />
    </sui-form-field>
    <sui-form-field>
      <sui-checkbox
        v-model="filters.changed"
        :label="$t('case.table.changed')"
      />
    </sui-form-field>
    <sui-form-field>
      <sui-checkbox
        v-model="filters.includeOthers"
        :label="$t('case.table.include_others')"
      />
    </sui-form-field>
  </sui-form>
</template>

<script>
import Select from '../../components/Select.vue';
import { CaseStatus } from '@/static/enums/caseStatus';
import { getStatusText } from '@/mixins/case';
import DatePicker from '../../components/DatePicker.vue';
import api from '@/api';

export default {
  name: 'Filters',
  components: {
    DatePicker,
    Select,
  },
  data() {
    return {
      claimTypes: [],
      filters: {
        status: 0,
      },
      filterTimeout: null,
    };
  },
  computed: {
    statusOptions: {
      get() {
        return Object.values(CaseStatus)
          .filter((value) => typeof value === 'number')
          .map((status) => ({
            display_name: getStatusText(status),
            key: status,
          }));
      },
    },
  },
  watch: {
    filters: {
      handler(value) {
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
          this.$emit('change', value);
        }, 500);
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchClaimTypes();
  },
  methods: {
    fetchClaimTypes() {
      api.claimTypes.findAll().then((response) => {
        this.claimTypes = response.map((claimType) => {
          return {
            display_name: claimType.name,
            key: claimType.id,
          };
        });
      });
    },
    reset() {
      this.filters = {};
    },
    enterPressed() {
      this.$emit('enterPressed');
    },
  },
};
</script>

<style scoped>
.ui.form {
  margin-top: 1rem;
}
</style>
