<template>
  <sui-segment ref="claims">
    <h2>{{ $t('claim.claims') }}</h2>
    <sui-accordion v-for="(claim, index) in claims" :key="index">
      <sui-divider />

      <sui-accordion-title :tabindex="0" @keypress.enter="claimToggle">
        <sui-icon name="dropdown" />
        <h3>{{ claim.mainClaim ? $t('claim.mainClaim') : $t('claim.sideClaim') }}</h3>
      </sui-accordion-title>
      <Dropdown
        v-if="!disabled"
        v-model="claim.status"
        :options="claimStatuses"
        option-label="label"
        option-value="value"
        :placeholder="$t('status')"
        @change="toggleClaimStatusChange(claim)"
      />
      <span
        v-else-if="claim.status !== null"
        v-tooltip="getFormattedDateTime(claim.date_status_change)"
      >
        ({{ $t(`claim.status.${claim.status}`) }})
      </span>
      <sui-accordion-content>
        <Claim
          :data="claim"
          :disabled="disabled"
          :claim-type-options="claimTypeOptions"
          @created="(firstItem) => openClaim(firstItem, index)"
          @update="(values) => updateClaim(values, index)"
          @remove="() => removeClaim(index)"
          @stop="() => stopClaim(index)"
          @reopen="() => reopenClaim(index)"
        />
      </sui-accordion-content>
    </sui-accordion>

    <div v-if="!disabled" class="button__container">
      <sui-divider />

      <sui-button size="tiny" type="button" @click.prevent="addClaim">
        {{ $t('add') }}
      </sui-button>
    </div>

    <div v-if="showButtons" class="button__container">
      <sui-divider />

      <span
        v-if="disabled"
        v-tooltip.top="{
          value: $t('case.fieldsDisabled', {
            state: $t(CaseStateToLabel[caseState]),
          }),
          disabled: isEditable,
        }"
      >
        <sui-button size="mini" :disabled="!isEditable" @click.prevent="$emit('edit')">
          {{ $t('edit') }}
        </sui-button>
      </span>

      <sui-button v-if="!disabled" size="mini" @click.prevent="$emit('cancel')">
        {{ $t('cancel') }}
      </sui-button>

      <sui-button v-if="!disabled" :loading="isLoading" primary size="mini" type="submit">
        {{ $t('save') }}
      </sui-button>
    </div>
    <ClaimStatusChangeDialog
      v-if="claimStatusChangeDialogOpen"
      :claim="claimStatusChangedClaim"
      :open="claimStatusChangeDialogOpen"
      @onToggle="toggleClaimStatusChange"
    />
  </sui-segment>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/api';
import Claim from './Claim.vue';
import { Status as ClaimStatus } from '@/static/enums/claim';
import { getFormattedDateTime } from '@/mixins/dateTime';
import ClaimStatusChangeDialog from '@/components/Case/Claims/ClaimStatusChangeDialog';
import { SettingType } from '@/static/enums/settingType';
import { CaseStateToLabel } from '@/static/enums/caseStateToLabel';
import { isCaseEditable } from '@/utils/isCaseEditable';

export default {
  name: 'Claims',
  components: {
    ClaimStatusChangeDialog,
    Claim,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      claims: [],
      solutionType: 0,
      claimTypeOptions: [],
      claimStatusChangeDialogOpen: false,
      claimStatusChangedClaim: null,
      CaseStateToLabel,
    };
  },
  computed: {
    ...mapGetters('solutionType', ['hasSolutionType']),
    claimStatuses() {
      return Object.values(ClaimStatus).map((item) => {
        return {
          value: item,
          label: this.$t(`claim.status.${item}`),
        };
      });
    },
    showButtons() {
      if (!this.caseInfo) {
        return false;
      }

      return !this.caseInfo.in_transfer;
    },
    caseState() {
      return this.caseInfo.state;
    },
    /**
     * Whether the case can be edited (true) or not (false).
     */
    isEditable() {
      return isCaseEditable(this.caseInfo);
    },
  },
  created() {
    this.$watch(
      'data',
      (data) => {
        if (data && data.length > 0) {
          this.claims = [...data];
        } else {
          this.addClaim();
        }
      },
      { immediate: true }
    );
    this.fetchClaimTypes();
  },
  methods: {
    fetchClaimTypes() {
      api.claimTypes.findAll().then((response) => {
        this.claimTypeOptions = response;
      });
    },
    addClaim() {
      this.claims.push({});
    },
    claimToggle(event) {
      event.target.click();
    },
    updateClaim(values, index) {
      this.claims[index] = values;
      this.$emit('update', this.claims);
    },
    openClaim(firstItem, index) {
      const newestClaim = this.$refs.claims.$el.getElementsByClassName('ui accordion')[
        index
      ];
      const title = newestClaim.querySelector('.title');

      // Need this to make sure DOM renders first
      setTimeout(() => {
        title.click();
        firstItem.focus();
      }, 0);
    },
    removeClaim(index) {
      this.$emit('delete', this.claims[index]);
      this.claims.splice(index, 1);
      this.$emit('update', this.claims);
    },
    stopClaim(index) {
      this.$emit('stop', this.claims[index]);
    },
    reopenClaim(index) {
      this.$emit('reopen', this.claims[index]);
    },
    toggleClaimStatusChange(claim) {
      this.claimStatusChangedClaim = claim === null ? null : claim;
      this.claimStatusChangeDialogOpen = !this.claimStatusChangeDialogOpen;
    },
    getFormattedDateTime,
  },
};
</script>

<style scoped>
.ui.segment {
  margin-bottom: 1rem;
}

.ui.button.claim-button {
  background-color: transparent;
  color: #4c4c4c;
  font-size: 24px;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
}

.ui.button.claim-button i.icon {
  font-size: 1.2rem;
  margin-right: 0 !important;
  vertical-align: top;
}

.button__container {
  text-align: right;
}

.ui.accordion:first-of-type .ui.divider {
  display: none;
}

.ui.accordion .title {
  display: inline-block;
  margin-left: -0.5rem;
  min-width: calc((100% + 1rem) / 8);
  padding-right: 0.5rem;
  text-align: right;
}

.ui.accordion h3 {
  display: inline-block;
  margin-top: 0;
}
</style>
