import BureauApi from '../../api/bureau';

const FETCHING_BUREAU = 'FETCHING_BUREAU';
const FETCHING_BUREAU_SUCCESS = 'FETCHING_BUREAU_SUCCESS';
const FETCHING_BUREAU_ERROR = 'FETCHING_BUREAU_ERROR';
const UPDATING_BUREAU = 'UPDATING_BUREAU';
const UPDATING_BUREAU_SUCCESS = 'UPDATING_BUREAU_SUCCESS';
const UPDATING_BUREAU_ERROR = 'UPDATING_BUREAU_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    bureau: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasBureau(state) {
      return state.bureau !== null;
    },
    bureau(state) {
      return state.bureau;
    },
  },
  mutations: {
    [FETCHING_BUREAU](state) {
      state.isLoading = true;
      state.error = null;
      state.bureau = null;
    },
    [FETCHING_BUREAU_SUCCESS](state, bureau) {
      state.isLoading = false;
      state.error = null;
      state.bureau = bureau;
    },
    [FETCHING_BUREAU_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.bureau = null;
    },
    [UPDATING_BUREAU](state) {
      state.isLoading = true;
      state.error = null;
      state.bureau = null;
    },
    [UPDATING_BUREAU_SUCCESS](state, bureau) {
      state.isLoading = false;
      state.error = null;
      state.bureau = bureau;
    },
    [UPDATING_BUREAU_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.bureau = null;
    },
  },
  actions: {
    async fetch({ commit }, id) {
      commit(FETCHING_BUREAU);
      try {
        const response = await BureauApi.fetch(id);
        commit(FETCHING_BUREAU_SUCCESS, response.data);
        return response.data;
      } catch (e) {
        commit(FETCHING_BUREAU_ERROR, e);
        return null;
      }
    },
    async update({ commit }, data) {
      commit(UPDATING_BUREAU);
      try {
        const response = await BureauApi.update(data.id, data);
        commit(UPDATING_BUREAU_SUCCESS, response.data);
        return response.data;
      } catch (e) {
        commit(UPDATING_BUREAU_ERROR, e);
        return null;
      }
    },
  },
};
