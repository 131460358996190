import req from './lib/req';

export default {
  findAll() {
    return req.get('/bank');
  },
  fetch(id) {
    return req.get(`/bank/${id}`);
  },
};
