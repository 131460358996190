import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import app from './modules/app';
import banks from './modules/banks';
import settings from './modules/settings';
import user from './modules/user';
import cases from './modules/case';
import person from './modules/person';
import query from './modules/query';
import claim from './modules/claim';
import bureau from './modules/bureau';
import solutionType from './modules/solutionType';
import classifier from './modules/classifier';
import classifierValue from './modules/classifierValue';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  namespaced: true,
  plugins: [
    createPersistedState({
      key: 'vuex',
      reducer(val) {
        if (val.auth.token === null) {
          return {};
        }
        return val;
      },
    }),
  ],
  modules: {
    auth,
    app,
    banks,
    settings,
    user,
    cases,
    person,
    query,
    claim,
    bureau,
    solutionType,
    classifier,
    classifierValue,
  },
});
