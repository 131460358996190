import req from '../lib/req';

const qs = require('qs');

export function findByParams(params) {
  return req.get(`/error-log?${qs.stringify(params)}`);
}

export function findById(id) {
  return req.get(`/error-log/${id}`);
}

export function findAll() {
  return req.get('/error-log');
}
