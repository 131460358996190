<template>
  <div>
    <Dialog
      :header="$t('claim.stop')"
      :visible="isOpen"
      :breakpoints="{ '960px': '40vw', '640px': '80vw' }"
      :style="{ width: '30rem' }"
      :closable="false"
    >
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12">
          <label>Peatamise kuupäev</label>
          <Calendar
            v-model="stopStartDate"
            :month-navigator="true"
            :show-time="false"
            :year-navigator="true"
            append-to="body"
            date-format="dd.mm.yy"
            year-range="1990:2050"
          />
        </div>
        <div class="p-col-12 p-md-12">
          <label>Peatamise alus</label>
          <Dropdown
            v-model="stoppingBasis"
            :options="stoppingReasons"
            option-label="name"
            data-key="id"
            placeholder="Vali alus"
            :filter="true"
            append-to="body"
            class="text-left"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" style="float: left">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else style="float: left">
                {{ slotProps.placeholder }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-12 p-md-12">
          <label>{{ $t('case.stopExplanation').toString() }}</label>
          <InputText v-model="stopReason" />
        </div>
      </div>
      <template #footer>
        <Button
          :label="$t('cancel')"
          icon="pi pi-times"
          class="p-button-text"
          @click="toggleModal"
        />
        <Button
          :label="$t('claim.stop')"
          icon="pi pi-check"
          autofocus
          :disabled="isDisabled"
          @click="stopCase"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import api from '../../api';
import { getCurrentDateTime } from '../../mixins/dateTime';

export default {
  name: 'StopCaseModal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    caseId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      stoppingBasis: 0,
      stoppingReasons: [],
      stopReason: null,
      stopStartDate: DateTime.now().toJSDate(),
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters('claim', ['isLoading', 'hasError', 'error']),
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
  },
  mounted() {
    api.classifier.findByTag('stop_reason').then((response) => {
      api.classifierValueLegacy.findByClassifier(response.data[0].id).then((resp) => {
        this.stoppingReasons = resp.data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
      });
    });
  },
  methods: {
    async stopCase() {
      this.isDisabled = true;
      api.cases
        .stop(this.caseId, {
          stop_basis: this.stoppingBasis.id,
          stop_reason: this.stopReason,
          stop_start_date: DateTime.fromJSDate(this.stopStartDate),
        })
        .then(() => this.$emit('onStoppingSuccess'))
        .catch(() => {
          this.isDisabled = false;
          this.$emit('onStoppFail', this.error);
        })
        .finally(() => {
          this.isDisabled = false;
        });
    },
    toggleModal() {
      this.$emit('onToggle');
    },
    getCurrentDateTime,
  },
};
</script>
