<template>
  <section class="layout">
    <div class="layout--header">
      <partial-topmenu @nav-click="toggleSidenav" />
    </div>
    <div class="layout--container">
      <div class="layout--aside">
        <partial-sidenav :open="isSidenavOpen" />
      </div>
      <div class="layout--content">
        <router-view />
      </div>
    </div>
  </section>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
import SideNav from '../../components/SideNav/SideNav.vue';
import TopNav from '../../components/TopNav/TopNav.vue';

export default {
  name: 'DefaultLayout',
  components: {
    [SideNav.name]: SideNav,
    [TopNav.name]: TopNav,
  },
  computed: {
    ...mapGetters('app', ['isSidenavOpen']),
    ...mapGetters('auth', ['tenant_id']),
  },
  methods: {
    ...mapMutations('app', ['toggleSidenav']),
  },
};
</script>
<style>
.layout--content {
  flex: 1 0;
  overflow: auto;
  height: calc(100vh - 3rem);
  padding: 1rem;
}
.layout--header {
  height: 3rem;
}
.layout--container {
  display: flex;
}
.layout--aside {
  height: calc(100vh - 3rem);
}
</style>