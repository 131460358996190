/**
 * Possible states of an arrest's lifecycle.
 * These represent combined states between arrests from all systems
 * (EPIS, EArrest, etc).
 *
 * NB: Same as values in ArrestState.php. Keep them in sync.
 */
export enum ArrestState {
  ACTIVE = 'active',
  CREATING = 'creating',
  DRAFT = 'draft',
  LEGACY = 'legacy',
  REMOVED = 'removed',
  REMOVING = 'removing',
  UPDATING = 'updating',
}
