import api from '@/api';
import { Column } from '@/api/caseTable/Column';
import { Filters } from '@/api/caseTable/Filters';
import { ColumnField } from '@/api/caseTable/ColumnField';
import { ColumnFieldNumberHeaderLabel } from '@/api/caseTable/ColumnFieldNumberHeaderLabel';
import { MultiSortMeta } from '@/types/DataTable/MultiSortMeta';

/**
 * Function for fetching cases of given filters and converting them into
 * a final csv string output that can be downloaded as a file. The process
 * of this is async for 1000 cases at a time and a progess function passed
 * can be used to display the current progess compared to all pages of cases
 * needed to fetch as csv text.
 *
 * @param columns Columns to diplay in final csv.
 * @param filters Filters to use to fetch cases.
 * @param multiSortMeta MultiSortMeta options.
 * @param itemsTotal Total number of cases to be found.
 * @param columnsAll All available columns of cases.
 * @param progress Function to update progess of fetching cases.
 * @returns Final csv outout as text.
 */
export async function fetchAllCasesAsCsv(
  columns: Column[],
  filters: Filters,
  multiSortMeta: MultiSortMeta[],
  itemsTotal: number,
  columnsAll: Column[],
  progress: (p: number, t: number) => void
): Promise<string> {
  const itemsPerPage = 1000;
  const pages = Math.ceil(itemsTotal / itemsPerPage);

  let csvAsText = '';
  for (let page = 1; page <= pages; page++) {
    let casesAsCsv = await api.caseTable.fetchCasesAsCsv({
      columns: columns.map((column) => column.field),
      limit: itemsPerPage,
      page,
      sort: multiSortMeta,
      filters: filters,
    });

    if (page === 1) {
      // Translating column headers into more readable form.

      const columnNamesByField = columnsAll.reduce(
        (cols: Record<string, string>, col: Column) => ({
          ...cols,
          // Number field header name is a special case where we need to use
          // a translation defined in front-end in order to assure we can later
          // import the cases by numbers found from the translated column.
          [col.field]: col.field === ColumnField.NUMBER ? ColumnFieldNumberHeaderLabel : col.header,
        }),
        {}
      );

      const i = casesAsCsv.indexOf('\n');
      const headerOld = casesAsCsv.slice(0, i);
      const body = casesAsCsv.slice(i + 1);
      const headerNew = headerOld
        .split(',')
        .map((field) => `"${columnNamesByField[field] || field}"`)
        .join(',');

      casesAsCsv = [headerNew, body].join('\n');
    }

    if (page > 1) {
      // Removing columns header for responses later than first page,
      // to allow "stitching" all responses together into a single csv.
      const i = casesAsCsv.indexOf('\n');
      casesAsCsv = casesAsCsv.slice(i + 1);
    }

    // this.progressValue = page / pages;
    progress(page, pages);
    csvAsText += casesAsCsv;
  }

  return csvAsText;
}
