import { mapGetters } from 'vuex';
import pageNameSync from '../../mixins/pageNameSync';

export default {
  mixins: [pageNameSync],
  data: () => ({
    username: '',
    password: '',
  }),
  computed: {
    ...mapGetters('auth', ['token']),
    isSignInAvailable() {
      return !!this.username && !!this.password;
    },
    isLoading() {
      return (
        this.$store.getters['auth/isLoading'] ||
        this.$store.getters['user/isLoading']
      );
    },
    error() {
      return this.$store.getters['auth/error'];
    },
  },
  mounted() {
    if (this.token && localStorage.getItem('user-id')) {
      this.$store.dispatch('auth/auth', {
        token: this.token,
        id: localStorage.getItem('user-id'),
      });
      if (!this.$store.getters['auth/hasError']) {
        this.$router.push({ name: 'ChooseRole' });
      } else {
        this.password = '';
      }
    }
  },
  methods: {
    async handleSignIn() {
      await this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password,
      });
      if (!this.$store.getters['auth/hasError']) {
        this.$router.push({ name: 'ChooseRole' });
      } else {
        this.password = '';
      }
    },
    async handleSignInTara() {
      window.location.href = process.env.VUE_APP_TARA_URL;
    },
  },
};
