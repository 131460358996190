import req from '@/api/lib/req';
import { Collection } from '@/types/ApiPlatform/Collection';
import { Single } from '@/types/ApiPlatform/Single';
import { FeatureFlagSaved } from '@/types/FeatureFlag';

export async function find(page = 1, limit = 100): Promise<Collection<FeatureFlagSaved>> {
    return req
        .get(`/v2/feature_flags?page=${page}&limit=${limit}`)
        .then(({ data }: { data: Collection<FeatureFlagSaved> } ) => data)
    ;
}

export async function update(featureFlag: FeatureFlagSaved): Promise<FeatureFlagSaved> {
    return req
        .put(`/v2/feature_flags/${featureFlag.id}`, featureFlag)
        .then((resp) => resp.data)
    ;
}