import { Column } from '@/api/caseTable/Column';
import { ColumnField } from '@/api/caseTable/ColumnField';
import { User } from '@/types';

/**
 * Returns columns that are given as default case table columns for given user,
 * or just the number column if no default columns are found.
 *
 * @param user Current user object.
 * @param columnsAll All possible column options.
 * @returns
 */
export function findDefaultCaseColumnsOfUser(
  user: User | null,
  columnsAll: Column[]
): Column[] {
  const numberColumn = columnsAll.find((col) => col.field === ColumnField.NUMBER);

  if (!numberColumn) {
    throw new Error('Number column not found from column options.');
  }

  if (!user) {
    return [numberColumn];
  }

  const defaultColumns: Column[] = [];
  user.case_table_column_fields.forEach((field) => {
    const found = columnsAll.find((col) => col.field === field);
    if (!found) {
      return;
    }

    defaultColumns.push(found);
  });

  return defaultColumns.length > 0 ? defaultColumns : [numberColumn];
}
