export const TYPE_LAW_POINT = 'lawpoint';
export const TYPE_CURRENCY = 'currency';
export const TYPE_ASJA_LIIK = 'Asja liik';
export const TYPE_COUNTRY = 'Riik';
export const Types = {
  HOLIDAY: 'holiday',
  LAW_POINT: 'lawpoint',
  CURRENCY: 'valuuta',
  Valuuta: 'Valuuta',
  'Asja liik': 'Asja Liik',
};
