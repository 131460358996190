<template>
  <DP
    v-model="date"
    :class="{
      'date-picker--26px': !v2,
    }"
    :disabled="disabled"
    :lang="lang"
    :placeholder="placeholder"
    format="DD.MM.YYYY"
  />
</template>

<script>
import DP from 'vue2-datepicker';

import { getFormattedDate, getDateInRfc3339 } from '@/mixins/dateTime';

export default {
  name: 'DatePicker',
  components: {
    DP,
  },
  props: {
    /**
     * Chosen date value as a RFC string or null.
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * Whether datepicker should not be usable.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Placeholder displayed in picker.
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Whether to use the v2 version of datepicker. Currently this just
     * changes the height of the picker input.
     */
    v2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          monthsShort: [
            'jaan',
            'veebr',
            'märts',
            'apr',
            'mai',
            'juuni',
            'juuli',
            'aug',
            'sept',
            'okt',
            'nov',
            'dets',
          ],
        },
        days: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
        months: [
          'Jaanuar',
          'Veebruar',
          'Märts',
          'Aprill',
          'Mai',
          'Juuni',
          'Juuli',
          'August',
          'September',
          'Oktoober',
          'November',
          'Detsember',
        ],
      },
    };
  },
  computed: {
    date: {
      get() {
        return getFormattedDate(this.value, true);
      },
      set(value) {
        this.$emit('input', getDateInRfc3339(value));
      },
    },
  },
};
</script>

<style>
.mx-datepicker {
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
}

.date-picker--26px .mx-input {
  height: 26px !important;
}

.mx-input-append {
  padding: 5px !important;
  width: 38px !important;
}

.mx-clear-wrapper {
  padding: 3px !important;
}
</style>
