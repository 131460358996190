import req from '../lib/req';

/**
 * @deprecated Use api/modules/bankAccount.ts instead.
 */
export function fetchByIds(idList) {
  const params = {
    id: idList,
  };

  return req.get('/bank-account', {
    params: {
      where: JSON.stringify(params),
    },
  });
}

/**
 * @deprecated Use api/modules/bankAccount.ts instead.
 */
export function find(id) {
  return req.get(`/bank-account/${id}`);
}

/**
 * @deprecated Use api/modules/bankAccount.ts instead.
 */
export function update(id, data) {
  return req.put(`/bank-account/${id}`, data);
}

/**
 * @deprecated Use api/modules/bankAccount.ts instead.
 */
export function create(account) {
  return req.post('/bank-account', account);
}
