import req from '@/api/lib/req';
import { Id } from '@/types';
import { Collection } from '@/types/ApiPlatform/Collection';
import { Single } from '@/types/ApiPlatform/Single';
import { BulkActionNew, BulkActionSaved } from '@/types/BulkAction';
import { BulkActionStatus } from '@/static/enums/bulkActionStatus';
import { Filters } from '@/api/caseTable/Filters';
import { stringifyFilters } from '@/api/caseTable/stringifyFilters';

export type BulkActionsByDate = {
  date: string;
  bulkActions: BulkActionSaved[];
};

/**
 * Finds a single bulk action by id.
 */
export async function findById(id: Id): Promise<Single<BulkActionSaved>> {
  return req.get(`/v2/bulk_actions/${id}`).then((resp) => resp.data);
}

/**
 * Finds a paged result of bulk actions grouped by date.
 */
export async function findGroupedByDatePaged(
  daysPerPage?: number,
  page?: number,
  limit?: number
): Promise<Collection<BulkActionsByDate>> {
  return req
    .get(`/v2/bulk_actions_by_date?daysPerPage=${daysPerPage || 7}&page=${page || 1}&limit=${limit || 50}`)
    .then((resp) => resp.data);
}

/**
 * Finds a paged result of bulk actions from a single date.
 */
export async function findByDatePaged(
  date: string,
  page?: number,
  limit?: number
): Promise<Collection<BulkActionSaved>> {
  return req
    .get(`/v2/bulk_actions_of_date?date=${date}&page=${page || 1}&limit=${limit || 50}`)
    .then((resp) => resp.data);
}

/**
 * Creates a bulk action entity.
 */
export async function create(
  actionNew: BulkActionNew
): Promise<Single<BulkActionSaved>> {
  return req.post(`/v2/bulk_actions`, actionNew).then((resp) => resp.data);
}

/**
 * Adds cases that are currently filtered to the given action.
 */
export async function addCases(
  action: BulkActionSaved,
  filters: Filters
): Promise<void> {
  return req
    .post(`/v2/bulk_actions/${action.id}/cases`, {
      filters: stringifyFilters(filters),
    })
    .then((resp) => resp.data);
}

/**
 * Reschedules the tasks of given bulk action and status for retrying.
 * If status is passed as null then *all* tasks of action are retried.
 */
export async function retry(
  action: BulkActionSaved,
  status: BulkActionStatus | null
): Promise<Single<BulkActionSaved>> {
  return req
    .put(`/v2/bulk_action/${action.id}/retry`, { status })
    .then((resp) => resp.data);
}

/**
 * Marks the given bulk action as cancelled, meaning if any further
 * sub-tasks that are not yet processed will be ignored.
 */
export async function cancel(
  action: BulkActionSaved
): Promise<Single<BulkActionSaved>> {
  return req
    .put(`/v2/bulk_action/${action.id}/cancel`)
    .then((resp) => resp.data);
}

/**
 * Marks the given bulk action as successful.
 */
export async function success(
  action: BulkActionSaved
): Promise<Single<BulkActionSaved>> {
  return req
    .put(`/v2/bulk_action/${action.id}/success`)
    .then((resp) => resp.data);
}

/**
 * Marks the given bulk action as failed.
 */
export async function fail(
  action: BulkActionSaved
): Promise<Single<BulkActionSaved>> {
  return req.put(`/v2/bulk_action/${action.id}/fail`).then((resp) => resp.data);
}
