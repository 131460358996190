import api from "@/api";
import FeatureFlag from "../FeatureFlag.vue";
import { showErrorNotification } from "@/mixins/notification";
import { downloadFile } from "@/utils/downloadFile";

export default {
  name: 'PartialSidenav',
    components: { FeatureFlag },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userManuals: [],
    }
  },
  computed: {
    classList() {
      return {
        open: this.open,
      };
    },
  },
  async created() {
    this.fetchUserManuals();
  },
  methods: {
    async fetchUserManuals() {
      this.userManuals = (await api.manual.findAllUserManualDocuments())['hydra:member'];
    },
    async downloadUserManuals() {
      try {
        await this.fetchUserManuals();

        this.userManuals.forEach(async (file) => {
          await downloadFile(() => api.manual.download(file), file.name);
        })
      } catch (e) {
        showErrorNotification({ text: 'Kasutusjuhendi allalaadimine ebaõnnestus.' });
      }
    }
  }
};
