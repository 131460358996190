<template>
  <div class="topnav">
    <div class="topnav--section">
      <div class="sidenav--toggle" @click="$emit('nav-click', $event)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 24 24"
        >
          <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
        </svg>
      </div>
      <div class="logo">
        <a href="#" @click.prevent="goToDashboard">
          <img src="@/static/images/KPKLogo.png" alt="logo" />
        </a>
      </div>
    </div>
    <div class="topnav--section">
      <FeatureFlag if-enabled="FF_FILTERS_V1">
        <form
          class="ui mini action input search-form"
          @submit.prevent="handleSearch"
        >
          <input
            v-model="searchValue"
            :placeholder="$t('case.search.case')"
            name="search"
            type="text"
          />
          <sui-button icon="search" size="mini" type="submit" />
        </form>
      </FeatureFlag>

      <Dropdown
        v-model="role"
        :options="roles"
        :filter="true"
        append-to="body"
        option-label="name"
      >
        <template #value="slotProps">
          {{ slotProps.value.name }}
        </template>
      </Dropdown>
      <Button
        class="p-button"
        icon="pi pi-sign-out"
        icon-pos="right"
        label="Välju"
        @click="signOut"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { SET_TOKEN, SET_TENANT_ID } from '../../store/modules/auth';
import FeatureFlag from '@/components/FeatureFlag.vue';

export default {
  name: 'PartialTopmenu',
  components: {
    FeatureFlag,
  },
  computed: {
    ...mapGetters('user', ['hasError', 'user', 'hasUser', 'isLoading']),
    ...mapGetters('app', ['search']),
    ...mapGetters('auth', ['tenant_id']),
    role: {
      get() {
        if (this.roles.length === 0 || this.tenant_id === null) {
          return {};
        }
        return this.roles.filter((role) => role.id === this.tenant_id)[0];
      },
      async set(role) {
        await this.$store.dispatch('user/setRole', role.id);
        if (!this.hasError) {
          this.$store.commit(`auth/${SET_TENANT_ID}`, role.id);
          this.$router.go();
        }
      },
    },
    searchValue: {
      get() {
        return this.search;
      },
      set(value) {
        this.$store.commit('app/updateSearch', value);
      },
    },
    roles: {
      get() {
        if (!this.user || this.user.roles.length === 0) {
          return [];
        }
        return this.user.roles.map((role) => ({
          id: role.tenant_id,
          name: `${this.user.name} - ${role.role} - ${role.tenant_code} - ${role.tenant_name}`,
        }));
      },
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch('user/signOut').finally(() => {
        this.$store.commit(`auth/${SET_TOKEN}`, null);
        this.$store.commit(`auth/${SET_TENANT_ID}`, null);
        this.$router.push({ name: 'SignIn' });
      });
    },
    handleSearch(event) {
      const searchTerm = event.target[0].value;

      if (searchTerm) {
        this.$router.push({ name: 'CaseListLegacy', params: { search: searchTerm } });
      }
    },
    goToDashboard() {
      this.$router.push({ name: 'Dashboard' });
    },
  },
};
</script>
<style>
.topnav--section .p-inputtext {
  padding: 0.3rem 0.5rem;
}
.topnav--section .p-button {
  margin-left: 14px;
}
.logo {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.8rem;
  margin-left: 1rem;
}
.logo img {
  height: 30px;
}
.topnav {
  color: white;
  background: var(--primary-color);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* z-index modified so top menu bar will stay on top of all other content at all time */
  z-index: 10;
}
.topnav--section {
  display: flex;
  align-items: center;
}
.sidenav--toggle {
  margin-left: 0.5rem;
  cursor: pointer;
}
.sidenav--toggle:hover {
  background: rgba(255, 255, 255, 0.1);
}
.sidenav--toggle > svg {
  margin: 0;
  width: 2rem;
  height: auto;
  fill: white;
  transform: translateY(2px);
}
.topnav--search.ui.input {
  margin: 0 1rem;
}
.inputfile {
  display: none;
}
.topnav .inputfile + label {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  background-color: #e0e1e2;
  display: inline-block;
  padding: 0.78571429em 0.78571429em 0.78571429em;
  font-size: 0.78571429rem;
  border-radius: 0.28571429rem;
  line-height: 1em;
  cursor: pointer;
}
.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #cacbcd;
  color: rgba(0, 0, 0, 0.8);
}

.search-form {
  margin: 0 1rem;
}

.action.input {
  margin-bottom: 0;
}
</style>
