import req from '@/api/lib/req';
import { ClassifierTag } from '@/static/enums/classifierTag';
import { ClassifierValue } from '@/types';
import { Collection } from '@/types/ApiPlatform/Collection';

/**
 * Fetches classifier values of given tag.
 */
export async function findClassifierValuesByTag(
  tag: ClassifierTag
): Promise<Collection<ClassifierValue>> {
  return req
    .get(`/v2/classifier_values?classifier.tag=${tag}&active=1`)
    .then((resp) => resp.data);
}