import req from './lib/req';

export default {
  fetchMainClaimsforCaseList(caseIds) {
    const params = {
      enforcementCase: caseIds,
      mainClaim: true,
    };
    return req.get('/claim', {
      params: {
        where: JSON.stringify(params),
      },
    });
  },
  update(id, data) {
    return req.put(`/claim/${id}`, data);
  },
};
