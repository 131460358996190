import { Filters } from '@/api/caseTable/Filters';
import { CaseStatus } from '@/static/enums/caseStatus';
import { PersonType } from '@/static/enums/personType';

/**
 * Returns default "empty" filters for case table.
 */
export function createDefaultCaseFilters(): Filters {
  return {
    arrests: {},
    arrestSequence: {},
    'claimant.type': [PersonType.JURIDICAL, PersonType.PRIVATE],
    'claimant.name': '',
    'claimant.regCode': '',
    'debtor.type': [PersonType.JURIDICAL, PersonType.PRIVATE],
    'debtor.name': '',
    'debtor.regCode': '',
    debtorMainBankAccountExists: null,
    status: [CaseStatus.OPEN, CaseStatus.PARTIALLY_CLOSED],
    'enforcementCaseSolution.type': [],
    'enforcementCaseSolution.resolver': [],
    mainClaimType: [],
    number: '',
    exists: {},
    deliveryDate: {},
    voluntaryComplianceDate: {},
    dateClosed: {},
    dateOpened: {},
    'balance.cents': {},
    globalSearchTerm: '',
    includeAllBailiffs: false,
    failedQueryTypes: null,
    caseSelection: null,
    'problems.type': [],
  };
}
