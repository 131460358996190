import { Option } from '@/components/FailedQueryFilterPicker/Option';
import { QueryType } from '@/static/enums/QueryType';
import { TarnType } from '@/static/enums/tarnType';

export function getOptions(): Option[] {
  return [
    {
      label: 'Lõpetamine',
      value: {
        queryTypes: [QueryType.TARN_EDIT],
        tarnTypes: [TarnType.TYPE_END],
      },
    },
    {
      label: 'Osaline lõpetamine',
      value: {
        queryTypes: [QueryType.TARN_EDIT],
        tarnTypes: [TarnType.TYPE_PARTIAL_END],
      },
    },
    {
      label: 'Peatamine',
      value: {
        queryTypes: [QueryType.TARN_EDIT],
        tarnTypes: [TarnType.TYPE_STOP],
      },
    },
    {
      label: 'Peatamise lõpetamine',
      value: {
        queryTypes: [QueryType.TARN_EDIT],
        tarnTypes: [TarnType.TYPE_STOP_CANCEL],
      },
    },
    {
      label: 'Taasavamine',
      value: {
        queryTypes: [QueryType.TARN_EDIT],
        tarnTypes: [TarnType.TYPE_REOPEN],
      },
    },
    {
      label: 'TARN-i lisamine',
      value: {
        queryTypes: [QueryType.TARN_ADD],
        tarnTypes: [],
      },
    },
    {
      label: 'TARN-i vastuvõtmine',
      value: {
        queryTypes: [QueryType.TARN_EDIT],
        tarnTypes: [TarnType.TYPE_ACCEPT],
      },
    },
    {
      label: 'TARN NTK lisamine',
      value: {
        queryTypes: [QueryType.TARN_NTK_ADD],
        tarnTypes: [],
      },
    },
    {
      label: 'TARN NTK muutmine',
      value: {
        queryTypes: [QueryType.TARN_NTK_CHANGE],
        tarnTypes: [],
      },
    },
  ];
}
