<template>
  <div>
    <template v-if="caseInfo.id && debtorData.id">
      <Arrests :case-info="caseInfo" :debtor-data="debtorData" @onChange="$emit('onChange')"/>
      <ArchivedArrestsTable :case-id="caseInfo.id" />
    </template>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Arrests from './Arrest/Arrests.vue';
import ArchivedArrestsTable from '@/components/Case/ArchivedArrestsTable';

export default {
  name: 'PropertiesTab',
  components: {
    Arrests,
    ArchivedArrestsTable,
  },
  props: {
    /**
     * Current case object that was retrieved from v1 api,
     * see EnforcementCase.yml for serialized fields.
     */
    caseInfo: {
      type: Object,
      default: () => {},
    },
    debtorData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    caseId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('cases', {
      selectCase: 'selectCase',
    }),
  },
};
</script>
<style src="./Properties.css"></style>
