<template>
  <div>
    <DataTable
      :value="logs"
      responsive-layout="scroll"
      :loading="loading"
      :row-hover="true"
      :paginator="true"
      :rows="perPage"
      :lazy="true"
      :total-records="totalRecords"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rows-per-page-options="[10, 25, 50]"
      class="p-datatable-striped"
      @page="onPage($event)"
    >
      <Column field="id" :header="$t('id')"></Column>
      <Column field="action" :header="$t('log.action')"></Column>
      <Column field="logged_at" :header="$t('log.datetime')">
        <template #body="slotProps">
          {{ getFormattedDateTime(slotProps.data.logged_at) }}
        </template>
      </Column>
      <Column field="object_id" :header="$t('log.relatedId')"></Column>
      <Column field="object_class" :header="$t('log.object')"> </Column>
      <Column field="version" header="Versioon"> </Column>
      <Column field="username" :header="$t('log.author')"> </Column>
      <Column field="message" header="Sisu">
        <template #body="slotProps">
          <a href="#" @click.prevent="toggleModal(slotProps.data)">
            {{ $t('log.show') }}
          </a>
        </template>
      </Column>
    </DataTable>

    <Modal :open="modalOpen" @close="toggleModal">
      <template slot="modal-header">
        {{ modalContent.related_class }}
      </template>

      <template slot="modal-content">
        <div class="log-message">
          <tree-view
            :data="modalContent.data"
            :options="{ maxDepth: 3 }"
          ></tree-view>
        </div>
      </template>

      <template slot="modal-actions">
        <sui-button primary @click.native="toggleModal">
          {{ $t('close') }}
        </sui-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import api from '../api';
import { getFormattedDateTime } from '../mixins/dateTime';
import Modal from '../components/Modal.vue';

export default {
  name: 'LogTable',
  components: {
    Modal,
  },
  props: {
    caseId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      logs: [],
      loading: false,
      totalRecords: 1,
      perPage: 25,
      page: 0,
      modalOpen: false,
      modalContent: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      api.log
        .getCaseLogs(this.caseId, this.getParams())
        .then((response) => {
          this.logs = response.data.items;
          this.totalRecords = response.data.total_item_count;
          this.perPage = response.data.items_per_page;
          this.page = response.data.current_page;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getParams() {
      let params = {
        case_id: this.caseId,
        limit: this.perPage,
        page: this.page + 1,
      };
      if (this.$route.query.transfer_id) {
        params = {
          ...params,
          transfer_id: this.$route.query.transfer_id,
        };
      }
      return params;
    },
    toggleModal(content) {
      this.modalOpen = !this.modalOpen;
      this.modalContent = content;
    },
    onPage(event) {
      this.perPage = event.rows;
      this.page = event.page;
      this.getData();
    },
    getFormattedDateTime,
  },
};
</script>

<style>
.log-message {
  white-space: pre-line;
}
</style>
