<template>
  <div
    :data-tooltip="tooltipText"
    data-position="top center"
    data-inverted
    class="input-tooltip"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputTooltip',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    tooltipText: {
      get() {
        return this.text || null;
      },
    },
  },
};
</script>

<style scoped>
.input-tooltip::after {
  width: unset;
  white-space: nowrap;
}
</style>
