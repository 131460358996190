import DefaultLayout from './layouts/Default/Default.vue';
import EmptyLayout from './layouts/Empty/Empty.vue';

export default {
  components: {
    [DefaultLayout.name]: DefaultLayout,
    [EmptyLayout.name]: EmptyLayout,
  },
  computed: {
    needDefaultLayout() {
      return DefaultLayout.name === this.$route.meta.layout;
    },
  },
};
