import { AxiosResponse } from 'axios';
import req from '../lib/req';
import { IssuerFeeTable, IssuerFeeTableRow } from '@/types';

export function findAll(): Promise<IssuerFeeTable[]> {
  return req.get('/issuer-fee-table').then((response) => response.data);
}

export function getRows(table: IssuerFeeTable): Promise<IssuerFeeTableRow[]> {
  return req
    .get('/issuer-fee-row', {
      params: {
        issuer_fee_table: table.id,
      },
    })
    .then((response) => response.data);
}
export function create(
  table: IssuerFeeTable
): Promise<AxiosResponse<IssuerFeeTable>> {
  return req.post('/issuer-fee-table', table);
}

export function deleteTable(
  table: IssuerFeeTable
): Promise<AxiosResponse<IssuerFeeTable>> {
  return req.delete(`/issuer-fee-table/${table.id}`);
}
