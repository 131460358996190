import { Filters } from '@/api/caseTable/Filters';
import { PersonType } from '@/static/enums/personType';
import { Id } from '@/types';

/**
 * Helping function to stringify (or nullify) filters values, that are entity
 * objects or numbers.. etc, before sending the request to back-end.
 *
 * Api Platform expects all filter values to be strings. So in order
 * to allow filtering by non-string values, we have to convert
 * them into strings manually. Also note that if some "array" filter
 * is empty, then null should be passed instead of an empty array,
 * to make sure that filter would be fully ignored by api platform.
 *
 * NB: When new filters are added, then it may be needed to add logic
 * here to convert that filter values into strings.
 */
export function stringifyFilters(
  filters: Filters
): Record<keyof Filters & { caseSelections: Id | null }, any> {
  return {
    ...filters,
    arrests: filters.arrests.bank
      ? {
          ...filters.arrests,
          bank: toIdsOrNull(filters.arrests.bank),
        }
      : filters.arrests,
    // Passing null when all values are chosen in filters to avoid making
    // any unneeded table joins, if we dont care which exact types are found.
    'claimant.type': allPersonTypesChosen(filters['claimant.type'])
      ? null
      : toStringsOrNull(filters['claimant.type']),
    'claimant.regCode': isEmptyString(filters['claimant.regCode'])
      ? null
      : filters['claimant.regCode'],
    // Passing null when all values are chosen in filters to avoid making
    // any unneeded table joins, if we dont care which exact types are found.
    'debtor.type': allPersonTypesChosen(filters['debtor.type'])
      ? null
      : toStringsOrNull(filters['debtor.type']),
    'debtor.regCode': isEmptyString(filters['debtor.regCode'])
      ? null
      : filters['debtor.regCode'],
    'enforcementCaseSolution.resolver': toStringsOrNull(
      filters['enforcementCaseSolution.resolver']
    ),
    'enforcementCaseSolution.type': toStringsOrNull(
      filters['enforcementCaseSolution.type']
    ),
    mainClaimType: toIdsOrNull(filters.mainClaimType),
    number: isEmptyString(filters.number) ? null : filters.number,
    status: toStringsOrNull(filters.status),
    'problems.type': toStringsOrNull(filters['problems.type']),
    // For api platform we need to use the associative entities collection
    // field name here to have correct filtering for case selections.
    caseSelections: filters.caseSelection,
  };
}

/**
 * Converts array of number values into string values
 * or null of no values are given.
 */
function toStringsOrNull(values: number[]): string[] | null {
  if (values.length === 0) {
    return null;
  }

  return values.map((val) => val.toString());
}

/**
 * Converts array of entity values with ids into ids array
 * or null of no entitites are given.
 */
function toIdsOrNull(entitites: { id: Id }[]): Id[] | null {
  if (entitites.length === 0) {
    return null;
  }

  return entitites.map((e) => e.id);
}

/**
 * Whether given types include all possible PersonType values.
 */
function allPersonTypesChosen(types: PersonType[]): boolean {
  return (
    types.length ===
    Object.values(PersonType).filter((v) => typeof v === 'number').length
  );
}

/**
 * Whether given string is empty or not.
 */
function isEmptyString(value: string): boolean {
  return '' === value.trim();
}