<template>
  <sui-grid>
    <sui-grid-column :width="16" :large-screen="2" :widescreen="2">
      <h3>{{ $t('claim.sum') }}</h3>
    </sui-grid-column>

    <sui-grid-column :width="16" :large-screen="14" :widescreen="14">
      <sui-form-fields v-if="model.type === 0" :fields="4">
        <sui-form-field>
          <label>{{ $t('claim.sumCharged') }}</label>
          <CurrencyInputNumber
            v-model="model.sumCharged"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>

        <sui-form-field>
          <label>
            {{ $t('claim.mainSum') }}
            <Tooltip
              text="Väärtus null tuleneb Taavist, kuna Taavis on vaid üks summa mida kasutatakse"
            />
          </label>
          <CurrencyInputNumber
            v-model="model.sumFull"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>

        <sui-form-field :width="8" style="position: relative">
          <label>{{ $t('claim.description') }}</label>
          <TextArea
            v-model="model.payment_desc"
            :disabled="disabled"
            rows="1"
            :auto-resize="true"
            @input="handleInput"
          />
        </sui-form-field>
      </sui-form-fields>

      <sui-form-fields v-if="!model.type || model.type === 1">
        <sui-form-field :width="4">
          <sui-checkbox
            v-model="model.solidarity"
            :disabled="disabled"
            :label="$t('claim.solitary')"
            @input="() => handleToggle('solidarity')"
          />
        </sui-form-field>

        <sui-form-field v-if="model.solidarity" :width="8">
          <label>{{ $t('claim.solidDebtors') }}</label>
          <Input
            v-model="model.solidarityDebtors"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>
      </sui-form-fields>

      <sui-form-fields v-if="model.type === 2">
        <sui-form-field v-if="!model.depends_on_min_subsistence" :width="4">
          <label>{{ $t('claim.periodicSum') }}</label>
          <CurrencyInputNumber
            v-model="model.sumPeriodical"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>
        <sui-form-field :width="6">
          <label>Elatise sissenõudmise tasu suurus</label>
          <CurrencyInputNumber
            v-model="model.periodicAmountAlimony"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>
      </sui-form-fields>
      <sui-form-fields v-if="model.type === 2">
        <sui-form-field>
          <label>{{ $t('claim.periodStart') }}</label>
          <DatePicker
            v-model="model.periodStart"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>

        <sui-form-field>
          <label>{{ $t('claim.periodEnd') }}</label>
          <DatePicker
            v-model="model.periodEnd"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>
        <sui-form-field>
          <label>{{ $t('claim.dueDate') }}</label>
          <Select
            v-model="model.due_date_type"
            :disabled="disabled"
            :options="claimDueDates"
            @input="handleInput"
          />
        </sui-form-field>
        <sui-form-field v-if="model.due_date_type === 0">
          <label>Kuupäev</label>
          <Select
            v-model="model.periodicDueDay"
            :options="periodicDueDays"
            :clearable="false"
            option-label="label"
            place-holder="Kuupäev"
            @input="handleInput"
          />
        </sui-form-field>
      </sui-form-fields>
      <sui-form-fields :width="6">
        <sui-form-field>
          <label
            >{{ $t('claim.referenceNumber') }}
            <i
              v-if="!disabled"
              v-tooltip="'Genereeri'"
              class="pi pi-cog"
              @click="generateRefNo"
            ></i>
          </label>
          <Input
            v-model="model.referenceNo"
            :disabled="disabled"
            icon="barcode"
            @input="handleInput"
          />
        </sui-form-field>
      </sui-form-fields>
    </sui-grid-column>
  </sui-grid>
</template>

<script>
import api from '../../../api';
import DatePicker from '../../DatePicker.vue';
import Input from '../../Input.vue';
import Tooltip from '../../Tooltip.vue';
import CurrencyInputNumber from '../../CurrencyInputNumber.vue';
import { showErrorNotification } from '@/mixins/notification';
import Select from '@/components/Select.vue';
import { SettingType } from '@/static/enums/settingType';

export default {
  name: 'Sum',
  components: {
    CurrencyInputNumber,
    DatePicker,
    Input,
    Select,
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      claimDueDates: [],
    };
  },
  computed: {
    model: {
      get() {
        return { ...this.data };
      },
    },
    periodicDueDays() {
      return [...Array(28).keys()].map((item) => {
        return { display_name: item + 1, key: item + 1 };
      });
    },
  },
  async mounted() {
    this.claimDueDates = await this.getClaimDueDates();
  },
  methods: {
    async getClaimDueDates() {
      if (this.$store.getters['settings/claimDueDates']) {
        return this.$store.getters['settings/claimDueDates'].value.map(
          (item) => {
            return { display_name: item.display_name, key: item.key };
          }
        );
      }
      return api.settings
        .fetchByType(
          this.$store.getters['auth/tenant_id'],
          SettingType.CLAIM_DUE_DATE
        )
        .then(([resp]) => {
          return resp.value.map((item) => {
            return { display_name: item.display_name, key: item.key };
          });
        });
    },
    handleInput() {
      this.$emit('update', this.model);
    },
    handleToggle(name) {
      this.model[name] = !this.model[name];
      this.$emit('update', this.model);
    },
    generateRefNo() {
      if (this.model.id === null || undefined === this.model.id) {
        showErrorNotification({
          text: this.$t('error.ref_no_generator.save_first'),
        });
        return;
      }
      api.claim.generateRefNo(this.model).then((response) => {
        if (
          response.data.referenceNo !== undefined &&
          response.data.referenceNo !== ''
        ) {
          this.data.referenceNo = response.data.referenceNo;
          this.model.referenceNo = response.data.referenceNo;
          this.$emit('update', this.model);
        }
      });
    },
  },
};
</script>
<style>
button.ui.circular.icon {
  bottom: 0.5rem;
  position: fixed;
  right: 1rem;
}

.ui.button[data-tooltip] {
  padding: 0 0.2rem;
}
</style>
