/**
 * Enum values for used ClassifierValue tag values.
 * NB: Same values as in ClassifierValue.php! Keep in sync.
 */
export enum ClassifierTag {
  HOLIDAY = 'holiday',
  STOP_REASON = 'stop_reason',
  CURRENCY = 'currency',
  CLOSE_BASIS = 'close_basis',
  COUNTRY = 'country',
}
