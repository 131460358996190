<template>
  <Accordion>
    <AccordionTab header="Üleandmise ootel olevad toimikud">
      <div>
        <sui-table class="transfer-case-table__table" striped>
          <sui-table-header class="transfer-case-table__header">
            <sui-table-row>
              <sui-table-header-cell>Üleandev täitur</sui-table-header-cell>
              <sui-table-header-cell>Kuupäev</sui-table-header-cell>
              <sui-table-header-cell>Toimikud</sui-table-header-cell>
              <sui-table-header-cell>Märkus</sui-table-header-cell>
              <sui-table-header-cell></sui-table-header-cell>
            </sui-table-row>
          </sui-table-header>
          <sui-table-body v-if="transfers.length">
            <sui-table-row
              v-for="caseTransfer in transferList"
              :key="caseTransfer.id"
            >
              <sui-table-cell>{{
                caseTransfer.name_person_giving
              }}</sui-table-cell>
              <sui-table-cell>{{
                getFormattedDateTime(caseTransfer.date_initiated)
              }}</sui-table-cell>
              <sui-table-cell>
                <div v-if="caseTransfer.case_ids.length > 3">
                  <sui-list>
                    <sui-list-item>
                      <sui-popup flowing hoverable position="bottom">
                        <sui-list>
                          <sui-list-item>Esimesed {{ caseTransfer.case_ids.length }} toimikut..</sui-list-item>
                          <sui-list-item
                            v-for="transferCase in caseTransfer.case_ids"
                            :key="transferCase.id"
                          >
                            <router-link
                              :to="{
                                name: 'Case',
                                params: { id: transferCase.id },
                                query: { transfer_id: caseTransfer.id },
                              }"
                            >
                              <a target="_blank">{{ transferCase.number }}</a>
                            </router-link>
                          </sui-list-item>
                        </sui-list>
                        <div slot="trigger"> {{ caseTransfer.case_ids[0].total }} toimikut kokku.. </div>
                      </sui-popup>
                    </sui-list-item>
                  </sui-list>
                </div>
                <div v-else>
                  <sui-list>
                    <sui-list-item
                      v-for="transferCase in caseTransfer.case_ids"
                      :key="transferCase.id"
                    >
                      <router-link
                        :to="{
                          name: 'Case',
                          params: { id: transferCase.id },
                          query: { transfer_id: caseTransfer.id },
                        }"
                      >
                        <a target="_blank">{{ transferCase.number }}</a>
                      </router-link>
                    </sui-list-item>
                  </sui-list>
                </div>
              </sui-table-cell>
              <sui-table-cell>{{ caseTransfer.description }}</sui-table-cell>
              <sui-table-cell>
                <sui-button
                  :loading="loading"
                  :disabled="loading"
                  @click="acceptTransfer(caseTransfer)"
                >
                  Võta vastu
                </sui-button>
                <sui-button
                  :loading="loading"
                  :disabled="loading"
                  @click="openRejectModal(caseTransfer)"
                >
                  Keeldu
                </sui-button>
              </sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>
        <transfer-case-reject-modal
          :transfer="transfer"
          :open="isRejectModalOpen"
          @onToggle="toggleRejectModal()"
          @onReject="rejectTransfer"
        ></transfer-case-reject-modal>
      </div>
    </AccordionTab>
  </Accordion>
</template>
<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { getFormattedDateTime } from '../../mixins/dateTime';
import TransferCaseRejectModal from './TransferCaseRejectModal.vue';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../mixins/notification';
import api from '../../api';

export default {
  name: 'TransferCaseTable',
  components: {
    Accordion,
    AccordionTab,
    TransferCaseRejectModal,
  },
  props: {
    transfers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      transferList: [],
      isRejectModalOpen: false,
      transfer: null,
    };
  },
  mounted() {
    Promise.all(
      this.transfers.map(async (transfer) => ({
        ...transfer,
        case_ids: await (
          await api.caseTransfer.fetchCaseInfo(transfer.id)
        ).data,
      }))
    ).then((result) => {
      this.transferList = result;
    });
  },
  methods: {
    openRejectModal(transfer) {
      this.transfer = transfer;
      this.toggleRejectModal();
    },
    toggleRejectModal() {
      this.isRejectModalOpen = !this.isRejectModalOpen;
    },
    acceptTransfer(transfer) {
      this.loading = true;

      api.caseTransfer
        .accept(transfer)
        .then(() => {
          showSuccessNotification({
            text: 'Toimikute vastuvõtmisega nõustutud.',
          });
          const index = this.transferList.indexOf(transfer);
          if (index > -1) {
            this.transferList.splice(index, 1);
          }
        })
        .catch((error) => {
          showErrorNotification({ text: error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rejectTransfer(transfer) {
      this.loading = true;

      api.caseTransfer
        .decline(transfer, transfer.decline_reason)
        .then(() => {
          showSuccessNotification({ text: 'Üleandmisest keeldutud.' });
          const index = this.transferList.indexOf(transfer);
          if (index > -1) {
            this.transferList.splice(index, 1);
          }
          this.toggleRejectModal();
        })
        .catch((error) => {
          showErrorNotification({ text: error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFormattedDateTime,
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .p-accordion-content {
    padding: 0 !important;
  }
}

.transfer-case-table__table {
  border-radius: 0;
}

.transfer-case-table__table th,
.transfer-case-table__table td {
  color: #495057 !important;
}
</style>
