import VueI18n from 'vue-i18n';

import { OptionType } from '@/components/DateFilterPicker/OptionType';

/**
 * Type for option to be used for selecting the desired field or
 * a specific date (null) value to compare against.
 */
export type Option = {
  label: string;
  value: string;
  type: OptionType;
};

/**
 * Returns default comparison target options for DateFilterPicker.
 */
export function getOptions(i18n: VueI18n, blackListedField: string): Option[] {
  return [
    {
      label: i18n.t('dateFilterPicker.option.specificDate').toString(),
      value: '', // Value does nothing for this option.
      type: OptionType.SPECIFIC_DATE,
    },
    {
      label: i18n
        .t('dateFilterPicker.option.voluntaryComplianceDate')
        .toString(),
      value: 'voluntaryComplianceDate',
      type: OptionType.FIELD,
    },
    {
      label: i18n.t('dateFilterPicker.option.dateOpened').toString(),
      value: 'dateOpened',
      type: OptionType.FIELD,
    },
    {
      label: i18n.t('dateFilterPicker.option.dateClosed').toString(),
      value: 'dateClosed',
      type: OptionType.FIELD,
    },
  ].filter((option) => option.value !== blackListedField);
}
