import PersonApi from '../../api/person';

const UPDATING_PERSON = 'UPDATING_PERSON';
const UPDATING_PERSON_SUCCESS = 'UPDATING_PERSON_SUCCESS';
const UPDATING_PERSON_ERROR = 'UPDATING_PERSON_ERROR';
const FETCHING_PERSONS = 'FETCHING_PERSONS';
const FETCHING_PERSONS_SUCCESS = 'FETCHING_PERSONS_SUCCESS';
const FETCHING_PERSONS_ERROR = 'FETCHING_PERSONS_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    persons: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasPersons(state) {
      return state.persons.length > 0;
    },
    persons(state) {
      return state.persons;
    },
  },
  mutations: {
    [UPDATING_PERSON](state) {
      state.isLoading = true;
      state.error = null;
      state.persons = [];
    },
    [UPDATING_PERSON_SUCCESS](state, persons) {
      state.isLoading = false;
      state.error = null;
      state.persons = persons;
    },
    [UPDATING_PERSON_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.persons = [];
    },
    [FETCHING_PERSONS](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_PERSONS_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCHING_PERSONS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    async update({ commit }, { personId, data }) {
      commit(UPDATING_PERSON);
      try {
        const response = await PersonApi.update(personId, data);
        // TODO:: use store for fetching cases and if updated, then update in store
        commit(UPDATING_PERSON_SUCCESS, []);
        return response.data;
      } catch (e) {
        commit(UPDATING_PERSON_ERROR, e);
        return null;
      }
    },
    async fetchKTs({ commit }) {
      commit(FETCHING_PERSONS);
      try {
        const response = await PersonApi.fetchKTs();
        commit(FETCHING_PERSONS_SUCCESS);
        return response.data;
      } catch (e) {
        commit(FETCHING_PERSONS_ERROR, e);
        return null;
      }
    },
  },
};
