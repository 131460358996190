import ClassifierValueApi from '../../api/classifierValue';
import ClassifierApi from '../../api/classifier';

const FETCHING_CLASSIFIER_VALUES = 'FETCHING_CLASSIFIER_VALUES';
const FETCHING_CLASSIFIER_VALUES_SUCCESS = 'FETCHING_CLASSIFIER_VALUES_SUCCESS';
const FETCHING_CLASSIFIER_VALUES_ERROR = 'FETCHING_CLASSIFIER_VALUES_ERROR';
const UPDATING_CLASSIFIER_VALUE = 'UPDATING_CLASSIFIER_VALUE';
const UPDATING_CLASSIFIER_VALUE_SUCCESS = 'UPDATING_CLASSIFIER_VALUE_SUCCESS';
const UPDATING_CLASSIFIER_VALUE_ERROR = 'UPDATING_CLASSIFIER_VALUE_ERROR';
const CREATING_CLASSIFIER_VALUE = 'CREATING_CLASSIFIER_VALUE';
const CREATING_CLASSIFIER_VALUE_SUCCESS = 'CREATING_CLASSIFIER_VALUE_SUCCESS';
const CREATING_CLASSIFIER_VALUE_ERROR = 'CREATING_CLASSIFIER_VALUE_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    [FETCHING_CLASSIFIER_VALUES](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_CLASSIFIER_VALUES_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCHING_CLASSIFIER_VALUES_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [UPDATING_CLASSIFIER_VALUE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [UPDATING_CLASSIFIER_VALUE_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [UPDATING_CLASSIFIER_VALUE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [CREATING_CLASSIFIER_VALUE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [CREATING_CLASSIFIER_VALUE_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [CREATING_CLASSIFIER_VALUE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit(FETCHING_CLASSIFIER_VALUES);
      try {
        const { data } = await ClassifierValueApi.findAll();
        commit(FETCHING_CLASSIFIER_VALUES_SUCCESS);
        return data;
      } catch (e) {
        commit(FETCHING_CLASSIFIER_VALUES_ERROR, e);
        return null;
      }
    },
    async findByName({ commit }, name) {
      commit(FETCHING_CLASSIFIER_VALUES);
      try {
        const { data } = await ClassifierApi.findByName(name);
        if (data.length === 0) {
          return [];
        }
        const classifierValues = await ClassifierValueApi.findByClassifier(
          data[0].id
        );
        commit(FETCHING_CLASSIFIER_VALUES_SUCCESS);
        return classifierValues.data;
      } catch (e) {
        commit(FETCHING_CLASSIFIER_VALUES_ERROR, e);
        return null;
      }
    },
    async findByClassifier({ commit }, classifier) {
      commit(FETCHING_CLASSIFIER_VALUES);
      try {
        const { data } = await ClassifierValueApi.findByClassifier(classifier);
        commit(FETCHING_CLASSIFIER_VALUES_SUCCESS);
        return data;
      } catch (e) {
        commit(FETCHING_CLASSIFIER_VALUES_ERROR, e);
        return null;
      }
    },
    async findOne({ commit }, id) {
      commit(FETCHING_CLASSIFIER_VALUES);
      try {
        const { data } = await ClassifierValueApi.findOne(id);
        commit(FETCHING_CLASSIFIER_VALUES_SUCCESS);
        return data;
      } catch (e) {
        commit(FETCHING_CLASSIFIER_VALUES_ERROR, e);
        return null;
      }
    },
    async update({ commit }, classifierValue) {
      commit(UPDATING_CLASSIFIER_VALUE);
      try {
        const { data } = await ClassifierValueApi.update(classifierValue);
        commit(UPDATING_CLASSIFIER_VALUE_SUCCESS);
        return data;
      } catch (e) {
        commit(UPDATING_CLASSIFIER_VALUE_ERROR, e);
        return null;
      }
    },
    async create({ commit }, classifierValue) {
      commit(CREATING_CLASSIFIER_VALUE);
      try {
        const { data } = await ClassifierValueApi.create(classifierValue);
        commit(CREATING_CLASSIFIER_VALUE_SUCCESS);
        return data;
      } catch (e) {
        commit(CREATING_CLASSIFIER_VALUE_ERROR, e);
        return null;
      }
    },
  },
};
