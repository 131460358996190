/**
 * Allowed states for an enforcement case.
 * NB: These must be kept in sync with CaseState.php in back-end!
 */
export enum CaseState {
  UNMIGRATED = 'unmigrated',
  DRAFT = 'draft',
  PROJECT = 'project',
  TARN_ACCEPT = 'tarn_accept',
  OPEN = 'open',
}

