import QueryApi from '../../api/query';
import { getFormattedName } from '../../mixins/person';
import { getPersons } from '../../mixins/util';

const FINDING_TARN_CASES = 'FINDING_TARN_CASES';
const FINDING_TARN_CASES_SUCCESS = 'FINDING_TARN_CASES_SUCCESS';
const FINDING_TARN_CASES_ERROR = 'FINDING_TARN_CASES_ERROR';
const FETCHING_QUERIES = 'FETCHING_QUERIES';
const FETCHING_QUERIES_SUCCESS = 'FETCHING_QUERIES_SUCCESS';
const FETCHING_QUERIES_ERROR = 'FETCHING_QUERIES_ERROR';
const FETCHING_QUERY = 'FETCHING_QUERY';
const FETCHING_QUERY_SUCCESS = 'FETCHING_QUERY_SUCCESS';
const FETCHING_QUERY_ERROR = 'FETCHING_QUERY_ERROR';
const PERFORMING_REQUEST = 'PERFORMING_REQUEST';
const PERFORMING_REQUEST_SUCCESS = 'PERFORMING_REQUEST_SUCCESS';
const PERFORMING_REQUEST_ERROR = 'PERFORMING_REQUEST_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    queries: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasQueries(state) {
      return state.queries.length > 0;
    },
    queries(state) {
      return state.queries;
    },
  },
  mutations: {
    [FINDING_TARN_CASES](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FINDING_TARN_CASES_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FINDING_TARN_CASES_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_QUERIES](state) {
      state.isLoading = true;
      state.error = null;
      state.queries = [];
    },
    [FETCHING_QUERIES_SUCCESS](state, queries) {
      state.isLoading = false;
      state.error = null;
      state.queries = queries;
    },
    [FETCHING_QUERIES_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.queries = [];
    },
    [FETCHING_QUERY](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_QUERY_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCHING_QUERY_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [PERFORMING_REQUEST](state) {
      state.isLoading = true;
      state.error = null;
    },
    [PERFORMING_REQUEST_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [PERFORMING_REQUEST_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    async findTarnCases({ commit }) {
      commit(FINDING_TARN_CASES);
      try {
        const response = await QueryApi.findTarnCases();
        commit(FINDING_TARN_CASES_SUCCESS);

        return response.data;
      } catch (e) {
        commit(FINDING_TARN_CASES_ERROR, e);
        return null;
      }
    },
    async fetchAll({ commit }) {
      commit(FETCHING_QUERIES);
      try {
        const response = await QueryApi.fetchAll();
        commit(FETCHING_QUERIES_SUCCESS, response.data);
        return response.data;
      } catch (e) {
        commit(FETCHING_QUERIES_ERROR, e);
        return null;
      }
    },
    async fetchByParams({ commit }, params) {
      commit(FETCHING_QUERIES);
      try {
        const response = await QueryApi.fetchByParams(params);
        commit(FETCHING_QUERIES_SUCCESS, response.data);
        return response.data;
      } catch (e) {
        commit(FETCHING_QUERIES_ERROR, e);
        return null;
      }
    },
    async getCount({ commit }, params) {
      commit(PERFORMING_REQUEST);
      try {
        const response = await QueryApi.getCount(params);
        commit(PERFORMING_REQUEST_SUCCESS);
        return response.data;
      } catch (e) {
        commit(PERFORMING_REQUEST_ERROR, e);
        return null;
      }
    },
    async fetchOne({ commit }, { id, params }) {
      commit(FETCHING_QUERY);
      try {
        const response = await QueryApi.fetchOne(id, params);
        commit(FETCHING_QUERY_SUCCESS);
        return response.data;
      } catch (e) {
        commit(FETCHING_QUERY_ERROR, e);
        return null;
      }
    },
    async fetchFormattedQueries({ commit }, params) {
      commit(FETCHING_QUERIES);
      try {
        let { data: queries } = await QueryApi.fetchByParams(params);
        const issuerIds = queries.map((query) => query.issuer);
        const issuers = await getPersons(issuerIds);
        queries = queries.map((query) => {
          const currentIssuer = issuers.find(
            (issuer) => issuer.id === query.issuer
          );
          const issuerName = getFormattedName(currentIssuer);
          return {
            ...query,
            issuerName,
          };
        });
        commit(FETCHING_QUERIES_SUCCESS, []);
        return queries;
      } catch (e) {
        commit(FETCHING_QUERIES_ERROR, e);
        return null;
      }
    },
  },
};
