<template>
  <multiselect
    v-model="model"
    :disabled="disabled"
    :options="activeOptions"
    class="single"
    deselect-label=""
    label="display_name"
    :placeholder="placeHolder"
    selected-label=""
    select-label=""
    track-by="key"
    @search-change="handleSearchChange"
  >
    <template v-if="clearable && model && !disabled" slot="clear">
      <sui-icon class="clear" name="close" @click="clear" />
    </template>

    <template slot="noOptions">
      {{ $t('noOptions') }}
    </template>

    <template slot="noResult">
      {{ $t('noResult') }}
    </template>
  </multiselect>
</template>

<script>
export default {
  name: 'Select',
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Number, String],
      default: null,
    },
    placeHolder: {
      type: String,
      default: 'Vali',
    },
  },
  computed: {
    model: {
      get() {
        if (this.value === null || typeof this.value === 'undefined')
          return null;

        return this.options.find(
          (option) => option.key.toString() === this.value.toString()
        );
      },
      set(selectedOption) {
        this.$emit('input', selectedOption.key);
      },
    },
    activeOptions: {
      get() {
        return this.options.filter(
          (option) => typeof option.active === 'undefined' || option.active
        );
      },
    },
  },
  methods: {
    clear() {
      this.$emit('input', null);
    },
    handleSearchChange(value) {
      this.$emit('search-change', value);
    },
  },
};
</script>

<style>
.multiselect {
  --height: 26px;
  --padding: 2px;
  font-size: 1rem;
  min-height: var(--height);
}

.multiselect__single {
  font-size: 1rem;
  margin-bottom: 0;
  max-height: calc(var(--height) - 4px);
  overflow: hidden;
  padding-left: 0;
  text-overflow: ellipsis;
}

.multiselect__select {
  height: calc(var(--height) - 2px);
}

.multiselect__tags {
  border-color: rgba(34, 36, 38, 0.15);
  min-height: var(--height);
  padding-right: 45px;
  padding-top: var(--padding);
}

.multiselect__input {
  border: 0 !important;
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0 !important;
}

.multiselect--active .multiselect__input {
  width: 100% !important;
}

.ui.form .multiselect__input[type='text'] {
  padding: 0 !important;
}

.multiselect__select:before {
  top: 75%;
}

.multiselect--disabled .multiselect__select {
  background-color: white;
}

.multiselect__placeholder {
  margin-bottom: 0;
  padding-top: 0;
}

.multiselect__content-wrapper {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
}

.multiselect__option {
  min-height: var(--height);
  padding: 0.3rem 0.5rem;
  white-space: normal;
}

.multiselect__tag {
  margin-bottom: var(--padding);
  margin-right: var(--padding);
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: top;
}

.multiselect__tag,
.multiselect__option--highlight {
  background-color: #2185d0;
}

.multiselect__tag-icon:hover {
  background-color: #1a5090;
}

.multiselect__tag-icon:after {
  color: white;
}

.multiselect .clear {
  color: #999;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 4px;
}

.single .multiselect__option--selected.multiselect__option--highlight {
  background-color: #2185d0;
}
</style>
