import { Id, User } from '@/types';
import req from '../lib/req';

export function findById(id: Id): Promise<User> {
  return req.get(`/user/${id}`).then((response) => response.data);
}

export function editUser(id: Id, data: Partial<User>): Promise<User> {
  return req.put(`/user/${id}`, data).then((response) => response.data);
}

export function getAll(): Promise<User[]> {
  return req.get('/user/list').then((response) => response.data);
}

export function changeEmployeeRole(userId: Id, role: any): Promise<void> {
  return req.post(`/user/${userId}/change-employee-role`, { role });
}
