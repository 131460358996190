export default {
  name: 'UiSearch',
  props: {
    category: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    icon: {
      type: String,
      default: 'search',
    },
    size: {
      type: String,
      default: '',
    },
    results: {
      type: [Array, Object],
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focused: -1,
    };
  },
  computed: {
    rootClassList() {
      const classList = {
        category: this.category,
        loading: this.loading,
      };

      if (this.size) {
        classList[this.size] = true;
      }

      return classList;
    },
  },
  watch: {
    opened(opened) {
      if (opened) {
        this.focused = -1;
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown, false);
    document.addEventListener('click', this.handleClick, false);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown, false);
    document.removeEventListener('click', this.handleClick, false);
  },
  methods: {
    handleClick(event) {
      if (!this.$refs.searchContainer.contains(event.target)) {
        this.close();
      }
    },
    handleKeyDown(event) {
      if (this.opened) {
        const results = document.getElementsByClassName('result');

        if (event.key === 'ArrowDown') {
          event.preventDefault();

          if (this.focused !== results.length - 1) {
            this.focused += 1;
            results[this.focused].focus();
          }
        }

        if (event.key === 'ArrowUp') {
          event.preventDefault();

          if (this.focused !== 0) {
            this.focused -= 1;
            results[this.focused].focus();
          }
        }

        if (event.key === 'Enter') {
          event.preventDefault();

          const clickEvent = document.createEvent('MouseEvents');
          clickEvent.initEvent('mousedown', true, true);
          results[this.focused].dispatchEvent(clickEvent);
        }

        if (event.key === 'Tab' || event.key === 'Escape') {
          this.close();
        }
      }
    },
    open() {
      this.$emit('update:opened', true);
    },
    close() {
      this.$emit('update:opened', false);
    },
    onSearchChange(e) {
      const { value } = e.target;

      this.$emit('search-change', value);
    },
    onResultSelect(result) {
      this.$emit('result-select', { ...result });
      this.close();
    },
    onSearchFocus(e) {
      this.onSearchChange(e);
    },
  },
};
