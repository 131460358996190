export function getFormattedName(person) {
  if (!person) return '';

  const { name, nameFirst, nameLast } = person;

  if (name) return name;
  if (nameFirst && nameLast) return `${nameFirst} ${nameLast}`;
  if (!nameFirst && nameLast) return `${nameLast}`;

  return '';
}
