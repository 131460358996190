import api from '@/api';
import { Store } from 'vuex';
import { Id } from '@/types';

/**
 * Function to trigger IssuerFeeUpdateModal after some action with case is
 * completed and if the desired requirements are met.
 *
 * @param fnSomeActionWithCase Promise function to do some action(s) with case.
 * @param store Vuex store instance.
 * @param caseId Id of case for which to check issuer fee.
 * @param doNotTriggerIfComputedFeeStaysTheSame Whether modal should not be
 * shown even if the current case fee differs from new computed fee, but
 * computed fees before and after have not changed.
 */
export async function triggerIssuerFeeUpdateModalIfNeeded(
  fnSomeActionWithCase: () => Promise<void>,
  store: Store<Record<string, unknown>>,
  caseId: Id,
  doNotTriggerIfComputedFeeStaysTheSame: boolean
): Promise<void> {
  const { new: computedFeeBefore, old: caseFee } =
    await api.cases.checkIssuerFeeByCaseId(caseId);

  await fnSomeActionWithCase();

  const { new: computedFeeAfter } = await api.cases.checkIssuerFeeByCaseId(
    caseId
  );

  if (
    caseFee.cents === computedFeeBefore.cents &&
    computedFeeBefore.cents === computedFeeAfter.cents
  ) {
    // No fees are changed at all.
    return;
  }

  if (caseFee.cents === computedFeeAfter.cents) {
    // Currently set fee is same as new computed fee.
    return;
  }

  if (
    caseFee.cents !== computedFeeBefore.cents &&
    computedFeeBefore.cents === computedFeeAfter.cents &&
    doNotTriggerIfComputedFeeStaysTheSame
  ) {
    // Current fee is different from new fee but both computed fees are identical,
    // meaning user has previously declined an update to fee and no new modal is wanted.
    return;
  }

  store.dispatch('cases/toggleIssuerFeeUpdateModal');
}
