import Vue from 'vue';
import { mapActions } from 'vuex';
import api from '../../api';

const SETTING_ROLE = 'SETTING_ROLE';
const SETTING_ROLE_SUCCESS = 'SETTING_ROLE_SUCCESS';
const SETTING_ROLE_ERROR = 'SETTING_ROLE_ERROR';
const FETCHING_USER = 'FETCHING_USER';
const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS';
const FETCHING_USER_ERROR = 'FETCHING_USER_ERROR';
const FETCHING_USERS = 'FETCHING_USERS';
const FETCHING_USERS_SUCCESS = 'FETCHING_USERS_SUCCESS';
const FETCHING_USERS_ERROR = 'FETCHING_USERS_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    user: [],
    selectedRole: null,
    error: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    selectedRole(state) {
      return state.selectedRole;
    },
    user(state) {
      return state.user;
    },
    hasUser(state) {
      return !!(state.user && state.user.id);
    },
    isRoleKoda(state, commit, rootState) {
      if (!state.user) {
        return false;
      }

      const curRole = state.user.roles.find(
        (role) => role.tenant_id === rootState.auth.tenant_id
      );
      return curRole.role_code === 'ROLE_KODA';
    },
    isRoleDev(state, commit, rootState) {
      if (!state.user) {
        return false;
      }
      const curRole = state.user.roles.find(
        (role) => role.tenant_id === rootState.auth.tenant_id
      );
      return curRole.role_code === 'ROLE_DEV';
    },
    isRoleKT(state, commit, rootState) {
      if (!state.user) {
        return false;
      }
      const curRole = state.user.roles.find(
        (role) => role.tenant_id === rootState.auth.tenant_id
      );
      return curRole.role_code === 'ROLE_KT';
    },
  },
  mutations: {
    [SETTING_ROLE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [SETTING_ROLE_SUCCESS](state, data) {
      state.isLoading = false;
      state.error = null;
      state.selectedRole = data.role;
    },
    [SETTING_ROLE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_USER](state) {
      state.isLoading = true;
      state.error = null;
      state.user = null;
    },
    [FETCHING_USER_SUCCESS](state, user) {
      state.isLoading = false;
      state.error = null;
      state.user = user;
    },
    [FETCHING_USER_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.user = null;
    },
    [FETCHING_USERS](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_USERS_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCHING_USERS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    clear(state) {
      state.user = null;
      state.selectedRole = null;
    },
    setUserInfo(state, userInfo) {
      state.user = userInfo;
    },
  },
  actions: {
    ...mapActions('settings', ['getSettings']),
    async fetchUser({ commit }, userId) {
      commit(FETCHING_USER);
      try {
        const user = await api.users.findById(userId);
        commit(FETCHING_USER_SUCCESS, user);
        return user;
      } catch (e) {
        commit(FETCHING_USER_ERROR, e);
        return null;
      }
    },
    async setRole({ commit }, roleId) {
      commit(SETTING_ROLE);
      try {
        const response = await api.auth.sendRoleId(roleId);
        commit(SETTING_ROLE_SUCCESS, response.data);
        return response.data;
      } catch (e) {
        commit(SETTING_ROLE_ERROR, e);
        return null;
      }
    },
    verify({ commit }) {
      api.auth.verify().then((response) => {
        api.users.findById(response.data.id).then((user) => {
          commit('setUserInfo', user);
        });
      });
    },
    signOut({ commit }) {
      commit('clear');
      Vue.$cookies.remove('vuex');
      Vue.$cookies.remove('koda-token');
      Vue.$cookies.remove('koda-id');
    },
    async fetchAll({ commit }) {
      commit(FETCHING_USERS);
      try {
        const users = await api.users.getAll();
        commit(FETCHING_USERS_SUCCESS);
        return users;
      } catch (e) {
        commit(FETCHING_USERS_ERROR, e);
        return null;
      }
    },
  },
};
