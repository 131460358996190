// If you update these, make sure to update services/koda/api/src/Entity/Tag.php as well.
export const Tags = {
  AWAITING_ARREST_CHANGE: 1,
  AWAITING_CLOSING: 2,
  AWAITING_MANUAL_ARREST: 3,
  AUTOMATIC_ARREST_FAILED: 4,
  IMPORTED_TARN_CASE: 5,
  INCOMPLETE_CASE: 6,
  AWAITING_ARREST: 7,
  AWAITING_ARREST_REMOVE: 8,
  ARREST_FULFILLED: 9,
};

export type Tab = {
  name: string,
  url: TabUrl,
  disabled: boolean,
  icon: string|null,
}

export enum TabUrl {
  OVERALL = 'overall',
  ASSETS = 'assets',
  DOCUMENTS = 'documents',
  QUERIES = 'queries',
  LOGS = 'logs',
  PROBLEMS = 'problems',
};

export const MENU_ITEMS: Tab[] = [
  {
    name: 'Üldandmed',
    url: TabUrl.OVERALL,
    disabled: false,
    icon: null,
  },
  {
    name: 'Varad',
    url: TabUrl.ASSETS,
    disabled: false,
    icon: null,
  },
  {
    name: 'Dokumendid',
    url: TabUrl.DOCUMENTS,
    disabled: false,
    icon: null,
  },
  {
    name: 'Päringud',
    url: TabUrl.QUERIES,
    disabled: false,
    icon: null,
  },
  {
    name: 'Logi',
    url: TabUrl.LOGS,
    disabled: false,
    icon: null,
  },
  {
    name: 'Probleemid',
    url: TabUrl.PROBLEMS,
    // Problems tab is only shown when problems exist for a case.
    disabled: true,
    icon: 'exclamation triangle',
  },
];
export const Actions = {
  REFRESH_AWAITING_ARREST: {
    name: 'refreshAwaitingArrest',
    info: 'refreshAwaitingArrestInfo',
  },
  CHANGE_FEE_AND_ARREST: {
    name: 'changeFeeAndArrest',
    info: 'changeFeeAndArrestInfo',
  },
  END_CASES: {
    name: 'endCases',
    info: 'endCasesInfo',
  },
  FIND_CASES: {
    name: 'refresh',
  },
  FIND_END_CASES: {
    name: 'findEndCases',
  },
  GO_TO_CASE: {
    name: 'goToCase',
    info: 'goToCaseInfo',
  },
  REMOVE_CASES: {
    name: 'removeCases',
    info: 'removeCasesInfo',
  },
  REMOVE_AWAITING_END: {
    name: 'removeAwaitingCases',
    info: 'removeCasesInfo',
  },
  FIND_TARN_CASES: {
    name: 'findTarnCases',
  },
  ACCEPT_TARN_CASE: {
    name: 'acceptTarnCase',
  },
  REFUSE_TARN_CASE: {
    name: 'refuseTarnCase',
    disabled: true,
  },
  REFRESH_MANUAL_ARREST: {
    name: 'refreshManualArrest',
  },
  REFRESH_ARREST_REMOVE: {
    name: 'refreshArrestRemove',
  },
  REMOVE_ARRESTS: {
    name: 'removeArrests',
  },
};

export const Filters = {
  ALL: {
    name: 'all',
  },
  AWAITING_CHANGE: {
    name: 'awaiting-change',
    actionsInComponent: true,
    component: () => ({
      component: import(
        '../../components/Dashboard/AwaitingArrestChange/AwaitingArrestChange.vue'
      ),
    }),
    tag: Tags.AWAITING_ARREST_CHANGE,
  },
  AWAITING_ARREST_REMOVE: {
    name: 'awaiting-arrest-remove',
    description: 'awaiting-arrest-remove-description',
    actions: [
      Actions.REFRESH_ARREST_REMOVE,
      Actions.REMOVE_ARRESTS,
      Actions.REMOVE_CASES,
    ],
    tag: Tags.AWAITING_ARREST_REMOVE,
  },
  AWAITING_FINISH: {
    name: 'awaiting-finish',
    actionsComponent: () => ({
      component: import(
        '../../components/Dashboard/AwaitingFinish/Actions.vue'
      ),
    }),
    tag: Tags.AWAITING_CLOSING,
  },
  ARREST_FAILED: {
    name: 'arrest-failed',
    component: () => ({
      component: import(
        '../../components/Dashboard/FailedArrests/FailedArrests.vue'
      ),
    }),
    actions: [Actions.GO_TO_CASE],
    tag: Tags.AUTOMATIC_ARREST_FAILED,
  },
  AWAITING_ARREST: {
    name: 'awaiting-arrest',
    description: 'awaiting-arrest-description',
    actions: [Actions.REFRESH_AWAITING_ARREST, Actions.REMOVE_CASES],
    tag: Tags.AWAITING_ARREST,
  },
  MANUAL_ARREST: {
    name: 'manual-arrest',
    description: 'manual-arrest-description',
    actions: [
      Actions.GO_TO_CASE,
      Actions.REMOVE_CASES,
      Actions.REFRESH_MANUAL_ARREST,
    ],
    tag: Tags.AWAITING_MANUAL_ARREST,
  },
  INCOMPLETE_CASE: {
    name: 'incomplete-case',
    actions: [Actions.GO_TO_CASE],
    singleCase: true,
    tag: Tags.INCOMPLETE_CASE,
  },
  Tarn: {
    name: 'tarn-cases',
    description: 'tarn-cases-description',
    actionsComponent: () => ({
      component: import('../../components/Dashboard/Tarn/Actions.vue'),
    }),
    component: () => ({
      component: import('../../components/Dashboard/Tarn/Tarn.vue'),
    }),
    tag: Tags.IMPORTED_TARN_CASE,
  },
  ARREST_FULFILLED: {
    name: 'arrest-fulfilled',
    description: 'arrest-fulfilled-description',
    actionsComponent: () => ({
      component: import(
        '../../components/Dashboard/ArrestFulfilled/Actions.vue'
      ),
    }),
    component: () => ({
      component: import(
        '../../components/Dashboard/ArrestFulfilled/ArrestFulfilled.vue'
      ),
    }),
    tag: Tags.ARREST_FULFILLED,
  },
  ERRORS: {
    name: 'errors',
    descriptions: 'errors-description',
    component: () => ({
      component: import('../../components/ErrorLog/ErrorLog.vue'),
    }),
    tag: null,
  },
};

export const Types = {
  BANK_ACCOUNT: 0,
  ARREST: 1,
  CANCEL_ARREST: 2,
  CHANGE_ARREST: 3,
  RECEIVED_RESPONSES: 4,
};
