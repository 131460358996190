<template>
  <div ref="representative">
    <sui-grid>
      <sui-grid-column :width="16" :large-screen="2" :widescreen="2" />

      <sui-grid-column :width="16" :large-screen="8" :widescreen="8">
        <sui-form-fields class="representation__data">
          <sui-form-field :width="7">
            <label>{{ $t('person.representatives.basis') }}</label>
            <Select
              v-model="represent_basis"
              :disabled="disabled"
              :options="representationBases"
              @input="handleInput"
            />
          </sui-form-field>

          <sui-form-field :width="7">
            <label>{{ $t('person.representatives.until') }}</label>
            <DatePicker
              v-model="represent_until"
              :disabled="disabled"
              @input="handleInput"
            />
          </sui-form-field>
        </sui-form-fields>
      </sui-grid-column>
    </sui-grid>

    <div class="person-wrapper">
      <Person
        :initial-data="data"
        :representative-disabled="disabled"
        role="representative"
        @personDataChange="onPersonDataChange"
      />
    </div>
  </div>
</template>

<script>
// @flow
import DatePicker from '../DatePicker.vue';
import Select from '../Select.vue';

export default {
  name: 'Representative',
  components: {
    DatePicker,
    Person: () => import('./Person.vue'),
    Select,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    representationBases: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      personData: null,
      represent_basis: 0,
      represent_until: '',
    };
  },
  created() {
    this.represent_basis = this.data.represent_basis || this.represent_basis;
    this.represent_until = this.data.represent_until || this.represent_until;
  },
  mounted() {
    const firstElement =
      this.$refs.representative.parentNode.parentNode.querySelector('.title');
    this.$emit('created', firstElement);
  },
  methods: {
    onPersonDataChange(role, data) {
      this.personData = data;

      this.$emit('edit', {
        ...data,
        represent_basis: this.represent_basis,
        represent_until: this.represent_until,
      });
    },
    handleInput() {
      this.$emit('edit', {
        ...(this.personData || this.data),
        represent_basis: this.represent_basis,
        represent_until: this.represent_until,
      });
    },
  },
};
</script>

<style scoped>
.person-wrapper {
  margin-left: -1rem;
  margin-right: -1rem;
}

.representation__data {
  padding-bottom: 1.5rem;
}
</style>
