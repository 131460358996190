import req from '../lib/req';
import { Id, Person } from '@/types';

export function findById(id: Id, params: []) {
  return req.get(`/person/${id}`, { params });
}

export function findForTransfer(personId: Id) {
  return req.get(`/case-transfer/person/${personId}`);
}

/**
 * @deprecated uses v1 tapais2 api. Use findByIdList instead
 */
export function getPersonListByIds(idList: Id[], includeOthers = 0) {
  const params = {
    id: idList,
    includeOthers,
  };

  return req.get('/person', {
    params: {
      where: JSON.stringify(params),
    },
  });
}

export function findByIdList(idList: Id[]): Promise<Person[]> {
  return req
    .get('/v2/people', { params: { id: idList } })
    .then((resp) => resp.data['hydra:member']);
}

export function quickSearch(term: string) {
  return req.get(`/person/quick-search/${term}`);
}

export function findForQuickSearch(id: Id) {
  return req.get(`/person/quick-search/${id}/get`);
}

export function create(data: unknown) {
  return req.post('/person', data);
}

export function update(id: Id, data: unknown) {
  return req.put(`/person/${id}`, data);
}
