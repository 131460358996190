import req from '../lib/req';
import { Statuses } from '@/static/enums/arrest';
import { Id } from '@/types/Id';
import { Arrest } from '@/types/Arrest';
import { ArrestDraft } from '@/types/ArrestDraft';
import { AxiosResponse } from 'axios';
import { ArrestTransitionData } from '@/types/ArrestTransitionData';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require('qs');
/**
 * @deprecated This should not be used. Correct endpoint is /v2/arrests
 */
export function findByIds(idList: Id[], params: Record<string, unknown>) {
  return req.get('/arrest', {
    params: {
      id: idList,
      ...params,
    },
  });
}

/**
 * @deprecated This should not be used. Correct endpoint is /v2/arrests
 */
export function create(data: unknown) {
  return req.post('/arrest', data);
}

export function createDraft(draft: ArrestDraft): Promise<Arrest> {
  return req
    .post('/v2/arrests', draft)
    .then((result: AxiosResponse<Arrest>) => result.data);
}

/**
 * Updates the given arrest with given and submitted fields. This is required
 * for v2 arrests to update any validerd fields (f.e arrest sums) before any
 * transition is emitted, to ensure the final arrest is validated during transition.
 */
export function updateArrest(arrest: Arrest): Promise<Arrest> {
  return req
    .patch(`/v2/arrests/${arrest.id}`, arrest, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    .then((result: AxiosResponse<Arrest>) => result.data);
}

/**
 * @deprecated This should not be used. Correct endpoint is /v2/arrests/${id}
 */
export function update(id: Id, data: unknown) {
  return req.put(`/arrest/${id}`, data);
}

/**
 * @deprecated This should not be used. Correct endpoint is /v2/arrests/${id}
 */
export function remove(id: Id) {
  return req.put(`/arrest/${id}`, { status: Statuses.IN_ARREST_CANCEL });
}

export function transition(
  id: Id,
  transition: string,
  data: ArrestTransitionData
): Promise<Arrest> {
  return req
    .post(`/v2/arrests/${id}/transition/${transition}`, data)
    .then((res: AxiosResponse<Arrest>) => res.data);
}

/**
 * Returns all active arrests for given case
 * @param caseId
 */
export function fetchCaseArrests(caseId: Id): Promise<Arrest[]> {
  return req
    .get(`/v2/arrests?enforcementCase=${caseId}`)
    .then((resp) => resp.data['hydra:member']);
}

export function findExpireArrests(): Promise<Arrest[]> {
  return req
    .get(
      `/v2/arrests?exists[expiresAt]=true&state=active&preArrest=true&order[expiresAt]=asc`
    )
    .then((resp) => resp.data['hydra:member']);
}

export function findArrestsWithTransitionError(): Promise<Arrest[]> {
  return req
    .get(`/v2/arrests?exists[lastTransitionError]`)
    .then((resp) => resp.data['hydra:member']);
}

export function findByParams(params: unknown) {
  return req.get(`/arrest?${qs.stringify(params)}`);
}
