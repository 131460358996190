<template>
  <div>
    <ContactGroup
      :contacts="phones"
      :disabled="disabled"
      :label="$t('person.contacts.phone')"
      :type="ContactTypes.PHONE"
      icon="phone"
      @update="updatePhones"
    />

    <ContactGroup
      :contacts="emails"
      :disabled="disabled"
      :label="$t('person.contacts.email')"
      :type="ContactTypes.EMAIL"
      icon="envelope outline"
      @update="updateEmails"
    />
  </div>
</template>

<script>
// @flow
import ContactGroup from './ContactGroup.vue';
import { ContactTypes } from '../../static/enums/person';

export default {
  name: 'PersonContacts',
  components: {
    ContactGroup,
  },
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ContactTypes,
    };
  },
  computed: {
    emails: {
      get() {
        return this.contacts.filter(
          (contact) => contact.type === ContactTypes.EMAIL
        );
      },
    },
    phones: {
      get() {
        return this.contacts.filter(
          (contact) => contact.type === ContactTypes.PHONE
        );
      },
    },
  },
  methods: {
    updateEmails(emails) {
      this.$emit('update', [...emails, ...this.phones]);
    },
    updatePhones(phones) {
      this.$emit('update', [...phones, ...this.emails]);
    },
  },
};
</script>
