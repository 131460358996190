<template>
  <div ref="reopenPopoverWrapper" class="popover__reopen--wrapper">
    <sui-button
      v-if="useIcon"
      class="row-action"
      icon="trash alternate outline"
      type="button"
      @click.prevent="togglePopover"
    />

    <a v-else-if="useLink" href="#" @click.prevent="togglePopover">
      {{ text }}
    </a>

    <sui-button v-else negative size="tiny" @click.prevent="togglePopover">
      {{ text }}
    </sui-button>

    <div v-if="popoverOpen" class="popover__reopen">
      <p>{{ $t('confirm') }}</p>

      <sui-button size="mini" @click.prevent="closePopover">
        {{ $t('cancelConfirm') }}
      </sui-button>

      <sui-button
        floated="right"
        negative
        size="mini"
        @click.prevent="onConfirm"
      >
        {{ $t('reopenConfirm') }}
      </sui-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReOpenPopover',
  props: {
    text: {
      type: String,
      default: '',
    },
    useLink: {
      type: Boolean,
      default: false,
    },
    useIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoverOpen: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClick, false);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClick, false);
  },
  methods: {
    handleClick(event) {
      if (!this.$refs.reopenPopoverWrapper.contains(event.target)) {
        this.closePopover();
      }
    },
    closePopover() {
      this.popoverOpen = false;
    },
    togglePopover() {
      this.popoverOpen = !this.popoverOpen;
    },
    onConfirm() {
      this.$emit('confirm');
      this.closePopover();
    },
  },
};
</script>

<style>
.popover__reopen {
  background-color: white;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 1rem;
  position: absolute;
  right: -10px;
  text-align: left;
  top: calc(100% + 10px);
  width: 220px;
  z-index: 200;
}

.popover__reopen::after {
  background-color: white;
  border: solid rgba(0, 0, 0, 0.05);
  border-width: 1px 0 0 1px;
  content: '';
  display: block;
  height: 10px;
  position: absolute;
  right: 15px;
  top: -5px;
  transform: rotate(45deg);
  width: 10px;
  z-index: 100;
}

.popover__reopen--wrapper {
  display: inline-block;
  position: relative;
}

.popover__reopen--wrapper .ui.button {
  z-index: 5;
}
</style>
