<template>
  <Modal :open="isOpen" size="mini" @close="toggleModal">
    <template slot="modal-header">
      {{ $t('case.change_no') }}
    </template>

    <template slot="modal-content">
      <sui-message v-if="warning" negative dismissable @dismiss="clearWarning">
        {{ warning }}
      </sui-message>

      <sui-form>
        <sui-form-field>
          <label>Uus number</label>
          <masked-input
            v-model="caseNo"
            mask="111/1111/1111"
            placeholder="Uus number"
          />
        </sui-form-field>
      </sui-form>
    </template>

    <template slot="modal-actions">
      <sui-button @click.native="toggleModal"> Katkesta </sui-button>
      <sui-button :loading="loading" primary @click.native="changeNo">
        {{ $t('case.change_no') }}
      </sui-button>
    </template>
  </Modal>
</template>

<script>
import api from '../../api';
import MaskedInput from 'vue-masked-input';
import Modal from '../../components/Modal.vue';

export default {
  name: 'ChangeNoModal',
  components: {
    Modal,
    MaskedInput,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: String,
      default: null,
    },
    caseId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      caseNo: '',
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
  },
  methods: {
    clearWarning() {
      this.warning = null;
    },
    changeNo() {
      this.loading = true;
      api.cases
        .changeNo(this.caseId, {
          number: this.caseNo,
        })
        .then(() => {
          this.$emit('onChangeSuccess');
        })
        .catch(() => {
          this.$emit('onChangeFail');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleModal() {
      this.$emit('onToggle');
    },
  },
};
</script>
