import { ProblemType } from '@/static/enums/problemType';

/**
 * Map of labels to translate a problem type.
 */
export const ProblemTypeToLabel: Record<ProblemType, string> = {
  [ProblemType.VALIDATION_ERRORS]: 'problem.type.validationErrors',
  [ProblemType.NOT_OPENED_IN_TARN]: 'problem.type.notOpenedInTarn',
  [ProblemType.NOT_IN_SYNC_WITH_TR_OPENED]: 'problem.type.notOpenedInTr',
  [ProblemType.NOT_IN_SYNC_WITH_TR_CLOSED]: 'problem.type.notClosedInTr',
  [ProblemType.NOT_IN_SYNC_WITH_TR_OTHER]: 'problem.type.notInSyncWithTr',
};
