import { CaseState } from '@/static/enums/caseState';
import { caseStatesEditable } from '@/static/enums/caseStateEditable';

/**
 * Whether the case can be edited or not.
 * The case should not be editable when it has an ongoing transfer
 * or when it is in a certain state that does not allow it
 * (f.e awaiting for tarn accept response).
 *
 * Note that this uses retrieved case retrieved from v1 api which does not
 * have a type, you can see EnforcementCase.yml for its serialized fields.
 * @todo - use typed case object from v2 api.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCaseEditable(caseObject: any): boolean {
  return (
    !caseObject.transfer_id && caseStatesEditable[caseObject.state as CaseState]
  );
}
