import { ArrestState, ArrestTransition } from '@/static/enums/arrest';
import api from '@/api';
import { ArrestTableRow } from '@/types/ArrestTableRow';
import { Arrest } from '@/types/Arrest';
import { ArrestDraft } from '@/types/ArrestDraft';
import { ArrestTransitionData } from '@/types/ArrestTransitionData';

/**
 * Used to arrest in EPIS system.
 * At first, an arrest object is created with state=draft.
 * If the arrest already exists (arrest is active or in draft). Then this part is skipped.
 * After that, a transition POST request is sent with the required properties
 */
export function editEpisArrests(
  list: ArrestTableRow[],
  template: string,
  newArrestReferenceNr: string
) {
  const promises: Promise<Arrest>[] = [];
  list.forEach((rowItem) => {
    if (rowItem.id === undefined) {
      promises.push(
        createDraftArrest(rowItem, newArrestReferenceNr).then((arrest) => {
          return transitionArrest(
            {
              ...arrest,
              arrestSum: rowItem.arrest_sum,
              sumNotArrested: rowItem.sum_not_arrested,
              preArrest: rowItem.preArrest ?? false,
              amountOfMinWages: rowItem.amount_of_min_wages,
              stopped: rowItem.stopped,
            },
            ArrestTransition.DRAFT_TO_CREATING,
            template
          );
        })
      );
      return;
    }

    if (null === rowItem.referenceNo) {
      // Existing arrests should always have an arrest reference number.
      throw new Error(`Arestil ${rowItem.id} puudub aresti viitenumber.`);
    }

    const arrest: Arrest = {
      id: rowItem.id,
      arrestSum: rowItem.arrest_sum,
      enforcementCase: rowItem.enforcementCase,
      bank: rowItem.bank,
      state: rowItem.state,
      amountOfMinWages: rowItem.amount_of_min_wages,
      sumNotArrested: rowItem.sum_not_arrested,
      preArrest: rowItem.preArrest,
      stopped: rowItem.stopped,
      expiresAt: rowItem.expiresAt,
      referenceNo: rowItem.referenceNo,
    };

    promises.push(
      api.arrest.updateArrest(arrest).then((arrest) => {
        return transitionArrest(arrest, getTargetTransition(arrest), template);
      })
    );
  });

  return Promise.all(promises);
}

/**
 * Since arrest workflow allows moving in a very limited directions,
 * then we can safely assume what the next transition could be.
 */
function getTargetTransition(arrest: Arrest): string {
  if (arrest.state === ArrestState.DRAFT) {
    return ArrestTransition.DRAFT_TO_CREATING;
  }
  if (arrest.state === ArrestState.ACTIVE) {
    return ArrestTransition.ACTIVE_TO_UPDATING;
  }
  throw Error(`Arest '${arrest.id}' olekut ei suudetud tuvastada`);
}

/**
 * Used to create the draft version of a new arrest. Draft state has no backend logic behind it.
 * This is created purely for getting the id of the new arrest which we can then transition.
 */
export function createDraftArrest(item: ArrestTableRow, newArrestReferenceNr: string): Promise<Arrest> {
  const arrestDraft: ArrestDraft = {
    enforcementCase: item.enforcementCase,
    bank: item.bank,
    state: item.state,
    arrestSum: item.arrest_sum,
    referenceNo: newArrestReferenceNr,
  };
  return api.arrest.createDraft(arrestDraft).then((arrest: Arrest) => {
    arrest.sumNotArrested = item.sum_not_arrested;
    arrest.amountOfMinWages = item.amount_of_min_wages;
    arrest.preArrest = item.preArrest;

    return arrest;
  });
}

/**
 * Used to transition the arrest from one state to another.
 */
export function transitionArrest(
  item: Arrest,
  transition: string,
  template: string
): Promise<Arrest> {
  const { arrestSum, amountOfMinWages, sumNotArrested, preArrest, stopped } = item;
  let transitionData: ArrestTransitionData = {
    template: template,
  };
  if (
    [
      ArrestTransition.DRAFT_TO_CREATING,
      ArrestTransition.ACTIVE_TO_UPDATING,
    ].includes(transition)
  ) {
    transitionData = {
      ...transitionData,
      arrestSum,
      preArrest: preArrest || false,
      sumNotArrested: sumNotArrested || { cents: 0, currency: 'EUR' },
      amountOfMinWages: Number(amountOfMinWages) || 0.0,
      stopped,
      referenceNo: item.referenceNo,
    };
  }

  return api.arrest.transition(item.id, transition, transitionData);
}
