import AddressSearch from './AddressSearch.vue';

export default {
  name: 'PersonAddresses',
  components: {
    AddressSearch,
  },
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addressComponents: {},
      addressList: [{}],
    };
  },
  created() {
    this.$watch(
      'addresses',
      (addresses) => {
        if (addresses.length > 0) {
          this.addressList = [...addresses];
        }
      },
      { immediate: true }
    );
  },
  methods: {
    addAddress() {
      this.addressList.push({});
    },
    editAddress(address, index) {
      this.addressList[index] = address;
      this.$emit('update', this.addressList);
    },
    removeAddress(index) {
      this.addressList.splice(index, 1);

      this.$emit('update', this.addressList);
    },
  },
};
