import VueI18n from 'vue-i18n';

import { OptionType } from '@/components/RangeFilterPicker/OptionType';

/**
 * Type for option to be used for selecting the desired field or
 * a specific sum value to compare against.
 */
export type Option = {
  label: string;
  value: string;
  type: OptionType;
};

/**
 * Returns default comparison target options for RangeFilterPicker.
 */
export function getOptions(i18n: VueI18n): Option[] {
  return [
    {
      label: i18n.t('rangeFilterPicker.option.specificSum').toString(),
      value: '', // Value does nothing for this option.
      type: OptionType.SPECIFIC_SUM,
    },
    {
      label: i18n.t('rangeFilterPicker.option.arrestSum').toString(),
      // Filtering against a list of arrests, means that if at least one arrest
      // sum falls under the given filter, then that case is returned.
      value: 'arrests.arrestSum.cents',
      type: OptionType.FIELD,
    },
  ];
}
