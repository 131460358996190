import { SettingType } from './settingType';

export const SubPagesKoda = {
  USER: {
    name: 'user',
  },
  BUREAU: {
    name: 'bureau',
  },
  CLASSIFIERS: {
    name: 'classifiers',
  },
  PARAMETERS: {
    name: 'parameters',
  },
  DOCUMENT: {
    name: 'document',
  },
  QUEUE: {
    name: 'queue',
  },
  QUERY: {
    name: 'query.name',
  },
};
export const SubPagesRegular = {
  USER: {
    name: 'user',
  },
  BUREAU: {
    name: 'bureau',
  },
  PARAMETERS: {
    name: 'parameters',
  },
  DOCUMENT: {
    name: 'document',
  },
  QUEUE: {
    name: 'queue',
  },
};

export const TaaviDefaultFields = [
  SettingType.CLAIM_CLAIM_TYPE,
  SettingType.CASE_SOLUTION_TYPE,
];
export const FileTypes = {
  ARREST: 0,
  ARREST_REMOVE: 7,
  PARTIALLY_CLOSE: 35,
};
