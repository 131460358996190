import { BulkActionType } from '@/static/enums/bulkActionType';
import { Form } from '@/components/BulkActionArgsModal/Form';

/**
 * Creates an "empty" default form for given bulk action type.
 */
export function createDefaultBulkActionForm(type: BulkActionType): Form {
  if (type === BulkActionType.SET_START_FEE) {
    return {
      type,
      args: {
        startFee: {
          cents: 0,
          currency: 'EUR',
          tarnId: null,
        },
      },
    };
  }

  if (type === BulkActionType.CLOSE_CASE) {
    return {
      type,
      args: {
        closeBasisId: '',
        closeExplanation: '',
        dateClosed: '',
        isPartial: false,
        templateOfPartialClose: null,
        dateDeath: null,
        dateExpired: null,
      },
    };
  }

  if (type === BulkActionType.COMPUTE_NEW_ISSUER_FEE) {
    return {
      type: BulkActionType.COMPUTE_NEW_ISSUER_FEE,
      args: {},
    };
  }

  if (type === BulkActionType.REMOVE_ARREST_PREPARE) {
    return {
      type: BulkActionType.REMOVE_ARREST_PREPARE,
      args: {
        bankIds: [],
      },
    };
  }

  if (type === BulkActionType.SET_ARREST_PREPARE) {
    return {
      type: BulkActionType.SET_ARREST_PREPARE,
      args: {
        arrestsArgs: [],
      },
    };
  }

  if (type === BulkActionType.VALIDATE_CASE) {
    return {
      type: BulkActionType.VALIDATE_CASE,
      args: {},
    };
  }

  if (type === BulkActionType.SYNC_CASE_TO_TARN) {
    return {
      type: BulkActionType.SYNC_CASE_TO_TARN,
      args: {},
    };
  }

  throw new Error(
    `No default form found for bulk action type "${type}". Check if type is correct or add default form for it.`
  );
}