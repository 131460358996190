/**
 * Enum values for different case arrest sequence values.
 * Same as in ArrestSequence.php! Keep in sync.
 */
export enum ArrestSequence {
  'NONE' = 0,
  'ONE' = 1,
  'TWO' = 2,
  'THREE' = 3,
  'FOUR' = 4,
  'FIVE' = 5,
  'SIX' = 6,
  'MORE_THAN_SIX' = 7,
}
