import VueI18n from 'vue-i18n';

import { ComparisonType } from '@/components/RangeFilterPicker/ComparisonType';

/**
 * Type for option to be used for selecting the desired comparison operator.
 */
export type ComparisonOption = {
  label: string;
  type: ComparisonType;
};

/**
 * Returns comparison operator options for RangeFilterPicker.
 * You can see ComparisonType.ts for more details on how each type should act.
 */
export function getComparisonOptions(i18n: VueI18n): ComparisonOption[] {
  return [
    {
      label: i18n.t('rangeFilterPicker.comparison.sumEqualTo').toString(),
      type: ComparisonType.EQUAL_TO,
    },
    {
      label: i18n.t('rangeFilterPicker.comparison.sumGreaterThan').toString(),
      type: ComparisonType.GREATER_THAN,
    },
    {
      label: i18n.t('rangeFilterPicker.comparison.sumLessThan').toString(),
      type: ComparisonType.LESS_THAN,
    },
  ];
}
