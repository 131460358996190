/**
 * Debounces the given function.
 *
 * For info about what debouncing is, please refer to
 * https://davidwalsh.name/javascript-debounce-function.
 *
 * This specific flavor of debounce is inspired by
 * https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940#gistcomment-3062135.
 *
 * The difference is that the target function is called immediately, if the
 * function has not been called before as opposed to waiting for "waitFor" ms
 * until the function gets called the first time.
 *
 * ```typescript
 * const debounced = debounce(this.someCostlyFunction, 500);
 *
 * debounced();
 * debounced();
 * debounced();
 *
 * // gets called only once in 500ms.
 * ```
 */
export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number,
  immediate?: boolean
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        // Seems that there has been at least one call to the debounced function
        // so postpone the calling of the original function another `waitFor`
        // milliseconds.
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          timeout = null;
          resolve(func(...args));
        }, waitFor);

        return;
      }

      if (undefined === immediate || immediate) {
        // It's the first time the debounced function has been callend and we're
        // supposed to immediately fire the function. So let's do just that. In
        // this case, we should still add a timeout so that the next call won't
        // be again fired immediately.
        setTimeout(() => resolve(func(...args)), 0);
        timeout = setTimeout(() => {
          timeout = null;
        }, waitFor);

        return;
      }

      // It's the first time the debounced function has been called, but we
      // shouldn't immediately call the function. So wait for `waitFor` ms to
      // resolve the promise.
      timeout = setTimeout(() => {
        timeout = null;
        resolve(func(...args));
      }, waitFor);
    });
};

