/**
 * Valid comparison operator values for Api Platform's DateFilter filter.
 * More at: https://api-platform.com/docs/v2.5/core/filters/#date-filter
 */
export enum DateFilterComparison {
  AFTER = 'after',
  BEFORE = 'before',
  STRICTLY_AFTER = 'strictly_after',
  STRICTLY_BEFORE = 'strictly_before',
}
