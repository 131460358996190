import req from '../lib/req';

export function fetchAll() {
  return req.get('/case-transfer').then((response) => response.data);
}
export function fetchCaseInfo(id) {
  return req.get(`/case-transfer/${id}/cases`);
}
export function update(data) {
  return req.put(`/case-transfer/${data.id}`, data);
}
export function create(data) {
  return req.post('/case-transfer', data);
}

export function addCasesToTransfer(id, case_ids) {
  return req.put(`/case-transfer/${id}/add_cases`, case_ids);
}

export function updateStatus(id, status) {
  return req.put(`/case-transfer/${id}/update_status`, status);
}

export function accept(transfer) {
  return req.put(`/case-transfer/${transfer.id}/accept`);
}

export function decline(transfer, declineReason) {
  return req.put(`/case-transfer/${transfer.id}/decline`, declineReason);
}
