<template>
  <Modal v-if="transfer" :open="isOpen" size="small" @close="toggleModal">
    <template slot="modal-header"> Üleandmisest keeldumine </template>
    <template slot="modal-content">
      <label>Põhjus</label>
      <Input v-model="transfer.decline_reason" />
    </template>
    <template slot="modal-actions">
      <sui-button @click.native="toggleModal"> Katkesta </sui-button>
      <sui-button primary @click.native="rejectCase()">
        {{ $t('decline') }}
      </sui-button>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue';
import Input from '../Input.vue';
import { Statuses } from '../../static/enums/caseTransfer';
import { getCurrentDateTime } from '../../mixins/dateTime';

export default {
  name: 'TransferCaseRejectModal',
  components: { Modal, Input },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    transfer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      reason: '',
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
  },
  methods: {
    toggleModal() {
      this.$emit('onToggle');
    },
    rejectCase() {
      this.transfer.status = Statuses.DECLINED;
      this.transfer.date_response = getCurrentDateTime();
      this.$emit('onReject', this.transfer);
    },
  },
};
</script>
