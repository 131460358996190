var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"header":_vm.$t(_vm.BulkActionTypeToLabel[_vm.type]),"visible":_vm.opened,"containerStyle":{ minWidth: '30rem' }},on:{"update:visible":function($event){_vm.opened=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"label":_vm.cancelButtonText,"icon":"pi pi-times","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('close')}}}),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
        value: _vm.$t('bulkActionArgsModal.checkRequiredFieldsFilled'),
        disabled: !_vm.confirmDisabled,
      }),expression:"{\n        value: $t('bulkActionArgsModal.checkRequiredFieldsFilled'),\n        disabled: !confirmDisabled,\n      }",modifiers:{"bottom":true}}],attrs:{"label":_vm.confirmButtonText,"icon":"pi pi-check","disabled":_vm.confirmDisabled,"loading":_vm.isLoading},on:{"click":_vm.confirm}})]},proxy:true}])},[(_vm.displayProblemFoundNotice)?_c('CreateBulkActionContext',{staticClass:"bulk-action-args-modal__form-content bulk-action-args-modal__form-content--notice",attrs:{"text":''}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'createBulkActionModal.creationContext.caseWithProblemsFound' ).toString())+" ")])]):_vm._e(),(_vm.form.type === _vm.BulkActionType.SET_START_FEE)?_c('SetStartFeeArgsForm',{attrs:{"totalCasesCount":_vm.totalCasesCount},model:{value:(_vm.form.args),callback:function ($$v) {_vm.$set(_vm.form, "args", $$v)},expression:"form.args"}}):_vm._e(),(_vm.form.type === _vm.BulkActionType.CLOSE_CASE)?_c('CloseCaseArgsForm',{staticClass:"bulk-action-args-modal__form-content",attrs:{"totalCasesCount":_vm.totalCasesCount},model:{value:(_vm.form.args),callback:function ($$v) {_vm.$set(_vm.form, "args", $$v)},expression:"form.args"}}):_vm._e(),(_vm.form.type === _vm.BulkActionType.REMOVE_ARREST_PREPARE)?_c('RemoveArrestPrepareArgsForm',{staticClass:"bulk-action-args-modal__form-content",attrs:{"totalCasesCount":_vm.totalCasesCount},model:{value:(_vm.form.args),callback:function ($$v) {_vm.$set(_vm.form, "args", $$v)},expression:"form.args"}}):_vm._e(),(_vm.form.type === _vm.BulkActionType.SET_ARREST_PREPARE)?_c('SetArrestPrepareArgsForm',{attrs:{"totalCasesCount":_vm.totalCasesCount},model:{value:(_vm.form.args),callback:function ($$v) {_vm.$set(_vm.form, "args", $$v)},expression:"form.args"}}):_vm._e(),(_vm.form.type === _vm.BulkActionType.COMPUTE_NEW_ISSUER_FEE)?_c('CreateBulkActionContext',{staticClass:"bulk-action-args-modal__form-content",attrs:{"text":_vm.$t('createBulkActionModal.creationContext.computeNewIssuerFee', {
        count: _vm.totalCasesCount,
      }).toString()}}):_vm._e(),(_vm.form.type === _vm.BulkActionType.VALIDATE_CASE)?_c('CreateBulkActionContext',{staticClass:"bulk-action-args-modal__form-content",attrs:{"text":_vm.$t('createBulkActionModal.creationContext.validateCase', {
        count: _vm.totalCasesCount,
      }).toString()}}):_vm._e(),(_vm.form.type === _vm.BulkActionType.SYNC_CASE_TO_TARN)?_c('CreateBulkActionContext',{staticClass:"bulk-action-args-modal__form-content",attrs:{"text":_vm.$t('createBulkActionModal.creationContext.syncCaseToTarn', {
        count: _vm.totalCasesCount,
      }).toString()}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }