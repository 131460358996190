<template>
  <div>
    <Action
      v-for="action in actions"
      :key="action.name"
      :action="action"
      :no-cases-selected="noCasesSelected"
      :loading="loading"
      @actionClick="handleClick"
    />

    <div v-if="actions.length === 0" class="no-filter">
      {{ $t(`dashboard.chooseFilter`) }}
    </div>
  </div>
</template>
<script>
import Action from './Action.vue';

export default {
  name: 'CaseActions',
  components: {
    Action,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    noCasesSelected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  methods: {
    handleClick(name, optionRequired = false, option) {
      this.$emit('actionClick', name, optionRequired, option);
    },
  },
};
</script>
<style scoped>
.action__container {
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
}

.action__info {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
}

.action__options {
  margin-bottom: 0.5rem;
}

.ui.button,
.ui.selection.dropdown {
  margin-right: 0;
  width: 100%;
}

.ui.icon.button {
  background-color: transparent;
  margin: 0;
  padding: 0 14px 0 7px;
}

.no-filter {
  opacity: 0.8;
  padding: 1.5rem 0;
}

[data-tooltip]:after {
  white-space: normal;
  width: 300px;
}
</style>
