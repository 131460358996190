import { BulkActionStatus } from '@/static/enums/bulkActionStatus';

/**
 * Map of labels to translate a bulk action's status into a human readable form.
 */
export const BulkActionStatusToLabel: Record<BulkActionStatus, string> = {
  [BulkActionStatus.CANCELLED]: 'bulkActionStatus.cancelled',
  [BulkActionStatus.FAILED]: 'bulkActionStatus.failed',
  [BulkActionStatus.OK]: 'bulkActionStatus.ok',
  [BulkActionStatus.WIP]: 'bulkActionStatus.wip',
};
