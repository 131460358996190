import { mapGetters } from 'vuex';

export default {
  name: 'UiPage',
  props: {
    flexRow: {
      type: Boolean,
      default: false,
    },
    flexColumn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('app', {
      name: 'pageName',
    }),
    flexMode() {
      return this.flexRow || this.flexColumn;
    },
    bodyStyle() {
      const style = {};

      if (this.flexMode) {
        style.display = 'flex';
        style['flex-direction'] = this.flexRow ? 'row' : 'column';
      }

      return style;
    },
  },
};
