import { render, staticRenderFns } from "./DeliveryImportModal.vue?vue&type=template&id=6cd50b57&scoped=true&"
import script from "./DeliveryImportModal.vue?vue&type=script&lang=js&"
export * from "./DeliveryImportModal.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryImportModal.vue?vue&type=style&index=0&id=6cd50b57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd50b57",
  null
  
)

export default component.exports