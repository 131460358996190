<template>
  <ui-page>
    <div class="dashboard">
      <div class="column dashboard--filters">
        <ui-column-header :title="$t('dashboard.filters')" />

        <FilterList ref="filters" :current-filter="selectedFilter.name" />
      </div>
      <component
        :is="selectedFilter.component"
        v-if="selectedFilter.actionsInComponent"
        @selectCases="onSelectCases"
      />
      <div
        v-if="!selectedFilter.actionsInComponent"
        class="column dashboard--content"
      >
        <ui-column-header
          :label="selectedCaseIds.length"
          :title="$t('dashboard.cases')"
        />
        <component
          :is="selectedFilter.component"
          :selected-case-ids="selectedCaseIds"
          @selectCases="onSelectCases"
        />
        <CaseTable
          v-if="!selectedFilter.component"
          ref="caseTable"
          :selected-case-ids="selectedCaseIds"
          :single-case="selectedFilter.singleCase"
          :tag="selectedFilter.tag"
          @selectCases="onSelectCases"
          @toggleCase="onCaseToggle"
          @countUpdate="handleCountUpdate"
        />
      </div>

      <div
        v-if="!selectedFilter.actionsInComponent"
        class="column dashboard--action"
      >
        <ui-column-header :title="$t('dashboard.actions')" />

        <div class="actions-column__container">
          <p v-if="selectedFilter.description">
            {{ $t(`dashboard.filter.${selectedFilter.description}`) }}
          </p>
          <component
            :is="selectedFilter.actionsComponent"
            :selected-case-ids="selectedCaseIds"
            @reload="reloadData"
            @selectCases="onSelectCases"
          />
          <CaseActions
            v-if="!selectedFilter.actionsComponent"
            :actions="selectedFilter.actions"
            :description="selectedFilter.description"
            :no-cases-selected="selectedCaseIds.length === 0"
            :loading="isLoading"
            @actionClick="handleAction"
            @selectCases="onSelectCases"
          />
        </div>
      </div>
    </div>
  </ui-page>
</template>
<script>
import api from '../../api';
import pageNameSync from '../../mixins/pageNameSync';
import CaseActions from './CaseActions.vue';
import CaseTable from './CaseTable.vue';
import FilterList from './FilterList.vue';
import { Actions, Filters } from '@/static/enums/case';
import {
  showSuccessNotification,
  showErrorNotification,
} from '@/mixins/notification';

export default {
  components: {
    CaseActions,
    CaseTable,
    FilterList,
  },
  mixins: [pageNameSync],
  beforeRouteUpdate(to, from, next) {
    this.selectedFilter = this.getFilter(to);
    next();
  },
  data() {
    return {
      selectedCaseIds: [],
      selectedFilter: this.getFilter(this.$route),
      loading: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['query/isLoading'] || this.loading;
    },
  },
  methods: {
    getFilter(route) {
      const { filter } = route.params;
      const currentFilter = Object.values(Filters).find(
        (filterItem) => filterItem.name === filter
      );
      return currentFilter || Filters.ALL;
    },
    onCaseToggle(caseId) {
      if (this.selectedFilter.singleCase) {
        this.selectedCaseIds = [caseId];
        return;
      }

      const index = this.selectedCaseIds.findIndex((id) => id === caseId);

      if (index > -1) {
        this.selectedCaseIds.splice(index, 1);
      } else {
        this.selectedCaseIds.push(caseId);
      }
    },
    onSelectCases(ids) {
      this.selectedCaseIds = ids;
    },
    handleAction(name, optionRequired = false, option) {
      if (optionRequired && option === -1) {
        showErrorNotification({ text: 'notification.valueNotSelected' });
        return;
      }
      switch (name) {
        case Actions.REMOVE_CASES.name:
          this.removeCasesFromList();
          break;
        case Actions.GO_TO_CASE.name:
          this.selectedCaseIds.forEach((id) => {
            const routeData = this.$router.resolve({
              name: 'Case',
              params: { id },
            });
            window.open(routeData.href, '_blank');
          });
          break;
        case Actions.REFUSE_TARN_CASE.name:
          this.refuseTarnCase();
          break;
        case Actions.REFRESH_MANUAL_ARREST.name:
          this.refreshManualArrest();
          break;
        case Actions.REFRESH_AWAITING_ARREST.name:
          this.refreshAwaitingArrest();
          break;
        case Actions.REFRESH_ARREST_REMOVE.name:
          this.refreshArrestRemove();
          break;
        case Actions.REMOVE_ARRESTS.name:
          this.removeArrests();
          break;
        default:
          break;
      }
    },
    removeCasesFromList() {
      const promises = Promise.all(
        this.selectedCaseIds.map((id) =>
          api.cases
            .makeCaseTagInactive(id, this.selectedFilter.tag)
            .then(() => {
              showSuccessNotification({ text: 'Eemaldatud' });
            })
            .catch(() => {
              showErrorNotification({ text: 'Ebaõnnestus' });
            })
        )
      );
      promises.then(() => {
        this.reloadData();
      });
    },
    refuseTarnCase() {
      this.selectedCaseIds.forEach((id) => {
        api.cases.refuseTarnCase(id);
      });
      this.reloadData();
    },
    reloadData() {
      this.loading = true;
      this.$refs.caseTable.reset();
      this.handleCountUpdate();
      this.selectedCaseIds = [];
      this.loading = false;
    },
    handleCountUpdate() {
      this.$refs.filters.updateCounts();
    },
    refreshManualArrest() {
      this.loading = true;
      api.cases.refreshManualArrests().finally(() => {
        this.loading = false;
        this.reloadData();
      });
    },
    refreshAwaitingArrest() {
      this.loading = true;
      api.cases
        .refreshAwaitingArrest()
        .then(() => {
          showSuccessNotification({
            text: 'Uuendamine on alustatud. Palun kannatust.',
          });
        })
        .finally(() => {
          this.loading = false;
          this.reloadData();
        });
    },
    refreshArrestRemove() {
      this.loading = true;
      api.cases
        .refreshArrestRemove()
        .then(() => {
          showSuccessNotification({
            text: 'Uuendamine on alustatud.Palun kannatust.',
          });
        })
        .finally(() => {
          this.loading = false;
          this.reloadData();
        });
    },
    removeArrests() {
      const promises = Promise.all(
        this.selectedCaseIds.map((id) =>
          api.cases
            .removeArrests(id)
            .then(() => {
              showSuccessNotification({ text: 'Eemaldatud' });
            })
            .catch(() => {
              showErrorNotification({ text: 'Ebaõnnestus' });
            })
        )
      );
      promises.then(() => {
        this.reloadData();
      });
    },
  },
};
</script>
<style scoped>
.dashboard {
  display: flex;
}
.column {
  padding: 1rem;
}
.dashboard--filters {
  width: 250px;
}
.dashboard--content {
  flex: 1;
}
.dashboard--action {
  width: 300px;
}
.page {
  padding: 1rem;
}
.disabled {
  opacity: 0.5;
}
.actions-column__container {
  margin-right: 40px;
}
</style>
