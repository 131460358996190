import req from '../lib/req';

/**
 * @deprecated - Use api/modules/bureau.ts instead.
 */
export function findByIds(idList) {
  return req.get('/bureau', {
    params: {
      where: {
        id: idList,
      },
    },
  });
}

/**
 * @deprecated - Use api/modules/bureau.ts instead.
 */
export function findAll() {
  return req.get('/bureau');
}

/**
 * @deprecated - Use api/modules/bureau.ts instead.
 */
export function find(id) {
  return req.get(`/bureau/${id}`);
}

/**
 * @deprecated - Use api/modules/bureau.ts instead.
 */
export function update(id, data) {
  return req.put(`/bureau/${id}`, data);
}
