import req from '../lib/req';

export function findByCaseId(id, params = {}) {
  return req.get(`/case-solution?where={"enforcementCase":"${id}"}`, {
    params,
  });
}

export function findForTransfer(id) {
  return req.get(`/case-transfer/case-solution/${id}`);
}

export function create(data) {
  return req.post('/case-solution', data);
}

export function update(id, data) {
  return req.put(`/case-solution/${id}`, data);
}
