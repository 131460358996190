import { ArrestSequence } from '@/static/enums/arrestSequence';

/**
 * Map of labels to translate a case's Status into a human readable form.
 */
export const ArrestSequenceToLabel: Record<ArrestSequence, string> = {
  [ArrestSequence.NONE]: 'arrestSequence.none',
  [ArrestSequence.ONE]: 'arrestSequence.one',
  [ArrestSequence.TWO]: 'arrestSequence.two',
  [ArrestSequence.THREE]: 'arrestSequence.three',
  [ArrestSequence.FOUR]: 'arrestSequence.four',
  [ArrestSequence.FIVE]: 'arrestSequence.five',
  [ArrestSequence.SIX]: 'arrestSequence.six',
  [ArrestSequence.MORE_THAN_SIX]: 'arrestSequence.moreThanSix',
};
