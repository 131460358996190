import { CaseState } from '@/static/enums/caseState';

/**
 * Map of labels to translate a CaseState into a human readable form.
 */
export const CaseStateToLabel: Record<CaseState, string> = {
  [CaseState.DRAFT]: 'case.state.draft',
  [CaseState.OPEN]: 'case.state.open',
  [CaseState.PROJECT]: 'case.state.project',
  [CaseState.TARN_ACCEPT]: 'case.state.tarn_accept',
  [CaseState.UNMIGRATED]: 'case.state.unmigrated',
};