<template>
  <LogTable :case-id="caseId" />
</template>

<script>
import LogTable from '../../../tables/LogTable.vue';

export default {
  name: 'Logs',
  components: {
    LogTable,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
  },
};
</script>
