var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{class:{
    'dynamic-case-table': true,
    'dynamic-case-table--paginator-disabled': _vm.isCaseCountLoading,
    'dynamic-case-table--selection-active': !!_vm.currentCaseSelectionId,
    'dynamic-case-table--exporting': _vm.tableState === _vm.TableState.EXPORTING,
    'dynamic-case-table--importing': _vm.tableState === _vm.TableState.IMPORTING,
  },attrs:{"value":_vm.cases,"current-page-report-template":_vm.$t('dynamicCaseTable.currentPageReport').toString(),"lazy":true,"loading":_vm.isLoading,"multi-sort-meta":_vm.multiSortMeta,"paginator-template":_vm.paginatorTemplate,"paginator":true,"rows":_vm.itemsPerPage,"row-class":_vm.getTableRowModifierClass,"total-records":_vm.totalCasesCount,"sort-field":_vm.ColumnField.NUMBER,"sort-order":-1,"sort-mode":"single","removable-sort":""},on:{"page":_vm.updatePage,"sort":_vm.updateSort},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dynamicCaseTable.noCasesFound'))+" ")]},proxy:true},{key:"paginatorstart",fn:function(){return [_c('p',{staticClass:"dynamic-case-table__paginator-loader"},[_vm._v(" "+_vm._s(_vm.$t('dynamicCaseTable.countingCases').toString())+" "),_c('i',{staticClass:"pi pi-spin pi-spinner"})])]},proxy:true},(_vm.tableState === _vm.TableState.EXPORTING)?{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dynamicCaseTable.casesCurrentlyExporting', { percentageDone: _vm.progressPercentage, }))+" ")]},proxy:true}:(_vm.tableState === _vm.TableState.IMPORTING)?{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dynamicCaseTable.casesCurrentlyImporting', { percentageDone: _vm.progressPercentage, }))+" ")]},proxy:true}:(_vm.tableState === _vm.TableState.VIEWING && !!_vm.currentCaseSelectionId)?{key:"header",fn:function(){return [_c('div',{staticClass:"dynamic-case-table__header--selection-active"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
          value: _vm.$t('dynamicCaseTable.clearCurrentCaseSelection'),
        }),expression:"{\n          value: $t('dynamicCaseTable.clearCurrentCaseSelection'),\n        }",modifiers:{"bottom":true}}],staticClass:"dynamic-case-table__clear-selection"},[_c('i',{staticClass:"pi pi-times",on:{"click":_vm.clearCurrentCaseSelection}})]),_c('i',{staticClass:"dynamic-case-table__info pi pi-info-circle"}),_vm._v(" "+_vm._s(_vm.$t('dynamicCaseTable.casesImported'))+" ")])]},proxy:true}:null],null,true)},_vm._l((_vm.columns),function(col,index){return _c('Column',{key:((col.field) + "-" + index),attrs:{"field":col.field,"sortable":col.sortable},scopedSlots:_vm._u([{key:"header",fn:function(){return [(col.field === _vm.ColumnField.ISSUER_FEE_INITIAL_NEW)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
          _vm.$t('dynamicCaseTable.columnNewIssuerFeeContext').toString()
        ),expression:"\n          $t('dynamicCaseTable.columnNewIssuerFeeContext').toString()\n        ",modifiers:{"bottom":true}}]},[_vm._v(" "+_vm._s(col.header)+" ")]):[_vm._v(" "+_vm._s(col.header)+" ")]]},proxy:true},(col.field === 'number')?{key:"body",fn:function(slotProps){return [_c('p',{staticClass:"dynamic-case-table__number"},[_c('router-link',{attrs:{"to":("/case/" + (slotProps.data['id'])),"target":"_blank"}},[_vm._v(" "+_vm._s(slotProps.data.number)+" ")]),(slotProps.data['is_problematic'])?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.$t('dynamicCaseTable.foundProblemsForCase').toString()),expression:"$t('dynamicCaseTable.foundProblemsForCase').toString()",modifiers:{"bottom":true}}],staticClass:"pi pi-exclamation-circle"}):_vm._e()],1)]}}:{key:"body",fn:function(slotProps){return [(col.alignment === _vm.ColumnAlignment.RIGHT)?_c('p',{style:({ textAlign: 'right' })},[_vm._v(" "+_vm._s(slotProps.data[col.field])+" ")]):[_vm._v(" "+_vm._s(slotProps.data[col.field])+" ")]]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }