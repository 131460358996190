var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Accordion',[_c('AccordionTab',{attrs:{"header":"Üleandmise ootel olevad toimikud"}},[_c('div',[_c('sui-table',{staticClass:"transfer-case-table__table",attrs:{"striped":""}},[_c('sui-table-header',{staticClass:"transfer-case-table__header"},[_c('sui-table-row',[_c('sui-table-header-cell',[_vm._v("Üleandev täitur")]),_c('sui-table-header-cell',[_vm._v("Kuupäev")]),_c('sui-table-header-cell',[_vm._v("Toimikud")]),_c('sui-table-header-cell',[_vm._v("Märkus")]),_c('sui-table-header-cell')],1)],1),(_vm.transfers.length)?_c('sui-table-body',_vm._l((_vm.transferList),function(caseTransfer){return _c('sui-table-row',{key:caseTransfer.id},[_c('sui-table-cell',[_vm._v(_vm._s(caseTransfer.name_person_giving))]),_c('sui-table-cell',[_vm._v(_vm._s(_vm.getFormattedDateTime(caseTransfer.date_initiated)))]),_c('sui-table-cell',[(caseTransfer.case_ids.length > 3)?_c('div',[_c('sui-list',[_c('sui-list-item',[_c('sui-popup',{attrs:{"flowing":"","hoverable":"","position":"bottom"}},[_c('sui-list',[_c('sui-list-item',[_vm._v("Esimesed "+_vm._s(caseTransfer.case_ids.length)+" toimikut..")]),_vm._l((caseTransfer.case_ids),function(transferCase){return _c('sui-list-item',{key:transferCase.id},[_c('router-link',{attrs:{"to":{
                              name: 'Case',
                              params: { id: transferCase.id },
                              query: { transfer_id: caseTransfer.id },
                            }}},[_c('a',{attrs:{"target":"_blank"}},[_vm._v(_vm._s(transferCase.number))])])],1)})],2),_c('div',{attrs:{"slot":"trigger"},slot:"trigger"},[_vm._v(" "+_vm._s(caseTransfer.case_ids[0].total)+" toimikut kokku.. ")])],1)],1)],1)],1):_c('div',[_c('sui-list',_vm._l((caseTransfer.case_ids),function(transferCase){return _c('sui-list-item',{key:transferCase.id},[_c('router-link',{attrs:{"to":{
                        name: 'Case',
                        params: { id: transferCase.id },
                        query: { transfer_id: caseTransfer.id },
                      }}},[_c('a',{attrs:{"target":"_blank"}},[_vm._v(_vm._s(transferCase.number))])])],1)}),1)],1)]),_c('sui-table-cell',[_vm._v(_vm._s(caseTransfer.description))]),_c('sui-table-cell',[_c('sui-button',{attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.acceptTransfer(caseTransfer)}}},[_vm._v(" Võta vastu ")]),_c('sui-button',{attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.openRejectModal(caseTransfer)}}},[_vm._v(" Keeldu ")])],1)],1)}),1):_vm._e()],1),_c('transfer-case-reject-modal',{attrs:{"transfer":_vm.transfer,"open":_vm.isRejectModalOpen},on:{"onToggle":function($event){return _vm.toggleRejectModal()},"onReject":_vm.rejectTransfer}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }