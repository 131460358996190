import { SettingNew } from '@/types/Setting/Setting';
import req from '../lib/req';
import { Id, Setting } from '@/types';

export function findById(id: Id) {
  return req.get(`/setting?tenant_id=${id}`);
}

export function create(settingNew: SettingNew<any>): Promise<Setting<any>> {
  return req.post(`/setting`, settingNew).then((response) => response.data);
}

export function update(id: Id, value: unknown) {
  return req.put(`/setting/${id}`, value);
}

export function shareTemplates(name: unknown, id: unknown) {
  return req.post('/setting/share', { template: name, id });
}

export function copyTemplate(name: unknown) {
  return req.post('/setting/copy', { template: name });
}

export function fetchByType<T>(
  bureauId: Id,
  type: string
): Promise<Setting<T>[]> {
  return req
    .get('/setting', {
      params: {
        tenant_id: bureauId,
        type,
      },
    })
    .then((resp) => resp.data);
}

export function findOnlyGlobal(): Promise<Setting<any>[]> {
  return req.get('/setting/global').then((resp) => resp.data);
}

export function findOnlyOfCurrentBureau(): Promise<Setting<any>[]> {
  return req.get('/setting/current-bureau').then((resp) => resp.data);
}
