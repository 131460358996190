import req from '../lib/req';
import { Collection } from '@/types/ApiPlatform/Collection';
import { DocumentSaved } from '@/types/Document';

/**
 * API methods for handling documents as user manuals.
 * The difference between normal documents is that user manuals are
 * not bureau-specific and should be accessible from any bureau. This
 * is why a lot of custom endpoint contollers are used here.
 */


export function findAllUserManualDocuments(): Promise<Collection<DocumentSaved>> {
    return req.get(`/v2/manuals`).then((resp) => resp.data);
}

export function download(document: DocumentSaved): Promise<Blob> {
    return req.get(`/v2/manuals/${document.id}`, { responseType: 'blob' }).then((response) => response.data);
}

export function update(document: DocumentSaved): Promise<DocumentSaved> {
    return req.put(`/v2/manuals/${document.id}`, document);
}

export function remove(document: DocumentSaved): Promise<void> {
    return req.delete(`/v2/manuals/${document.id}`);
}

