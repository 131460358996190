<template>
  <div class="radio__wrapper">
    <sui-checkbox
      v-for="choice in choices"
      :key="choice.value"
      v-model="selectedValue"
      :label="choice.label"
      :disabled="disabled"
      :value="choice.value.toString()"
      radio
    />
  </div>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    choices: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    selectedValue: {
      get() {
        if (this.value === null || typeof this.value === 'undefined')
          return null;

        return this.value.toString();
      },
      set(value) {
        this.$emit('input', parseInt(value, 10));
      },
    },
  },
};
</script>

<style scoped>
.radio__wrapper {
  margin-bottom: 0.25rem;
  white-space: nowrap;
}

.ui.radio.checkbox {
  margin: 0.25rem 1rem 0 0;
}
</style>
