import req from './lib/req';

export default {
  findOne(id) {
    return req.get(`/classifier/${id}`);
  },
  findAll() {
    return req.get('/classifier');
  },
  findByName(name) {
    const params = {
      name,
    };
    return req.get('/classifier', {
      params: {
        where: JSON.stringify(params),
      },
    });
  },
};
