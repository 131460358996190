<template>
  <sui-form-fields>
    <sui-form-field :width="14">
      <label>{{ $t('person.addresses.address') }}</label>

      <InputTooltip :text="formattedAddress">
        <div class="ui icon input">
          <input
            v-model="formattedAddress"
            v-gmaps-searchbox:location.formatted_address.address_components="vm"
            :disabled="disabled"
            placeholder=""
          />
          <i class="address book outline icon" />
        </div>
      </InputTooltip>
    </sui-form-field>

    <sui-form-field v-if="!disabled" :width="2" class="row-actions__field">
      <div class="row-actions__container">
        <DeletePopover v-if="showRemove" use-icon @confirm="remove" />

        <sui-button
          v-if="showAdd"
          class="row-action"
          icon="plus"
          type="button"
          @click.prevent="add"
        />
      </div>
    </sui-form-field>
  </sui-form-fields>
</template>

<script>
// @flow
import DeletePopover from '../DeletePopover.vue';
import InputTooltip from '../InputTooltip.vue';

export default {
  name: 'AddressSearch',
  components: {
    DeletePopover,
    InputTooltip,
  },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vm: {
        location: {},
      },
    };
  },
  computed: {
    formattedAddress: {
      get() {
        return this.vm.location.formatted_address || this.address.address;
      },
      set(value) {
        this.$emit('update', { address: value });
      },
    },
  },
  beforeUpdate() {
    // eslint-disable-next-line camelcase
    const { address_components, formatted_address } = this.vm.location;
    // eslint-disable-next-line camelcase
    if (formatted_address) {
      const country = address_components.find((addressComponent) =>
        addressComponent.types.includes('country')
      );
      const zip = address_components.find((addressComponent) =>
        addressComponent.types.includes('postal_code')
      );
      const address = {
        address: formatted_address,
        country: country === undefined ? '' : country.long_name,
        zip: zip === undefined ? '' : zip.long_name,
      };

      this.$emit('update', address);
    }
  },
  methods: {
    add() {
      this.$emit('add');
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>
