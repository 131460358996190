/**
 * Enum values of each type of setting.
 */
export enum SettingType {
  CASE_RESOLVER = 'CASE_RESOLVER',
  CASE_SOLUTION_TYPE = 'CASE_SOLUTION_TYPE',
  CLAIM_TYPE = 'CLAIM_TYPE',
  CLAIM_DUE_DATE = 'CLAIM_DUE_DATE',
  CLAIM_SURCHARGE_TYPE = 'CLAIM_SURCHARGE_TYPE',
  CLAIM_CLAIM_TYPE = 'CLAIM_CLAIM_TYPE',
  REPRESENTATIVE_TYPE = 'REPRESENTATIVE_TYPE',
  RESOLVING_TYPE = 'RESOLVING_TYPE',
  MIN_WAGE = 'MIN_WAGE',
  E_ARREST_FEE = 'E_ARREST_FEE',
  DEFAULT_DEADLINE = 'DEFAULT_DEADLINE',
  VOLUNTARY_FULFILLMENT_DEADLINE = 'VOLUNTARY_FULFILLMENT_DEADLINE',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  DOCUMENT_LAYOUT_ARREST = 'DOCUMENT_LAYOUT_ARREST',
  DOCUMENT_STATUS_REASON = 'DOCUMENT_STATUS_REASON',
  TRANSFER_REASON = 'TRANSFER_REASON',
  WORKAREA = 'WORKAREA',
  ALLOWED_TMS = 'ALLOWED_TMS',
  ARREST_CHANGE_DEFAULT_PERIOD = 'ARREST_CHANGE_DEFAULT_PERIOD',
  DEFAULT_CLOSE_TEMPLATE = 'DEFAULT_CLOSE_TEMPLATE',
  DEFAULT_EXPIRE_CLOSE_TEMPLATE = 'DEFAULT_EXPIRE_CLOSE_TEMPLATE',
  DEFAULT_STOP_BASIS = 'DEFAULT_STOP_BASIS',
  DEFAULT_RESOLVING_TYPE = 'DEFAULT_RESOLVING_TYPE',
}
