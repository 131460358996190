export const ContactTypes = {
  PHONE: 0,
  EMAIL: 1,
};

export const Genders = {
  UNKNOWN: 2,
  FEMALE: 1,
  MALE: 0,
};

export const Roles = {
  DEBTOR: 'debtor',
  CLAIMANT: 'claimant',
  REPRESENTATIVE: 'representative',
};

export const Types = {
  PRIVATE: 1,
  JURIDICAL: 2,
};
