/**
 * Enum for field values of case's columns. Note that only those field values
 * are exposed here which may have some special use case in front-end.
 * NB: Same constant values as in back-end /DataTableColumn.php.
 */
export enum ColumnField {
  BUREAU = 'bureau',
  NUMBER = 'number',
  ID = 'id',
  ISSUER_FEE_INITIAL = 'issuer_fee_initial',
  ISSUER_FEE_INITIAL_NEW = 'issuer_fee_initial_new',
}
