<template>
  <Dialog
    :style="{ width: '75%' }"
    :visible.sync="visible"
    header="Päringud"
    position="top"
  >
    <Panel v-for="(item, index) in queries" :key="index">
      <template #header>
        {{ getFormattedDateTime(item.created_at) }}
      </template>
      <div class="p-grid">
        <div class="p-col-6">
          <tree-view
            :data="
              item.request !== '' ? JSON.parse(item.request) : item.request
            "
            :options="{ maxDepth: 3 }"
          ></tree-view>
        </div>
        <div class="p-col-6">
          <tree-view
            :data="
              item.response !== '' ? JSON.parse(item.response) : item.response
            "
            :options="{ maxDepth: 3 }"
          ></tree-view>
        </div>
      </div>
    </Panel>
    <template #footer>
      <Button :label="$t('close')" icon="pi pi-times" @click="toggle" />
    </template>
  </Dialog>
</template>
<script>
import QueryAPI from '../../api/query';
import { getFormattedDateTime } from '../../mixins/dateTime';

export default {
  name: 'ArrestQueryModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    arrest: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      queries: [],
    };
  },
  mounted() {
    if (this.arrest && this.arrest.query_ids.length > 0) {
      // eslint-disable-next-line prefer-spread
      QueryAPI.findByIds(this.arrest.query_ids).then((response) => {
        this.queries = response.data.items;
      });
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
    getFormattedDateTime,
  },
};
</script>
