import req from './lib/req';

export default {
  findByCaseId(id) {
    return req.get(`/case-solution?where={"enforcementCase":"${id}"}`);
  },
  find(id) {
    return req.get(`/case-solution/${id}`);
  },
  create(data) {
    return req.post('/case-solution', data);
  },
  update(id, data) {
    return req.put(`/case-solution/${id}`, data);
  },
};
