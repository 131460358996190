import api from '../../api';
import { mapState, mapActions, mapGetters } from 'vuex';
import pageNameSync from '../../mixins/pageNameSync';
import DocumentsTab from './components/Documents/Documents.vue';
import Solution from '../../components/Case/Solution.vue';
import Claims from '../../components/Case/Claims/Claims.vue';
import Person from '../../components/Person/Person.vue';
import Upload from '../../components/Upload.vue';
import { removeEmptyValues } from '../../mixins/util';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../mixins/notification';
import { getCurrentDateTime } from '../../mixins/dateTime';
import { validateClaim } from '../../mixins/formValidator';

export default {
  components: {
    [DocumentsTab.name]: DocumentsTab,
    Claims,
    Person,
    Solution,
    Upload,
  },
  mixins: [pageNameSync],
  data: () => ({
    active: 'Üldandmed',
    id: '',
    menuItems: ['Üldandmed', 'Dokumendid'],
    interest: false,
    interestType: '1',
    interestAccounted: false,
    isLoading: false,
    form: {
      documents: [],
    },
    preference: {},
    genderOptions: [
      { value: 'M', text: 'Mees' },
      { value: 'N', text: 'Naine' },
    ],
    solution: {},
    claims: [],
  }),
  computed: {
    ...mapState('cases', ['cases']),
    ...mapGetters('user', ['selectedRole']),
    ...mapGetters('settings', ['settings']),
  },
  mounted() {
    const page = document.querySelector('.case--new');
    const firstInput = page.querySelector('input[type="text"]');

    firstInput.focus();
  },
  methods: {
    ...mapActions('app', ['changePageName']),
    isActive(name) {
      return this.active === name;
    },
    select(name) {
      this.active = name;
    },
    async addCaseData(event) {
      event.preventDefault();
      let invalid = false;
      this.isLoading = true;

      this.claims.forEach((claim) => {
        const validation = validateClaim(claim);

        if (validation) {
          showErrorNotification({
            text: validation,
          });

          invalid = true;
          this.isLoading = false;
        }
      });

      if (invalid) return;

      const { debtorData, claimantData, ...rest } = this.form;
      const hasMainClaim = !!this.claims.find((claim) => claim.mainClaim);

      if (!debtorData || !claimantData) {
        showErrorNotification({
          title: this.$t('notification.saveError'),
          text: this.$t('notification.savePersonError'),
        });

        this.isLoading = false;
        return;
      }

      if (!hasMainClaim) {
        showErrorNotification({
          title: this.$t('notification.saveError'),
          text: this.$t('notification.saveClaimError'),
        });

        this.isLoading = false;
        return;
      }

      const [debtorId, claimantId] = await Promise.all([
        this.createPersonAndGetId(debtorData),
        this.createPersonAndGetId(claimantData),
      ]);

      if (!debtorId || !claimantId) return;

      const caseData = {
        ...rest,
        debtor: debtorId,
        claimant: claimantId,
        date_opened: getCurrentDateTime(),
      };

      if (this.id) {
        api.cases
          .update(this.id, caseData)
          .then((response) => {
            const { id, number } = response.data;

            this.saveSolutionAndClaims(id).then(() => {
              this.$router.push({ name: 'CaseList' });

              showSuccessNotification({
                duration: 30000,
                text: `Loodi uus toimik ${number}`,
              });
            });
          })
          .catch(() => {
            this.onFail();
            this.isLoading = false;
          });
      } else {
        api.cases
          .create(caseData)
          .then((response) => {
            const { id, number } = response.data;
            this.id = id;

            this.saveSolutionAndClaims(id).then(async () => {
              await api.cases.recalc(response.data.id);
              await api.cases.addToTarn(response.data.id);

              this.$router.push({ name: 'CaseList' });

              showSuccessNotification({
                duration: 30000,
                text: `Loodi uus toimik ${number}`,
              });
            });
          })
          .catch(() => {
            this.onFail();
            this.isLoading = false;
          });
      }
      this.isLoading = false;
    },
    saveSolutionAndClaims(id) {
      return Promise.all([this.saveSolution(id), this.saveClaims(id)]);
    },
    saveSolution(id) {
      return api.solution
        .create({
          ...this.solution,
          enforcementCase: id,
        })
        .catch(() => {
          showErrorNotification({
            text: 'Lahendi salvestamine ebaõnnestus',
          });
        });
    },
    saveClaims(id) {
      const promises = this.claims.map(async (claim) => {
        const surcharge = await this.saveSurcharge(claim.surchargeData);

        const { data } = await api.claim.create({
          ...claim,
          enforcement_case_id: id,
          surcharge,
        });
        return data.id;
      });

      return Promise.all(promises).catch(() => {
        showErrorNotification({
          text: 'Nõuete salvestamine ebaõnnestus',
        });
      });
    },
    async saveSurcharge(surcharge) {
      const { data } = await api.surcharge.create(surcharge);
      return data.id;
    },
    onFail() {
      showErrorNotification({
        title: this.$t('notification.saveError'),
        text: this.$t('notification.saveErrorDescription'),
      });
      this.isLoading = false;
    },
    async createPersonAndGetId(person) {
      const {
        accountsData,
        addressData,
        contactsData,
        representativesData,
        ...rest
      } = person;

      const [contacts, addresses, bankAccounts, representatives] =
        await Promise.all([
          this.getContactIds(contactsData),
          this.getAddressIds(addressData),
          this.getAccountIds(accountsData),
          this.getRepresentativeIds(representativesData),
        ]);

      const personCopy = {
        ...removeEmptyValues(rest),
        contacts,
        addresses,
        bankAccounts,
        representatives,
      };

      delete personCopy.id;
      let id = '';

      await api.person
        .create(personCopy)
        .then((response) => {
          ({ id } = response.data);
        })
        .catch(() => {
          showErrorNotification({
            title: this.$t('notification.saveError'),
            text: this.$t('notification.saveErrorDescription'),
          });
        });

      return id;
    },
    async getAccountIds(accounts) {
      const promises = accounts.map(async (account) => {
        const { accountNo, name } = account;

        const newAccount = {
          accountNo,
          name,
        };

        const { data } = await api.banks.create(newAccount);
        return data.id;
      });

      return Promise.all(promises);
    },
    async getAddressIds(addresses) {
      if (addresses.length < 1) {
        return [];
      }
      const promises = addresses.map(async (address) => {
        const { data } = await api.addresses.create(address);
        return data.id;
      });

      return Promise.all(promises);
    },
    async getContactIds(contacts) {
      const nonEmptyContacts = contacts.filter((contact) => contact.value);

      const promises = nonEmptyContacts.map(async (contact) => {
        const { data } = await api.contacts.create(contact);
        return data.id;
      });

      return Promise.all(promises);
    },
    async getRepresentativeIds(representatives) {
      const promises = representatives.map(async (representative) => {
        const { data } = await api.person.create({
          ...representative,
          represent_basis: representative.represent_basis.toString(),
        });
        return data.id;
      });

      return Promise.all(promises);
    },
    definePerson(type) {
      let person = {};

      if (type) {
        person = this.form.claimant;
      } else {
        person = this.form.debtor;
      }

      return person;
    },
    addContact(type) {
      const person = this.definePerson(type);

      if (person.newPhone !== '') {
        person.contacts.push({
          contact: 'Telefon',
          value: person.newPhone,
        });
        person.newPhone = '';
      }

      if (person.newEmail !== '') {
        person.contacts.push({
          contact: 'E-mail',
          value: person.newEmail,
        });
        person.newEmail = '';
      }
    },
    addAddress(type) {
      const person = this.definePerson(type);

      person.addresses.push({
        address: person.vm.location.formatted_address,
        components: person.vm.location.address_components,
      });
      person.vm.location.formatted_address = '';
    },
    addAccount(type) {
      const person = this.definePerson(type);

      person.accounts.push({
        owner: person.newOwner,
        number: person.newNumber,
        bank: person.newBank,
      });
      person.newOwner = '';
      person.newNumber = '';
      person.newBank = '';
    },
    onFilesUpdate(files) {
      this.form.documents = files;
    },
    onPersonDataChange(role, data) {
      this.form[`${role}Data`] = data;
    },
    onSolutionChange(data) {
      this.solution = data;
    },
    onClaimChange(data) {
      this.claims = data;
    },
  },
};
