export const TarnEditTypes = {
  1: 'Vastuvõtmine',
  2: 'Üleandmine',
  3: 'Lõpetamine',
  4: 'Osaline lõpetamine',
  5: 'Aegumine',
  6: 'Tasude muutmine',
  7: 'Peatamine',
  8: 'Peatamise lõpetamine',
  9: 'Avamine',
  10: '',
  11: '',
};
