import { ArrestStatus } from './arrestStatus';

export const ArrestStatusToLabel: Record<ArrestStatus, string> = {
  [ArrestStatus.ACTIVE_LEGACY]: 'arrest.status.earest.active_legacy',
  [ArrestStatus.ACTIVE]: 'arrest.status.earest.active',
  [ArrestStatus.ARREST_APPLY_ERROR]: 'arrest.status.earest.arrest_apply_error',
  [ArrestStatus.ARREST_CANCEL_ERROR]: 'arrest.status.earest.arrest_cancel_error',
  [ArrestStatus.ARREST_CHANGE_ERROR]: 'arrest.status.earest.arrest_change_error',
  [ArrestStatus.ARREST_FAIL_OTHER]: 'arrest.status.earest.arrest_fail_other',
  [ArrestStatus.ARREST_FULFILLED]: 'arrest.status.earest.arrest_fulfilled',
  [ArrestStatus.BANKRUPT]: 'arrest.status.earest.bankrupt',
  [ArrestStatus.CANCEL_ERROR_CANCELLED]: 'arrest.status.earest.cancel_error_cancelled',
  [ArrestStatus.CANCEL_ERROR_FULFILLED]: 'arrest.status.earest.cancel_error_fulfilled',
  [ArrestStatus.CANCEL_ERROR_OTHER]: 'arrest.status.earest.cancel_error_other',
  [ArrestStatus.CANCEL_SUCCESS]: 'arrest.status.earest.cancel_success',
  [ArrestStatus.CHANGE_CHANGED]: 'arrest.status.earest.change_changed',
  [ArrestStatus.CHANGE_FAILED_CANCELLED]: 'arrest.status.earest.change_failed_cancelled',
  [ArrestStatus.CHANGE_FAILED_FULFILLED]: 'arrest.status.earest.change_failed_fulfilled',
  [ArrestStatus.CHANGE_FAILED_OTHER]: 'arrest.status.earest.change_failed_other',
  [ArrestStatus.IN_ARREST_CANCEL]: 'arrest.status.earest.in_arrest_cancel',
  [ArrestStatus.IN_ARREST_CHANGE]: 'arrest.status.earest.in_arrest_change',
  [ArrestStatus.IN_ARREST]: 'arrest.status.earest.in_arrest',
  [ArrestStatus.INACTIVE]: 'arrest.status.earest.in_active',
  [ArrestStatus.MISSING_DATA]: 'arrest.status.earest.missing_data',
  [ArrestStatus.NO_ACCOUNT]: 'arrest.status.earest.no_account',
  [ArrestStatus.NOT_CLIENT]: 'arrest.status.earest.not_client',
  [ArrestStatus.REMOVED]: 'arrest.status.earest.removed'
};
