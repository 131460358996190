export const Status = {
  ACTIVE: 0,
  STOPPED: 1,
  FULFILLED: 2,
  CANCELLED: 3,
  EXPIRED: 4,
  REPLACED: 5,
  NOT_ENFORCEABLE: 6,
};

export const Types = {
  TYPE_MONETARY: 0,
  TYPE_NONMONETARY: 1,
  TYPE_PERIODICAL: 2,
};
