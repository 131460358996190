import { CaseStatus } from '@/static/enums/caseStatus';

/**
 * Map of labels to translate a case's Status into a human readable form.
 */
export const CaseStatusToLabel: Record<CaseStatus, string> = {
  [CaseStatus.ARCHIVED]: 'case.status.archived',
  [CaseStatus.CLOSED]: 'case.status.closed',
  [CaseStatus.OPEN]: 'case.status.open',
  [CaseStatus.PARTIALLY_CLOSED]: 'case.status.partially_closed',
  [CaseStatus.REJECTED]: 'case.status.rejected',
  [CaseStatus.STOPPED]: 'case.status.stopped',
  [CaseStatus.TARN]: 'case.status.tarn',
};
