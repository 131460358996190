// @flow

export default {
  name: 'UiColumnHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Number],
      default: null,
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
};
