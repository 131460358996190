<template>
  <Dialog :visible="isOpen" :closable="false">
    <template #header>
      <span class="p-dialog-title">
        {{ $t('title.modal.delivery') }}
        <i
          v-tooltip="{
            value:
              'toimiku_nr;kattetoimetamise_kp\n' +
              '001/2002/001;06.07.2022',
          }"
          class="pi pi-question-circle"
        ></i>
      </span>
    </template>

    <div v-if="isLoading" class="delivery-import-modal__loading">
      <div class="delivery-import-modal__loader" />
      <p>{{ $t('import.loading').toString() }}</p>
    </div>

    <Upload
      ref="uploadContainer"
      :url="apiUrl"
      :timeout="timeout"
      @onUpload="handleFileUpload"
      @onSuccess="handleFileUploadSuccess"
      @onError="handleUploadError"
    />
    <ScrollPanel
      v-if="errors.length > 0"
      style="width: 100%; max-width 300px; max-height: 300px"
      class="import-error-box"
    >
      <table>
        <tbody>
          <tr v-for="(error, index) in errors" :key="index">
            <td>
              {{ error }}
            </td>
          </tr>
        </tbody>
      </table>
    </ScrollPanel>
    <template #footer>
      <Button :label="$t('close')" icon="pi pi-check" autofocus @click="toggleModal" />
    </template>
  </Dialog>
</template>
<script>
import Upload from '@/components/Upload';
import { showErrorNotification, showSuccessNotification } from '@/mixins/notification';

export default {
  name: 'DeliveryImportModal',
  components: { Upload },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/import/delivery`,
      errors: [],
      isLoading: false,
      // Timeout for upload and import process.
      // NB: Same timeout as in ImportExportController::importDeliveryDates()!
      timeout: 600000,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    clear() {
      if (this.$refs.uploadContainer) {
        this.$refs.uploadContainer.reset();
      }
      this.errors = [];
    },
    handleFileUpload() {
      this.isLoading = true;

      setTimeout(() => {
        if (this.isLoading) {
          // If after 30 seconds the file uploading is still in progress then
          // due to large amount of case numbers in file the timeout has already
          // actually been exceeded and the file should be reuploaded.
          showErrorNotification({
            text: this.$t('import.timeoutExceeded').toString(),
            duration: 10000,
          });
          this.isLoading = false;
          this.clear();
        }
      }, this.timeout);
    },
    handleFileUploadSuccess() {
      this.isLoading = false;
      this.clear();
      showSuccessNotification({ text: this.$t('import.success') });
    },
    handleUploadError(uploadError) {
      this.isLoading = false;
      this.errors =
        typeof uploadError.message === 'object'
          ? Object.values(uploadError.message)[0]
          : [];
      this.$refs.uploadContainer.reset();
      showErrorNotification({ text: this.$t('import.fail') });
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../mixins/loader.scss';

.delivery-import-modal__loading {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.delivery-import-modal__loader {
  @include loader;

  width: 16px;
  height: 16px;
  border-width: 2px;
}
</style>
