/**
 * Enum for possible arrest status codes (EArrest).
 *
 * NB: Same as STATUS_* constants in Arrest.php! Keep them in sync.
 */
export enum ArrestStatus {
  // - SUCCESS status codes.
  'ACTIVE_LEGACY' = 101, // Arest teostatud.
  'ACTIVE' = 1, // Arest teostatud.
  'ARREST_FULFILLED' = 7, // Arest täidetud.
  'CANCEL_SUCCESS' = 21, // Arest edukalt tühistatud.
  'CHANGE_CHANGED' = 11, // Arest muudetud.

  // - INFORMATIVE status codes.
  'IN_ARREST_CANCEL' = 106, // Arest tühistamisel.
  'IN_ARREST_CHANGE' = 105, // Arest muutmisel.
  'IN_ARREST' = 104, // Arestimisel.
  'INACTIVE' = 100, // Arest mitteaktiivne.
  'REMOVED' = 102, // Arest eemaldatud.

  // - ERROR status codes.
  // Attempted to ADD arrest.
  'ARREST_APPLY_ERROR' = 107, // Viga arestimisel.
  'ARREST_FAIL_OTHER' = 4, // Aresti ei saanud teostada, muud põhjused.
  'BANKRUPT' = 2, // Aresti ei saanud teostada, isik pankrotis.
  'MISSING_DATA' = 3, // Aresti ei saanud teostada, puudused esitatud võlgniku isikuandmetes.
  'NO_ACCOUNT' = 6, // Aresti ei saanud teostada, võlgniku konto puudu.
  'NOT_CLIENT' = 5, // Aresti ei saanud teostada, võlgnik pole asutuse klient.

  // Attempted to CHANGE arrest.
  'ARREST_CHANGE_ERROR' = 108, // Viga aresti muutmisel.
  'CHANGE_FAILED_CANCELLED' = 12, // Aresti muutmine ebaõnnestus, arest on tühistatud.
  'CHANGE_FAILED_FULFILLED' = 13, // Aresti muutmine ebaõnnestus, arest on täidetud.
  'CHANGE_FAILED_OTHER' = 14, // Aresti muutmine ebaõnnestus, muu põhjus.

  // Attempted to CANCEL arrest.
  'ARREST_CANCEL_ERROR' = 109, // Viga aresti tühistamisel.
  'CANCEL_ERROR_CANCELLED' = 23, // Aresti tühistamine ebaõnnestus, arest on juba tühistatud.
  'CANCEL_ERROR_FULFILLED' = 22, // Aresti tühistamine ebaõnnestus, arest on täidetud.
  'CANCEL_ERROR_OTHER' = 24, // Aresti tühistamine ebaõnnestus, muu põhjus.
}
