import req from '../lib/req';

export function create(data) {
  return req.post('/surcharge', data);
}

export function update(id, data) {
  return req.put(`/surcharge/${id}`, data);
}

export function getSurcharges(idList) {
  const params = {
    id: idList,
  };

  return req.get('/surcharge', {
    params: {
      where: JSON.stringify(params),
    },
  });
}
