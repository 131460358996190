/**
 * Enum for Problem entity type values.
 * NB: Same values as in Entity/Problem.php! Keep in sync.
 */
export enum ProblemType {
  VALIDATION_ERRORS = 1,
  NOT_OPENED_IN_TARN = 2,
  NOT_IN_SYNC_WITH_TR_OPENED = 3,
  NOT_IN_SYNC_WITH_TR_CLOSED = 4,
  NOT_IN_SYNC_WITH_TR_OTHER = 5,
}
