<template>
  <sui-label :color="color" :title="arrest.bankName">
    {{ arrest.bankName }}
    <span v-if="arrest.amount_of_min_wages">(min)</span>
  </sui-label>
</template>

<script>
export default {
  name: 'Bank',
  props: {
    arrest: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    color: {
      get() {
        if (this.arrest.amount_of_min_wages) {
          return 'teal';
        }

        return 'grey';
      },
    },
  },
};
</script>

<style scoped>
.ui.label {
  display: block;
  margin: 2px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

span {
  font-weight: normal;
}
</style>
