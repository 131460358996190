export const Statuses = {
  ACTIVE: 1,
  BANKRUPT: 2,
  MISSING_DATA: 3,
  ARREST_FAIL_OTHER: 4,
  NOT_CLIENT: 5,
  NO_ACCOUNT: 6,
  ARREST_FULFILLED: 7,
  CHANGE_CHANGED: 11, // Arest muudetud
  CHANGE_FAILED_CANCELLED: 12, // Aresti muutmine ebaõnnestus, arest on tühistatud
  CHANGE_FAILED_FULFILLED: 13, // Aresti muutmine ebaõnnestus, arest on täidetud
  CHANGE_FAILED_OTHER: 14, // Aresti muutmine ebaõnnestus, muu põhjus
  CANCEL_SUCCESS: 21,
  CANCEL_ERROR_FULFILLED: 22,
  CANCEL_ERROR_CANCELLED: 23,
  CANCEL_ERROR_OTHER: 24,
  IN_ARREST: 104,
  IN_ARREST_CHANGE: 105,
  IN_ARREST_CANCEL: 106,
  INACTIVE: 100,
  ACTIVE_OLD: 101,
  REMOVED: 102,
  ARREST_APPLY_ERROR: 107,
  ARREST_CHANGE_ERROR: 108,
  ARREST_CANCEL_ERROR: 109,
};
export const Errors = [
  Statuses.ARREST_APPLY_ERROR,
  Statuses.ARREST_CHANGE_ERROR,
  Statuses.ARREST_CANCEL_ERROR,
];
export const ActiveStatuses = [
  Statuses.ACTIVE,
  Statuses.NOT_CLIENT,
  Statuses.NO_ACCOUNT,
  Statuses.CHANGE_CHANGED,
  Statuses.CHANGE_FAILED_CANCELLED,
  Statuses.CHANGE_FAILED_FULFILLED,
  Statuses.CHANGE_FAILED_OTHER,
  Statuses.ACTIVE_OLD,
  Statuses.IN_ARREST,
  Statuses.IN_ARREST_CHANGE,
  Statuses.ARREST_CHANGE_ERROR,
  Statuses.ARREST_CANCEL_ERROR,
];
export const ChangedStatuses = [
  Statuses.CHANGE_CHANGED,
  Statuses.CHANGE_FAILED_CANCELLED,
  Statuses.CHANGE_FAILED_FULFILLED,
  Statuses.CHANGE_FAILED_OTHER,
  Statuses.IN_ARREST_CHANGE,
];
export const TYPE_WITHOUT_PAYMENT_ORDER = 'MAKSEJUHISETA';

/**
 * Duplicated services/koda/api/src/Enum/ArrestTransition.php
 * Must be kept in sync
 */
export const ArrestTransition = {
  DRAFT_TO_CREATING: 'draft_to_creating',
  CREATING_TO_ACTIVE: 'creating_to_active',
  CREATING_TO_DRAFT: 'creating_to_draft',
  ACTIVE_TO_UPDATING: 'active_to_updating',
  UPDATING_TO_ACTIVE: 'updating_to_active',
  ACTIVE_TO_REMOVING: 'active_to_removing',
  REMOVING_TO_ACTIVE: 'removing_to_active',
  REMOVING_TO_REMOVED: 'removing_to_removed',
};

/**
 * Duplicated services/koda/api/src/Enum/ArrestState.php
 * Must be kept in sync
 */
export const ArrestState = {
  LEGACY: 'legacy',
  DRAFT: 'draft',
  CREATING: 'creating',
  ACTIVE: 'active',
  UPDATING: 'updating',
  REMOVING: 'removing',
  REMOVED: 'removed',
};

/**
 * Arrest states where an action is in progress an no new actions can be applied.
 */
export const ArrestActionInProgressStates = [
  ArrestState.CREATING,
  ArrestState.UPDATING,
  ArrestState.REMOVING,
];
