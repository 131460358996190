/**
 * Enum for a type of comparison operator. For each type the emitted filter from
 * the DateFilterPicker component has a slightly different structure.
 */
export enum ComparisonType {
  EARLIER_THAN = 0, // One comparison used - "field < [start_of_current_day]".
  LATER_THAN = 1, // One comparison used - "field >= [start_of_next_day]".
  DATE_RANGE = 2, // Two comparisons used - "field < [end_of_period]" and "field >= [start_of_period]".
  DATE_MISSING = 3, // One comparison used - "field IS NULL".
  DATE_EQUAL_TO = 4, // Two comparison used - "field < [end_of_day]" and "field >= [start_of_day]"
}
