<template>
  <div>
    <DataTable
      :lazy="true"
      :loading="loading"
      :paginator="true"
      :row-hover="true"
      :rows="perPage"
      :rows-per-page-options="[5, 10, 25, 50]"
      :scrollable="true"
      :total-records="totalRecords"
      :value="arrests"
      class="p-datatable-striped"
      data-key="id"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      responsive-layout="scroll"
      scroll-height="500px"
      @page="onPage($event)"
    >
      <Column :header="$t('case.properties.establishment')">
        <template #body="slotProps">
          {{ slotProps.data.bank_name }}
        </template>
      </Column>

      <Column :header="$t('case.properties.status')">
        <template #body="slotProps">
          {{ getZonedDateTime(slotProps.data.date_response) }}
          <template
            v-if="
              slotProps.data.status_other_reason !== null &&
              slotProps.data.status_other_reason !== ''
            "
          >
            {{ slotProps.data.arrest_response }}:
            {{ slotProps.data.status_other_reason }}
          </template>
          <template v-else>
            {{ slotProps.data.arrest_response }}
          </template>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="
              slotProps.data.query_ids && slotProps.data.query_ids.length > 0
            "
            label="Päringud"
            @click="toggleQueriesModal(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <arrest-query-modal
      v-if="displayQueriesModal"
      :arrest="currentArrest"
      :visible="displayQueriesModal"
      @toggle="toggleQueriesModal"
    />
  </div>
</template>
<script>
import api from '../../api';
import { getZonedDateTime } from '@/mixins/dateTime';
import ArrestQueryModal from './ArrestQueryModal.vue';

export default {
  name: 'ArchivedArrestsTable',
  components: { ArrestQueryModal },
  props: {
    caseId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      arrests: [],
      totalRecords: 0,
      perPage: 5,
      page: 0,
      displayQueriesModal: false,
      currentArrest: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.banks = await api.banks.findAllActive();
      this.loading = true;
      let params = {};
      if (this.$route.query.transfer_id) {
        params = {
          transfer_id: this.$route.query.transfer_id,
          ...params,
        };
      }

      api.cases.findById(this.caseId, params).then((resp) => {
        if (resp.data.arrest_ids.length === 0) {
          this.loading = false;
          return;
        }
        api.arrest
          .findByIds(resp.data.arrest_ids, {
            ...params,
            archived: true,
            limit: this.perPage,
            page: this.page + 1,
          })
          .then((response) => {
            this.arrests = response.data.items.map((arrestItem) => {
              const bank = this.banks.find(
                (bankItem) => bankItem.id === arrestItem.bank_id
              );
              this.totalRecords = response.data.total_item_count;
              this.perPage = response.data.items_per_page;
              this.page = response.data.current_page;
              return {
                bank_name: bank ? bank.name : '',
                ...arrestItem,
              };
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    onPage(event) {
      this.perPage = event.rows;
      this.page = event.page;
      this.getData();
    },
    toggleQueriesModal(arrest) {
      this.displayQueriesModal = !this.displayQueriesModal;
      this.currentArrest = arrest;
    },
    getZonedDateTime,
  },
};
</script>
