<template>
  <ui-page>
    <div
      v-if="noticeMessageRows.length > 0"
      class="case__notice-message-wrapper"
    >
      <NoticeMessage
        :value="noticeMessageRows"
        @close="noticeMessageRows = []"
      />
    </div>
    <template #secondary>
      <sui-icon v-if="showChangeNoButton" name="edit" @click="changeCaseNo" />
      <Dropdown
        :value="caseInfo.status"
        :options="statuses"
        :disabled="!isEditable"
        option-label="name"
        option-value="status"
        style="background: #e0e1e2 none"
        @input="setStatus"
      />
      <sui-label is="sui-label" v-if="caseInfo.transfer_id" color="teal">
        Üleandmine pooleli
      </sui-label>
    </template>
    <div v-if="caseInfo">
      <div class="case-buttons__container">
        <sui-button
          v-if="!caseInfo.taitis_number"
          :loading="loadingSendToTarn"
          primary
          @click="sendToTarn"
        >
          Saada E-Täiturisse
        </sui-button>
        <sui-button
          v-if="canTransferCase()"
          :loading="loadingOpen"
          primary
          @click="toggleTransferCaseModal"
        >
          {{ $t('case.transfer') }}
        </sui-button>
        <sui-button
          v-if="showOpenButton"
          :loading="loadingOpen"
          primary
          @click="openCase"
        >
          {{ $t('case.open') }}
        </sui-button>

        <sui-button
          v-if="showReOpenButton"
          :loading="loadingOpen"
          primary
          @click="reOpenCase"
        >
          {{ $t('case.reopen') }}
        </sui-button>

        <sui-button
          v-if="showCloseButton"
          :loading="loading"
          primary
          @click="sendCaseCompletingReq"
        >
          {{ $t('case.close') }}
        </sui-button>
        <sui-button
          v-if="isRoleKoda || isRoleKT"
          :disabled="loading"
          @click="toggleQueryMenu"
        >
          Päringud
        </sui-button>
        <Menu ref="queryMenu" :model="queryItems" :popup="true" append-to="body" />
      </div>

      <p class="case__date">{{ $t('case.info.dateOpened') }}: {{ caseDate }}</p>
      <p v-if="caseStopped" class="case__date">
        {{ $t('case.info.stopStartDate') }}: {{ caseStopStartDate }}
      </p>
      <p v-if="caseClosed" class="case__date">
        {{ $t('case.info.dateClosed') }}: {{ caseEndDate }}
      </p>
      <p v-if="caseClosed" class="case__date">
        {{ $t('case.info.closeBasis') }}: {{ closeBasis }}
      </p>
      <p v-if="caseClosed" class="case__date">
        {{ $t('case.info.closeExplanation') }}: {{ caseInfo.close_explanation }}
      </p>
      <sui-tab
        :menu="{ pointing: true }"
        :active-index="activeTab"
        @change="handleTabChange"
        ref="tabsComponent"
      >
        <sui-tab-pane
          v-for="item in tabItems"
          :key="item.url"
          :title="item.name"
          :attached="false"
          :icon="item.icon"
        >
          <component
            :is="currentTabComponent"
            :case-id="caseId"
            :case-info="caseInfo"
            :debtor-data="debtorData"
            :claimant-data="claimantData"
            @onChange="loadCaseData()"
          ></component>
        </sui-tab-pane>
      </sui-tab>
      <div v-if="caseInfo && isEditable">
        <CloseCaseModal
          v-if="isCloseCaseModalOpen"
          :case="caseInfo"
          :warning="warning"
          :open="isCloseCaseModalOpen"
          @onClosingFail="showError"
          @onClosingSuccess="markClosed"
          @onToggle="toggleCloseCaseModal"
        />

        <ChangeNoModal
          v-if="isChangeNoModelOpen"
          :case-id="caseId"
          :open="isChangeNoModelOpen"
          @onChangeFail="showError"
          @onChangeSuccess="markChanged"
          @onToggle="toggleChangeNoModal"
        />

        <TransferCaseModal
          :cases="[caseInfo]"
          :open="isTransferCaseModalOpen"
          @onTransferSuccess="caseTransferSuccess"
          @onToggle="toggleTransferCaseModal"
        />
        <IssuerFeeUpdateModal
          :visible="isIssuerFeeUpdateModalOpen"
          :enforcement-case="caseInfo"
          @update="updateIssuerFee"
        />
      </div>
    </div>
  </ui-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import api from '../../api';
import NoticeMessage from '@/components/NoticeMessage/NoticeMessage.vue';
import OverallTab from './components/Overall/Overall.vue';
import PropertiesTab from './components/Properties/Properties.vue';
import DocumentsTab from './components/Documents/Documents.vue';
import Queries from './components/Queries/Queries.vue';
import Logs from './components/Logs.vue';
import Problems from './components/Problems.vue';
import CloseCaseModal from '../../components/Case/CloseCaseModal.vue';
import TransferCaseModal from '../../components/Case/TransferCaseModal.vue';
import IssuerFeeUpdateModal from '@/components/Case/IssuerFeeUpdateModal.vue';
import ChangeNoModal from './ChangeNoModal.vue';
import { CaseStatus } from '@/static/enums/caseStatus';
import { MENU_ITEMS, TabUrl } from '@/static/enums/case';
import { isCaseEditable } from '@/utils/isCaseEditable';
import {
  showSuccessNotification,
  showErrorNotification,
  showInfoNotification,
} from '@/mixins/notification';
import { getFormattedDate } from '@/mixins/dateTime';
import EventBus from '../../mixins/event-bus';
import Tooltip from '../../components/Tooltip.vue';
import { caseStatesEditable } from '@/static/enums/caseStateEditable';
import { CaseStateToLabel } from '@/static/enums/caseStateToLabel';
import { ProblemType } from '@/static/enums/problemType';

const STATUS_ARRESTED = 1;

// Max nr of problems to fetch for case.
const MAX_PROBLEMS_COUNT = 10;

export default {
  name: 'CasePage',
  components: {
    NoticeMessage,
    TransferCaseModal,
    Tooltip,
    IssuerFeeUpdateModal,
    [CloseCaseModal.name]: CloseCaseModal,
    [ChangeNoModal.name]: ChangeNoModal,
    'overall-tab': OverallTab,
    'assets-tab': PropertiesTab,
    'documents-tab': DocumentsTab,
    'queries-tab': Queries,
    'logs-tab': Logs,
    'problems-tab': Problems,
  },
  beforeRouteUpdate(to, from, next) {
    this.currentTab = to.params.tab;
    if (this.currentTab === 'general') {
      this.loadCaseData();
    }
    next();
  },
  data() {
    return {
      statuses: [
        { name: this.$t('case.status.open'), status: CaseStatus.OPEN },
        { name: this.$t('case.status.closed'), status: CaseStatus.CLOSED },
        { name: this.$t('case.status.stopped'), status: CaseStatus.STOPPED },
        { name: this.$t('case.status.tarn'), status: CaseStatus.TARN },
        { name: this.$t('case.status.rejected'), status: CaseStatus.REJECTED },
        {
          name: this.$t('case.status.partially_closed'),
          status: CaseStatus.PARTIALLY_CLOSED,
        },
      ],
      activeTab: 0,
      currentTab: 'overall',
      menuItems: MENU_ITEMS,
      caseInfo: null,
      loading: false,
      loadingOpen: false,
      loadingSendToTarn: false,
      warning: null,
      bankAccountList: [],
      isCloseCaseModalOpen: false,
      isChangeNoModelOpen: false,
      isTransferCaseModalOpen: false,
      claimantData: null,
      debtorData: null,
      closeBasis: '',
      queryItems: [
        {
          label: 'Saada lõpetamine',
          command: () => {
            this.sendEndQuery();
          },
        },
        {
          label: 'Saada vastuvõtmine',
          command: () => {
            this.sendAcceptQuery();
          },
        },
        {
          label: 'Saada NTK Vaatamine',
          command: () => {
            this.sendNTKVQuery();
          },
        },
        {
          label: 'Saada STE',
          command: () => {
            this.sendSTEQuery();
          },
        },
        {
          label: 'Sünkrooni toimiku andmed E-Täiturist',
          command: () => {
            this.sendSyncFromTarn();
          },
        },
      ],
      // List of message rows to display in case header notice as warnings.
      noticeMessageRows: [],
    };
  },
  computed: {
    ...mapGetters('user', ['isRoleDev', 'isRoleKoda', 'isRoleKT']),
    ...mapGetters('cases', ['hasError', 'error', 'isIssuerFeeUpdateModalOpen']),
    caseDate: {
      get() {
        return getFormattedDate(this.caseInfo.date_opened);
      },
    },
    caseEndDate: {
      get() {
        return getFormattedDate(this.caseInfo.date_closed);
      },
    },
    caseStopStartDate: {
      get() {
        return getFormattedDate(this.caseInfo.stop_start_date);
      },
    },
    caseClosed: {
      get() {
        if (!this.caseInfo) {
          return false;
        }
        return this.caseInfo.status === CaseStatus.CLOSED;
      },
    },
    caseStopped: {
      get() {
        if (!this.caseInfo) {
          return false;
        }
        return this.caseInfo.status === CaseStatus.STOPPED;
      },
    },
    caseId() {
      return this.$route.params.id;
    },
    caseNumber() {
      return this.caseInfo.number;
    },
    pageName() {
      if (this.caseNumber) {
        return `Täitetoimik #${this.caseNumber}`;
      }

      return 'Täitetoimik';
    },
    showCloseButton: {
      get() {
        return (
          this.caseInfo.status === CaseStatus.OPEN ||
          this.caseInfo.status === CaseStatus.PARTIALLY_CLOSED
        );
      },
    },
    showChangeNoButton: {
      get() {
        return (
          (this.isRoleDev || this.isRoleKoda) &&
          this.caseInfo.taitis_number === null &&
          this.isEditable
        );
      },
    },
    showReOpenButton: {
      get() {
        return (
          this.caseInfo.status === CaseStatus.CLOSED ||
          this.caseInfo.status === CaseStatus.PARTIALLY_CLOSED
        );
      },
    },
    showOpenButton: {
      get() {
        return this.caseInfo.status === CaseStatus.TARN;
      },
    },
    currentTabComponent() {
      return `${MENU_ITEMS[this.activeTab].url.toLowerCase()}-tab`;
    },
    /**
     * Whether the case can be edited (true) or not (false).
     */
    isEditable() {
      if (!this.caseInfo) {
        // Case not yet loaded.
        return false;
      }

      return isCaseEditable(this.caseInfo);
    },
    /**
     * Tab items to display in case header.
     */
    tabItems() {
      // For now we do not display disabled tabs at all.
      return this.menuItems.filter((item) => !item.disabled);
    },
  },
  created() {
    const loadClaimantAndDebtor = () => {
      let params = {};
      if (this.$route.query.transfer_id) {
        params = {
          transfer_id: this.$route.query.transfer_id,
        };
      }
      return Promise.all([
        api.cases
          .fetchBankAccounts({
            personId: this.caseInfo.debtor_id,
          })
          .then((res) => {
            this.bankAccountList = res.data;
          }),
        this.caseInfo.debtor_id
          ? api.person
              .findById(this.caseInfo.debtor_id, params)
              .then((resp) => (this.debtorData = resp.data))
          : false,
        this.caseInfo.claimant_id
          ? api.person
              .findById(this.caseInfo.claimant_id, params)
              .then((resp) => (this.claimantData = resp.data))
          : false,
      ]);
    };

    this.loadCaseData().then(() => {
      loadClaimantAndDebtor();
    });

    this.updateCurrentlyActiveProblems();

    EventBus.$on('RELOAD_CASE_DATA', () => {
      this.loadCaseData().then(() => {
        loadClaimantAndDebtor();
      });
    });

    EventBus.$on('RELOAD_PROBLEMS', async () => {
      this.updateCurrentlyActiveProblems();
    });
  },
  methods: {
    ...mapActions('app', {
      changePageName: 'changePageName',
    }),
    /**
     * Whether case can be transferred or not. Only KT role
     * is allowed to transfer a case.
     */
    canTransferCase() {
      if (!this.isRoleKT) {
        return false;
      }

      return this.isEditable;
    },
    loadCaseData() {
      let params = {};
      if (this.$route.query.transfer_id) {
        params = {
          transfer_id: this.$route.query.transfer_id,
        };
      }

      return api.cases
        .findById(this.caseId, params)
        .then((response) => {
          this.notifyAndReloadCaseIfNeeded(response.data);
          this.caseInfo = response.data;
          this.changePageName(this.pageName);

          if (this.caseInfo.close_basis_id) {
            // Fetch case's close basis name.
            api.classifierValueLegacy
              .findById(this.caseInfo.close_basis_id)
              .then((resp) => (this.closeBasis = resp.data.name));
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: 'CaseList' });
          }
        });
    },
    async fetchValidationWarnings() {
      const { messages } = await api.cases.getValidationWarnings(this.caseId);
      return messages;
    },
    isActive(item) {
      return this.currentTab === item.url;
    },
    select(item) {
      this.$router.push({
        name: 'Case',
        params: { id: this.caseId, tab: item.url },
      });
    },
    async sendCaseCompletingReq() {
      const arrestedBankAccounts = this.bankAccountList.filter(
        (account) => account.status === STATUS_ARRESTED
      );

      if (arrestedBankAccounts.length > 0) {
        this.warning = 'Võlgnikul on arestitud pangakontosid';
      }
      this.toggleCloseCaseModal();
    },
    async changeCaseNo() {
      this.toggleChangeNoModal();
    },
    showError() {
      showErrorNotification({
        title: this.$t('notification.caseCloseError'),
        text: this.$t('notification.caseCloseErrorDescription'),
      });
    },
    toggleCloseCaseModal() {
      this.isCloseCaseModalOpen = !this.isCloseCaseModalOpen;
    },
    toggleChangeNoModal() {
      this.isChangeNoModelOpen = !this.isChangeNoModelOpen;
    },
    toggleTransferCaseModal() {
      this.isTransferCaseModalOpen = !this.isTransferCaseModalOpen;
    },
    caseTransferSuccess() {
      this.toggleTransferCaseModal();
      this.loadCaseData();
    },
    markClosed() {
      this.isCloseCaseModalOpen = false;

      showSuccessNotification({
        title: this.$t('notification.caseCloseSuccess'),
      });
      this.$router.go();
    },
    markChanged() {
      this.toggleChangeNoModal();
      showSuccessNotification({
        title: this.$t('notification.caseNoChangeSuccess'),
      });

      this.loadCaseData();
    },
    deleteCase() {
      api.cases.deleteCase(this.caseId).then(() => {
        this.$router.push({ name: 'CaseList' });
      });
    },
    async openCase() {
      this.loadingOpen = true;
      if (!this.caseInfo.number) {
        await api.cases.createNumber(this.caseId);
      }
      api.cases
        .acceptTarn(this.caseId)
        .then(() => {
          this.loadingOpen = false;
          this.$router.go(0);
        })
        .catch((error) => {
          showErrorNotification({ text: error });
          this.loadingOpen = false;
        });
    },
    sendToTarn() {
      this.loadingSendToTarn = true;
      this.$store.dispatch('cases/sendToTarn', this.caseId).then(() => {
        this.loadingSendToTarn = false;
      });
    },
    async reOpenCase() {
      await this.$store.dispatch('cases/reOpen', this.caseId);
      if (this.hasError) {
        showErrorNotification({ text: this.error });
      } else {
        showSuccessNotification({
          title: this.$t('notification.caseReOpenSuccess'),
        });
        this.loadCaseData();
      }
      this.loadingOpen = false;
    },
    handleTabChange(e, activePane, index) {
      this.activeTab = index;
    },
    toggleQueryMenu(event) {
      this.$refs.queryMenu.toggle(event);
    },
    /**
     * When the loaded case is in a non-editable state, it means a third-party
     * request has not yet responded with some important data about the case
     * (f.e case is in TARN_ACCEPT state, meaning tarn has not yet responded
     * for accepting the case). During this time the case should not
     * be editable, and we should inform user to refresh
     * the page or wait until it is refreshed itself.
     */
    notifyAndReloadCaseIfNeeded(caseFetched) {
      if (caseStatesEditable[caseFetched.state]) {
        // If case is editable we do not need to reload or notify.
        return;
      }

      if (!this.caseInfo) {
        // If case was initially loaded.
        showInfoNotification({
          text: this.$t('notification.caseInUnEditableState', {
            state: this.$t(CaseStateToLabel[caseFetched.state]),
          }),
        });
      }

      setTimeout(() => {
        // Reload case in hope that next time tarn has responded.
        this.loadCaseData();
      }, 3000);
    },
    sendEndQuery() {
      this.loading = true;
      api.cases.sendEndQuery(this.caseId).then(() => {
        this.loading = false;
        this.$router.go();
      });
    },
    sendAcceptQuery() {
      this.loading = true;
      api.cases.sendAcceptQuery(this.caseId).then(() => {
        this.loading = false;
        this.$router.go();
      });
    },
    sendNTKVQuery() {
      this.loading = true;
      api.cases.sendNTKVQuery(this.caseId).then(() => {
        this.loading = false;
        this.$router.go(0);
      });
    },
    sendSTEQuery() {
      this.loading = true;
      api.cases.sendSTEQuery(this.caseId).then(() => {
        this.loading = false;
        this.$router.go(0);
      });
    },
    sendSyncFromTarn() {
      api.cases.syncFromTarn(this.caseId).then(() => {
        this.loading = false;
        this.$router.go(0);
      });
    },
    setStatus(status) {
      this.loading = true;
      api.cases
        .updateStatus(this.caseId, status)
        .then(() => {
          this.loading = false;
          this.$router.go(0);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateIssuerFee() {
      this.loadCaseData();
    },
    /**
     * Updates currently active problems as following:
     *   a) If relevant problems exist for case then a notice message is shown in header.
     *   b) If validation errors are found then they are also added to the notice.
     *   c) Problems tab is added/removed as needed.
     */
    async updateCurrentlyActiveProblems() {
      const problemsFoundResult = await this.fetchCurrentlyActiveProblems();

      this.updateProblemsTab(problemsFoundResult);
    },
    /**
     * Fetches currently active problems and diplays a notice about them in header.
     * Validation errors are also fetched and added to notice.
     */
    async fetchCurrentlyActiveProblems() {
      const findingValidationWarning = this.fetchValidationWarnings();
      const findingProblems = api.problem.findByCase(this.caseId, 1, MAX_PROBLEMS_COUNT + 1);

      const messages = await findingValidationWarning;
      const result = await findingProblems;

      if (result['hydra:totalItems'] > 0) {
        const trProblems = [
          ProblemType.NOT_IN_SYNC_WITH_TR_OPENED,
          ProblemType.NOT_IN_SYNC_WITH_TR_CLOSED,
          ProblemType.NOT_IN_SYNC_WITH_TR_OTHER,
        ];
        const trProblemsDetected = result['hydra:member'].filter((p) => trProblems.includes(p.type)).length > 0;

        const headerRow = trProblemsDetected
          ? 'Toimikus on probleemid. Andmete vastavus Täitmisregistriga ei ole tagatud ning toimikut pole võimalik kasutada masstegevustes.'
          : 'Toimikus on probleemid. Toimikut pole võimalik kasutada masstegevustes.'
        ;

        this.noticeMessageRows = [
          headerRow,
          'Palun lahendage probleemid või võtke vajadusel ühendust administraatoriga.',
          ...(messages.length > 0
                ? ['', ...messages]
                : []),
        ]
      } else {
        this.noticeMessageRows = [];
      }

      return result;

    },
    /**
     * Adds problems tab to header if problems have been found
     * or removes existing tab if problems have been resolved.
     */
    updateProblemsTab(problemsResult) {
      // Validation problems are ignored in count because they are already shown in header.
      // NB: Keep in sync with displaying in Problems.vue!
      const validationProblemExists = !!problemsResult['hydra:member'].find((p) => p.type === ProblemType.VALIDATION_ERRORS);
      const count = validationProblemExists
        ? problemsResult['hydra:totalItems'] - 1
        : problemsResult['hydra:totalItems'];

      const problemsItem = MENU_ITEMS.find((item) => item.url === TabUrl.PROBLEMS);
      if (!problemsItem) {
        throw new Error(`No case tab item with url "${TabUrl.PROBLEMS}" was found. Make sure such menu item is defined.`);
      }

      const getCountAsStringFn = (count, max) => {
        if (count > max) {
          return `${max}+`;
        }

        return count;
      };

      const label = 'Probleemid';
      this.menuItems = [
        ...MENU_ITEMS.filter((item) => item.url !== 'problems'),
        {
          ...problemsItem,
          name: `${label} (${getCountAsStringFn(count, MAX_PROBLEMS_COUNT)})`,
          disabled: count === 0,
        }
      ];

      const shouldProblemsTabBeRemoved = count === 0;
      if (shouldProblemsTabBeRemoved) {
        this.removeTab(label);
      }
    },
    /**
     * Current Vue Semantic UI Tab component supports reactively adding problems tab
     * when problems are found, but removing is not supported by default which
     * causes the problems tab to stay visible, even if no problems exist anymore.
     *
     * In order to remove the tab when problems are resolved and to avoid
     * reimplenting the tabs component we forcefully remove the "Probleemid"
     * tab from sui Tab component.
     *
     * Forcefully updating the SUI Tab component via component key change is
     * not a better option because that causes all case inputs to go blank for a
     * split second, which could be annoying.
     */
    removeTab(tabLabelToRemove) {
      if (!this.$refs.tabsComponent) {
        return;
      }

      this.$refs.tabsComponent.tabs = this.$refs.tabsComponent.tabs.filter((tab) => {
        if (undefined === tab.$props.title || typeof tab.$props.title !== 'string') {
          return true;
        }

        const isTabToRemove = new RegExp(tabLabelToRemove).test(tab.$props.title);
        return !isTabToRemove;
      });
    }
  },
};
</script>
<style src="./Case.css"></style>
