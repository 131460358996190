<template>
  <div class="documents">
    <h3 floated="left">FAILID</h3>
    <sui-divider clearing />

    <sui-loader :active="loading" />

    <sui-table v-if="caseDocuments.length > 0" striped>
      <sui-table-header>
        <sui-table-row>
          <sui-table-header-cell>{{ $t('name') }}</sui-table-header-cell>
          <sui-table-header-cell>{{ $t('status') }}</sui-table-header-cell>
          <sui-table-header-cell>{{ $t('created_at') }}</sui-table-header-cell>
          <sui-table-header-cell>{{ $t('type') }}</sui-table-header-cell>
          <sui-table-header-cell />
        </sui-table-row>
      </sui-table-header>

      <sui-table-body>
        <sui-table-row
          v-for="(document, index) in caseDocuments"
          :key="document.id"
        >
          <sui-table-cell>
            <a href="#" @click="download(document)">
              {{ document.name }}
            </a>
          </sui-table-cell>
          <sui-table-cell>
            <p>{{ $t(`document.document_status_${document.status}`) }}</p>
          </sui-table-cell>
          <sui-table-cell>
            <p>{{ getFormattedDateTime(document.created_at) }}</p>
          </sui-table-cell>
          <sui-table-cell>
            <Select
              :value="document.type"
              :options="documentTypes.value"
              :disabled="document.status === 0 || inTransfer"
              @input="handleTypeChange(index, $event)"
            />
          </sui-table-cell>
          <sui-table-cell>
            <div v-if="!inTransfer">
              <a
                v-if="document.status !== 0"
                href="#"
                :loading="loading"
                @click="sendDocumentRemoveReq(document.id)"
              >
                {{ $t('document.markInvalid') }}
              </a>
              <p v-else>
                {{ getDocumentStatusReason(document.status_reason) }} ({{
                  getFormattedDateTime(document.updated_at)
                }})
              </p>
            </div>
          </sui-table-cell>
        </sui-table-row>
      </sui-table-body>
    </sui-table>
    <DocumentRemoveModal
      :document-id="selectedDocumentId"
      :warning="warning"
      :open="isDocumentRemoveModalOpen"
      :scrollable="false"
      @onClosingFail="showError"
      @onClosingSuccess="markClosed"
      @onToggle="toggleDocumentRemoveModal"
    />
    <Upload
      v-if="!inTransfer"
      ref="uploadContainer"
      @onSuccess="handleFileUpload"
    />
  </div>
</template>
<script>
import api from '../../../../api';
import Select from '../../../../components/Select.vue';
import Upload from '../../../../components/Upload.vue';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../mixins/notification';
import DocumentRemoveModal from './DocumentRemoveModal.vue';
import { getFormattedDateTime } from '../../../../mixins/dateTime';
import { SettingType } from '@/static/enums/settingType';

export default {
  name: 'DocumentsTab',
  components: {
    Select,
    Upload,
    DocumentRemoveModal,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    inTransfer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    warning: null,
    isDocumentRemoveModalOpen: false,
    selectedDocumentId: null,
    caseDocuments: [],
    documentStatusReasons: [],
    documentTypes: [],
  }),
  created() {
    Promise.all([
      api.document.fetchCaseDocuments(this.caseId),
      api.settings.fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.DOCUMENT_TYPE
      ),
      api.settings.fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.DOCUMENT_STATUS_REASON
      ),
    ]).then(([documents, documentTypes, documentStatusReasons]) => {
      this.caseDocuments = documents.data;
      [this.documentTypes] = documentTypes;
      [this.documentStatusReasons] = documentStatusReasons;
    });
  },
  methods: {
    fetchDocuments() {
      api.document.fetchCaseDocuments(this.caseId).then((response) => {
        this.caseDocuments = response.data;
      });
    },
    getDocumentStatusReason(status) {
      return this.documentStatusReasons.value.find(
        (reason) => reason.key === status
      ).display_name;
    },
    async sendDocumentRemoveReq(id) {
      this.selectedDocumentId = id;
      this.toggleDocumentRemoveModal();
    },
    toggleDocumentRemoveModal() {
      this.isDocumentRemoveModalOpen = !this.isDocumentRemoveModalOpen;
    },
    markClosed() {
      this.isDocumentRemoveModalOpen = false;

      showSuccessNotification({
        title: this.$t('notification.documentMarkInvalidSuccess'),
      });
      this.fetchDocuments();
    },
    showError() {
      showErrorNotification({
        title: this.$t('notification.documentRemoveError'),
        text: this.$t('notification.documentRemoveErrorDescription'),
      });
    },
    getDocuments(ids) {
      api.document.getDocuments(ids).then((response) => {
        this.files = response.data;
      });
    },
    handleFileUpload(resp) {
      api.cases
        .update(this.caseId, {
          document_ids: [...this.caseDocuments.map((d) => d.id), resp.id],
        })
        .then(() => {
          this.fetchDocuments();
          this.$refs.uploadContainer.reset();
        });
    },
    async handleTypeChange(index, value) {
      const document = this.caseDocuments[index];
      api.document
        .updateDocument(document.id, {
          ...document,
          type: value,
        })
        .then(() => {
          showSuccessNotification({ text: this.error });
        })
        .catch((error) => {
          showErrorNotification(error);
        })
        .finally(() => {
          this.fetchDocuments();
        });
    },
    download(documentObj) {
      api.document.download(documentObj.id).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = documentObj.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getFormattedDateTime,
  },
};
</script>
<style>
.ui.loader {
  height: 1rem;
  position: relative;
  top: 1rem;
  width: 1rem;
}

.button__container {
  margin-top: 1rem;
  text-align: right;
}

.documents table {
  table-layout: fixed;
}

.documents table th:first-of-type {
  width: 25%;
}
</style>
