import CaseApi from '../../api/case';
import { CaseStatus } from '@/static/enums/caseStatus';

const FETCHING_CASES = 'FETCHING_CASES';
const FETCHING_CASES_SUCCESS = 'FETCHING_CASES_SUCCESS';
const FETCHING_CASES_ERROR = 'FETCHING_CASES_ERROR';
const UPDATING_CASE = 'UPDATING_CASE';
const UPDATING_CASE_SUCCESS = 'UPDATING_CASE_SUCCESS';
const UPDATING_CASE_ERROR = 'UPDATING_CASE_ERROR';
const REOPENING_CASE = 'REOPENING_CASE';
const REOPENING_CASE_SUCCESS = 'REOPENING_CASE_SUCCESS';
const REOPENING_CASE_ERROR = 'REOPENING_CASE_ERROR';
const TRANSFERRING_CASE = 'TRANSFERRING_CASE';
const TRANSFERRING_CASE_SUCCESS = 'TRANSFERRING_CASE_SUCCESS';
const TRANSFERRING_CASE_ERROR = 'TRANSFERRING_CASE_ERROR';
const SENDING_TO_TARN = 'SENDING_TO_TARN';
const SENDING_TO_TARN_ERROR = 'SENDING_TO_TARN_ERROR';
const SENDING_TO_TARN_SUCCESS = 'SENDING_TO_TARN_SUCCESS';
const TOGGLE_ISSUER_FEE_UPDATE_MODAL = 'TOGGLE_ISSUER_FEE_UPDATE_MODAL';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    cases: [],
    issuerFeeUpdateModalOpen: false,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isIssuerFeeUpdateModalOpen(state) {
      return state.issuerFeeUpdateModalOpen;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasCases(state) {
      return state.cases.length > 0;
    },
    cases(state) {
      return state.cases;
    },
  },
  mutations: {
    [REOPENING_CASE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [REOPENING_CASE_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [REOPENING_CASE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [UPDATING_CASE](state) {
      state.isLoading = true;
      state.error = null;
      state.cases = [];
    },
    [UPDATING_CASE_SUCCESS](state, cases) {
      state.isLoading = false;
      state.error = null;
      state.cases = cases;
    },
    [UPDATING_CASE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.cases = [];
    },
    [TRANSFERRING_CASE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [TRANSFERRING_CASE_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [TRANSFERRING_CASE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_CASES](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_CASES_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCHING_CASES_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [SENDING_TO_TARN](state) {
      state.isLoading = true;
      state.error = null;
    },
    [SENDING_TO_TARN_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [SENDING_TO_TARN_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [TOGGLE_ISSUER_FEE_UPDATE_MODAL](state) {
      state.issuerFeeUpdateModalOpen = !state.issuerFeeUpdateModalOpen;
    },
  },
  actions: {
    toggleIssuerFeeUpdateModal({ commit }) {
      commit(TOGGLE_ISSUER_FEE_UPDATE_MODAL);
    },
    async fetchAllCases({ commit }) {
      commit(FETCHING_CASES);
      try {
        const { data } = await CaseApi.fetchAll();
        commit(FETCHING_CASES_SUCCESS);
        return data;
      } catch (e) {
        commit(FETCHING_CASES_ERROR, e);
        return null;
      }
    },
    sendToTarn({ commit }, caseId) {
      commit(SENDING_TO_TARN);
      return CaseApi.sendToTarn(caseId)
        .then(() => {
          commit(SENDING_TO_TARN_SUCCESS);
        })
        .catch((error) => {
          commit(SENDING_TO_TARN_ERROR, error);
        });
    },
    async reOpen({ commit }, caseId) {
      commit(REOPENING_CASE);
      try {
        const response = await CaseApi.update(caseId, {
          status: CaseStatus.OPEN,
          close_basis: null,
          close_explanation: null,
        });
        commit(REOPENING_CASE_SUCCESS);
        return response.data;
      } catch (e) {
        commit(REOPENING_CASE_ERROR, e);
        return null;
      }
    },
    async update({ commit }, { caseId, data }) {
      commit(UPDATING_CASE);
      try {
        const response = await CaseApi.update(caseId, data);
        // TODO:: use store for fetching cases and if updated, then update in store
        commit(UPDATING_CASE_SUCCESS, []);
        return response.data;
      } catch (e) {
        commit(UPDATING_CASE_ERROR, e);
        return null;
      }
    },
    async transfer({ commit }, { caseId, personId }) {
      commit(TRANSFERRING_CASE);
      try {
        const response = await CaseApi.transfer(caseId, personId);
        commit(TRANSFERRING_CASE_SUCCESS);
        return response.data;
      } catch (e) {
        commit(TRANSFERRING_CASE_ERROR, e);
        return null;
      }
    },
  },
};
