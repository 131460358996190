import req from './lib/req';

export default {
  login(username, password) {
    return req.post('/auth/login', {
      username,
      password,
    });
  },
};
