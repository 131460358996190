<template>
  <div>
    <sui-form-fields v-for="(contact, index) in model" :key="index">
      <sui-form-field :width="14">
        <label>{{ label }}</label>
        <InputTooltip :text="contact.value">
          <Input
            v-model="contact.value"
            :disabled="disabled"
            :icon="icon"
            @input="editContact"
          />
        </InputTooltip>
      </sui-form-field>

      <sui-form-field v-if="!disabled" :width="2" class="row-actions__field">
        <div class="row-actions__container">
          <DeletePopover
            v-if="model.length > 1"
            use-icon
            @confirm="() => removeContact(index)"
          />

          <sui-button
            v-if="index === model.length - 1"
            class="row-action"
            icon="plus"
            type="button"
            @click.prevent="addContact"
          />
        </div>
      </sui-form-field>
    </sui-form-fields>
  </div>
</template>

<script>
// @flow
import DeletePopover from '../DeletePopover.vue';
import Input from '../Input.vue';
import InputTooltip from '../InputTooltip.vue';

export default {
  name: 'ContactGroup',
  components: {
    DeletePopover,
    Input,
    InputTooltip,
  },
  props: {
    contacts: {
      type: Array,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      model: [...this.contacts],
    };
  },
  created() {
    this.addContact();

    this.$watch(
      'contacts',
      (contacts) => {
        if (contacts.length > 0) {
          this.model = [...contacts];
        }
      },
      { immediate: true }
    );
  },
  methods: {
    addContact() {
      this.model.push({
        type: this.type,
      });
    },
    editContact() {
      this.$emit('update', this.model);
    },
    removeContact(index) {
      this.model.splice(index, 1);
      this.$emit('update', this.model);
    },
  },
};
</script>
