import req from '../lib/req';

export function findById(regCode, enforcementCaseId) {
  let parameters = [];
  if (!!enforcementCaseId) {
    parameters.push(`enforcementCase=${enforcementCaseId}`);
  }

  const parametersAsString = parameters.length > 0 ? `?${parameters.join('&')}` : '';
  return req.get(`/xroad/rr/${regCode}${parametersAsString}`);
}
