<template>
  <Modal :open="isOpen" size="large" @close="toggleModal">
    <template slot="modal-header"> Päring </template>
    <template slot="modal-content">
      <sui-grid v-if="query !== null" :columns="2" divided>
        <sui-grid-row>
          <sui-grid-column>
            <p>ID: {{ query.id }}</p>
          </sui-grid-column>
        </sui-grid-row>
        <sui-grid-row stretched>
          <sui-grid-column>
            <sui-segment class="query_content">
              <tree-view
                :data="JSON.parse(query.request)"
                :options="{ maxDepth: 3, rootObjectKey: 'päring' }"
              ></tree-view>
            </sui-segment>
          </sui-grid-column>
          <sui-grid-column>
            <sui-segment class="query_content">
              <tree-view
                :data="
                  query.response !== ''
                    ? JSON.parse(query.response)
                    : query.response
                "
                :options="{ maxDepth: 3, rootObjectKey: 'vastus' }"
              ></tree-view>
            </sui-segment>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
    </template>
    <template slot="modal-actions">
      <sui-button @click.native="toggleModal"> Sulge </sui-button>
    </template>
  </Modal>
</template>
<script>
import Modal from './Modal.vue';

export default {
  name: 'QueryViewModal',
  components: {
    Modal,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    queryId: {
      type: String,
      default: null,
    },
    queryType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      query: null,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
  },
  watch: {
    open() {
      if (this.open) {
        let params = {};
        if (this.queryType === 4) {
          params = {
            ...params,
            type: this.queryType,
          };
        }
        if (this.$route.query.transfer_id) {
          params = {
            ...params,
            transfer_id: this.$route.query.transfer_id,
          };
        }
        this.$store
          .dispatch('query/fetchOne', { id: this.queryId, params })
          .then((response) => {
            this.query = response;
          });
      }
    },
  },
  methods: {
    toggleModal() {
      this.$emit('onToggle');
    },
  },
};
</script>
<style>
.query_content {
  overflow: auto;
  max-height: 49vh;
}
.query_content .tree-view-wrapper {
  max-height: 45vh;
}
</style>
