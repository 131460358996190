import ClaimApi from '../../api/claim';

const FETCHING_CASES = 'FETCHING_CASES';
const FETCHING_CASES_SUCCESS = 'FETCHING_CASES_SUCCESS';
const FETCHING_CASES_ERROR = 'FETCHING_CASES_ERROR';
const UPDATING_CLAIM = 'UPDATING_CLAIM';
const UPDATING_CLAIM_SUCCESS = 'UPDATING_CLAIM_SUCCESS';
const UPDATING_CLAIM_ERROR = 'UPDATING_CLAIM_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    [FETCHING_CASES](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_CASES_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCHING_CASES_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [UPDATING_CLAIM](state) {
      state.isLoading = true;
      state.error = null;
    },
    [UPDATING_CLAIM_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [UPDATING_CLAIM_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    async fetchMainClaimsforCaseList({ commit }, caseIds) {
      if (caseIds.length === 0) {
        return null;
      }
      commit(FETCHING_CASES);
      try {
        const response = await ClaimApi.fetchMainClaimsforCaseList(caseIds);
        commit(FETCHING_CASES_SUCCESS);
        return response.data;
      } catch (e) {
        commit(FETCHING_CASES_ERROR, e);
        return null;
      }
    },
    async update({ commit }, data) {
      commit(UPDATING_CLAIM);
      try {
        const response = await ClaimApi.update(data.id, data.data);
        commit(UPDATING_CLAIM_SUCCESS);
        return response.data;
      } catch (e) {
        commit(UPDATING_CLAIM_ERROR, e);
        return null;
      }
    },
  },
};
