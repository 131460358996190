<template>
  <div>
    <div v-if="action.optionsName" class="action__options">
      <label>{{ action.optionsLabel }}</label>
      <Select
        v-model="selectedOption"
        :options="getOptions(action.optionsName)"
      />
    </div>

    <div class="action__container">
      <sui-button
        :disabled="isDisabled"
        :loading="loading"
        primary
        @click="() => handleClick(action.name)"
      >
        {{ $t(`dashboard.action.${action.name}`) }}
      </sui-button>

      <div v-if="action.info" class="action__info">
        <Tooltip :text="$t(`dashboard.action.${action.info}`)" />
      </div>
    </div>
  </div>
</template>

<script>
import Select from '../../components/Select.vue';
import Tooltip from '../../components/Tooltip.vue';
import { Actions } from '../../static/enums/case';

export default {
  name: 'Action',
  components: {
    Select,
    Tooltip,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    noCasesSelected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      closeTemplates: [],
    };
  },
  computed: {
    isDisabled: {
      get() {
        return (
          (this.noCasesSelected &&
            this.action.name !== Actions.FIND_CASES.name &&
            this.action.name !== Actions.FIND_TARN_CASES.name &&
            this.action.name !== Actions.FIND_END_CASES.name &&
            this.action.name !== Actions.REFRESH_MANUAL_ARREST.name &&
            this.action.name !== Actions.REFRESH_ARREST_REMOVE.name &&
            this.action.name !== Actions.REFRESH_AWAITING_ARREST.name) ||
          this.loading ||
          (this.action.name === Actions.REFUSE_TARN_CASE.name &&
            this.action.disabled)
        );
      },
    },
  },
  methods: {
    getOptions(name) {
      const setting = this.$store.getters[`settings/${name}`];
      return setting ? setting.value : [];
    },
    handleClick(name) {
      this.$emit(
        'actionClick',
        name,
        !!this.action.optionsName,
        this.selectedOption
      );
    },
  },
};
</script>

<style scoped>
.action__container {
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
}

.action__info {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
}

.action__options {
  margin-bottom: 0.5rem;
}

.ui.button,
.ui.selection.dropdown {
  margin-right: 0;
  width: 100%;
}

.no-filter {
  opacity: 0.8;
  padding: 1.5rem 0;
}
</style>
