import {
  convert,
  DateTimeFormatter,
  nativeJs,
  ZonedDateTime,
  LocalDateTime,
  ZoneId,
} from 'js-joda';

const DATE_FORMATTER = DateTimeFormatter.ofPattern('dd.MM.yyyy');
const DATE_TIME_FORMATTER = DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm');

export function getCurrentDateTime() {
  return ZonedDateTime.now().toString().replace('[SYSTEM]', '');
}
export function getFormattedDateTimeWithMidnight(dateTimeString) {
  if (!dateTimeString) return '';

  let zonedDateTime = null;

  try {
    zonedDateTime = ZonedDateTime.parse(dateTimeString).withZoneSameInstant(
      ZoneId.systemDefault()
    );
  } catch (e) {
    return dateTimeString;
  }
  return zonedDateTime
    .withHour(23)
    .withMinute(59)
    .withSecond(59)
    .toString()
    .replace('[SYSTEM]', '');
}
export function getFormattedDateTime(dateTime) {
  if (!dateTime) return '';

  let zonedDateTime = null;

  try {
    zonedDateTime = ZonedDateTime.parse(dateTime).withZoneSameInstant(
      ZoneId.systemDefault()
    );
  } catch (e) {
    return dateTime;
  }
  return zonedDateTime.format(DATE_TIME_FORMATTER);
}

export function getZonedDateTime(dateTime) {
  if (!dateTime) return '';

  let zonedDateTime = null;

  try {
    zonedDateTime = ZonedDateTime.parse(dateTime);
  } catch (e) {
    return dateTime;
  }
  return zonedDateTime.format(DATE_TIME_FORMATTER);
}

export function formatDateTime(dateTime) {
  if (!dateTime) return '';

  let newDateTime = null;

  try {
    newDateTime = LocalDateTime.parse(dateTime);
  } catch (e) {
    return dateTime;
  }

  return newDateTime.format(DATE_TIME_FORMATTER);
}

export function getFormattedDate(date, calendar = false) {
  if (!date) return '';

  let zonedDateTime = null;

  try {
    zonedDateTime = ZonedDateTime.parse(date);
  } catch (e) {
    return date;
  }

  if (calendar) {
    return convert(zonedDateTime).toDate();
  }

  return zonedDateTime.format(DATE_FORMATTER);
}

export function getDateInRfc3339(date) {
  if (!date) return '';

  let zonedDateTime = null;

  try {
    zonedDateTime = ZonedDateTime.from(nativeJs(date));
  } catch (e) {
    return date;
  }

  return zonedDateTime.toString().replace('[SYSTEM]', '');
}
