import { i18n } from '../app';
import { CaseStatus } from '../static/enums/caseStatus';

export function getStatusText(status) {
  if (status === CaseStatus.OPEN) {
    return i18n.t('case.status.open');
  }

  if (status === CaseStatus.CLOSED) {
    return i18n.t('case.status.closed');
  }

  if (status === CaseStatus.ARCHIVED) {
    return i18n.t('case.status.archived');
  }

  if (status === CaseStatus.STOPPED) {
    return i18n.t('case.status.stopped');
  }

  if (status === CaseStatus.TARN) {
    return i18n.t('case.status.tarn');
  }

  if (status === CaseStatus.REJECTED) {
    return i18n.t('case.status.rejected');
  }

  if (status === CaseStatus.PARTIALLY_CLOSED) {
    return i18n.t('case.status.partially_closed');
  }

  return '';
}
