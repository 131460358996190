<template>
  <div>
    <div class="choose-role--container">
      <h3 v-if="hasUser">Tere, {{ user.name }}</h3>
      <h5>Vali Roll</h5>

      <div v-if="user" class="choose-role--list">
        <div
          v-for="role in user.roles"
          :key="role.tenant_id"
          class="role--item"
          @click="chooseRole(role.tenant_id)"
        >
          <p class="role--role">{{ role.tenant_code }} - {{ role.role }}</p>
          <p class="role--name">
            {{ role.tenant_name }}
          </p>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import pageNameSync from '../../mixins/pageNameSync';
import { SET_TENANT_ID } from '@/store/modules/auth';

export default {
  mixins: [pageNameSync],
  data() {
    return {
      roles: {},
    };
  },
  computed: {
    ...mapGetters('user', ['user', 'hasUser', 'isLoading', 'error']),
  },
  async created() {
    if (!this.hasUser) {
      await this.$store.dispatch(
        'user/fetchUser',
        localStorage.getItem('user-id')
      );
    }
  },
  methods: {
    async chooseRole(tenantId) {
      await this.$store.dispatch('user/setRole', tenantId);
      this.$store.commit(`auth/${SET_TENANT_ID}`, tenantId);
      if (!this.$store.getters['user/hasError']) {
        await this.$store.dispatch('user/fetchUser', this.user.id);
        this.$router.push({ name: 'Dashboard' });
      }
    },
  },
};
</script>
<style>
.choose-role--list {
  margin-top: 1rem;
  overflow-y: auto;
  height: 50rem;
}
.choose-role--container {
  width: 25rem;
  margin: 0 auto;
  margin-top: 2rem;
  overflow: auto;
}
.role--role {
  font-size: 1.33rem;
  margin: 0;
}
.role--name {
  margin: 0;
}
.role--item {
  position: relative;
}
.role--item:hover {
  cursor: pointer;
  background: #f5f5f5;
}
.role--item:hover > .role--icon {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
</style>
