import { BulkActionType } from '@/static/enums/bulkActionType';

/**
 * Map of labels to translate a bulk action's type into a human readable form.
 */
export const BulkActionTypeToLabel: Record<BulkActionType, string> = {
  [BulkActionType.SET_START_FEE]: 'bulkActionType.setStartFee',
  [BulkActionType.CLOSE_CASE]: 'bulkActionType.closeCase',
  [BulkActionType.COMPUTE_NEW_ISSUER_FEE]: 'bulkActionType.computeNewIssuerFee',
  [BulkActionType.REMOVE_ARREST_PREPARE]: 'bulkActionType.removeArrestPrepare',
  [BulkActionType.REMOVE_ARREST_CONFIRM]: 'bulkActionType.removeArrestConfirm',
  [BulkActionType.SET_ARREST_PREPARE]: 'bulkActionType.setArrestPrepare',
  [BulkActionType.SET_ARREST_CONFIRM]: 'bulkActionType.setArrestConfirm',
  [BulkActionType.VALIDATE_CASE]: 'bulkActionType.validateCase',
  [BulkActionType.SYNC_CASE_TO_TARN]: 'bulkActionType.syncCaseToTarn',
};
