import api from '../../api';
import BankAPI from '../../api/bank';

const FETCHING_BANKS = 'FETCHING_BANKS';
const FETCHING_BANKS_SUCCESS = 'FETCHING_BANKS_SUCCESS';
const FETCHING_BANKS_ERROR = 'FETCHING_BANKS_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    banks: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasBanks(state) {
      return state.banks.length > 0;
    },
    banks(state) {
      return state.banks;
    },
  },
  mutations: {
    [FETCHING_BANKS](state) {
      state.isLoading = true;
      state.error = null;
      state.banks = [];
    },
    [FETCHING_BANKS_SUCCESS](state, banks) {
      state.isLoading = false;
      state.error = null;
      state.banks = banks;
    },
    [FETCHING_BANKS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.banks = [];
    },
  },
  actions: {
    async fetch({ commit }) {
      commit(FETCHING_BANKS);
      try {
        const response = BankAPI.findAll();
        commit(FETCHING_BANKS_SUCCESS, response.data.items);
        return response.data;
      } catch (e) {
        commit(FETCHING_BANKS_ERROR, e);
        return null;
      }
    },

    getBanks({ commit }) {
      commit(FETCHING_BANKS);
      return api.banks.find().then((banks) => {
        commit(FETCHING_BANKS_SUCCESS, banks);
        return banks;
      });
    },
  },
};
