<template>
  <Modal :open="isOpen" size="small" :scrollable="false" @close="toggleModal">
    <template slot="modal-header">
      {{ $t('case.transfer') }}
    </template>

    <template slot="modal-content">
      <sui-message v-if="warning" negative dismissable @dismiss="clearWarning">
        {{ warning }}
      </sui-message>
      <sui-dimmer v-if="isLoading">
        <sui-loader v-if="isLoading" />
      </sui-dimmer>
      <div v-if="!isLoading">
        <sui-grid :columns="2" divided>
          <sui-grid-row>
            <sui-grid-column :width="12">
              <sui-form>
                <sui-form-field>
                  <label>Ülevõttev täitur</label>
                  <sui-dropdown
                    v-model="caseTransfer.person_receiving"
                    :options="persons"
                    placeholder="Vali"
                    fluid
                    search
                    selection
                  />
                </sui-form-field>
                <sui-form-field>
                  <label>Põhjus</label>
                  <sui-dropdown
                    v-model="caseTransfer.reason"
                    :options="transferReasonList"
                    placeholder="Vali"
                    fluid
                    search
                    selection
                  />
                </sui-form-field>
                <sui-form-field>
                  <label>Märkus</label>
                  <Input
                    v-model="caseTransfer.description"
                    icon="file alternate outline"
                  />
                </sui-form-field>
              </sui-form>
            </sui-grid-column>
            <sui-grid-column :width="4">
              <sui-form-field>
                <sui-dropdown
                  v-model="caseToAdd"
                  :options="caseList"
                  :loading="isCaseListLoading"
                  placeholder="Lisa"
                  required
                  fluid
                  search
                  selection
                  @input="addCase"
                />
              </sui-form-field>
              <sui-list link class="case-list-column">
                <div
                  v-for="(enforcementCase, index) in cases"
                  :key="enforcementCase.id"
                >
                  <a
                    is="sui-list-item"
                    :href="`/case/${enforcementCase.id}`"
                    target="_blank"
                  >
                    {{ enforcementCase.number }}
                  </a>
                  <sui-icon name="close" @click="removeCaseFromList(index)" />
                </div>
              </sui-list>
            </sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <p v-else>
        {{ transferProgressText }}
      </p>
    </template>
    <template slot="modal-actions">
      <sui-button @click.native="toggleModal"> Katkesta </sui-button>
      <sui-button :loading="isLoading" primary @click.native="transferCase()">
        {{ $t('case.transfer') }}
      </sui-button>
    </template>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex';
import Modal from '../Modal.vue';
import Input from '../Input.vue';
import api from '../../api';
import {
  showErrorNotification,
  showSuccessNotification,
} from '@/mixins/notification';
import { SettingType } from '@/static/enums/settingType';
import { CaseTransferStatus } from '@/static/enums/caseTransferStatus';
import { ColumnField } from '@/api/caseTable/ColumnField';
import { Order } from '@/types/DataTable/Order';

/**
 * Fetch all case items of given filters.
 */
async function fetchAllCaseItems(filters) {
  let caseItems = [];

  const limit = 5000;
  let page = 1;

  const { 'hydra:member': cases } = await api.caseTable.findCasesPaged({
    columns: [ColumnField.ID, ColumnField.NUMBER],
    limit,
    page,
    sort: [{ field: ColumnField.NUMBER, order: Order.ASCENDING }],
    filters,
  });

  caseItems = [...caseItems, ...cases];

  let pagesLeft = true;
  for (; pagesLeft === true; ) {
    page += 1;

    const { 'hydra:member': cases } = await api.caseTable.findCasesPaged({
      columns: [ColumnField.ID, ColumnField.NUMBER],
      limit,
      page,
      sort: [{ field: ColumnField.NUMBER, order: Order.ASCENDING }],
      filters,
    });

    caseItems = [...caseItems, ...cases];

    if (cases.length === 0) {
      // Last page was empty, meaning to pages of cases are left.
      pagesLeft = false;
    }
  }

  return caseItems;
}

export default {
  name: 'TransferCaseModal',
  components: { Input, Modal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: String,
      default: null,
    },
    cases: {
      type: Array,
      required: true,
    },
    /**
     * Filters of V2 case table. These are required to ensure only
     * currently filtered cases are shown in modal.
     */
    filters: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      caseToAdd: null,
      caseList: [],
      loading: false,
      transferReasonList: [],
      caseTransfer: {
        person_receiving: null,
        description: null,
        reason: null,
        cases: this.cases,
        status: CaseTransferStatus.STATUS_CREATING,
      },
      persons: [],
      isCaseListLoading: false,
      // Progress value of cases being sent for transfer. Ranges 0-1 as float.
      transferProgressValue: 0,
    };
  },
  computed: {
    ...mapGetters('cases', ['hasError', 'error']),
    isLoading() {
      return (
        this.$store.getters['cases/isLoading'] ||
        this.$store.getters['person/isLoading'] ||
        this.loading
      );
    },
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
    transferProgressPercentage() {
      return Math.round(this.transferProgressValue * 1000) / 10;
    },
    transferProgressText() {
      return `Üleandmise algatamine.. ${this.transferProgressPercentage}%`;
    },
  },
  watch: {
    open() {
      if (this.isOpen) {
        this.fetchCasesList();

        this.$store.dispatch('person/fetchKTs').then((response) => {
          this.persons = [];
          response.map((person) => {
            this.persons.push({
              text: `${person.value}`,
              value: person.id,
            });
            return person;
          });
        });
        this.fetchTransferReasons();
      }
    },
  },
  methods: {
    fetchTransferReasons() {
      api.settings
        .fetchByType(
          this.$store.getters['auth/tenant_id'],
          SettingType.TRANSFER_REASON
        )
        .then(([response]) => {
          if (response.length === 0) {
            return;
          }

          this.transferReasonList = response.value
            .filter((reason) => reason.active)
            .map((reason) => ({
              text: reason.display_name,
              value: reason.key,
            }));
        });
    },
    async transferCase() {
      this.loading = true;

      // Array of id groups to be added to transfer dynamically.
      let caseIdsToTransferPaged = [];
      // Patch size for case ids to be added in one request.
      const caseIdsPageSize = 100;

      let page = 0;
      let counter = 0;
      const caseIdsToTransfer = this.cases.map((ecase) => ecase.id);
      for (let i = 0; i < caseIdsToTransfer.length; i++) {
        const id = caseIdsToTransfer[i];

        caseIdsToTransferPaged[page] = [
          ...(caseIdsToTransferPaged[page] || []),
          id,
        ];
        counter += 1;

        if (counter === caseIdsPageSize) {
          page += 1;
          counter = 0;
        }
      }

      try {
        // Initially we create the transfer without cases, and then start
        // adding cases to it dynamically to avoid timeouts.
        this.caseTransfer.case_ids = [];
        let transfer = (await api.caseTransfer.create(this.caseTransfer)).data;

        for (let page = 0; page < caseIdsToTransferPaged.length; page++) {
          await api.caseTransfer.addCasesToTransfer(
            transfer.id,
            caseIdsToTransferPaged[page]
          );

          this.transferProgressValue = (page + 1) / caseIdsToTransferPaged.length;
        }

        // Make transfer visible to receiving bailiff side.
        await api.caseTransfer.updateStatus(
          transfer.id,
          CaseTransferStatus.STATUS_PENDING
        );

        showSuccessNotification({ text: 'Toimikud saadetud üleandmiseks' });
        this.caseTransfer = {
          person_receiving: null,
          description: null,
          reason: null,
          cases: this.cases,
        };
        this.$emit('onTransferSuccess');
      } catch (error) {
        this.$emit('onTransferFail', error);
        showErrorNotification({ text: error });
      } finally {
        this.transferProgressValue = 0;
        this.loading = false;
      }
    },
    toggleModal() {
      this.$emit('onToggle');
    },
    clearWarning() {
      this.warning = null;
    },
    removeCaseFromList(index) {
      this.cases.splice(index, 1);
    },
    addCase(id) {
      // check if exists
      const existing = this.cases.filter((ecase) => ecase.id === id);
      if (existing.length > 0) {
        this.caseToAdd = null;
        showErrorNotification({ text: 'Selline toimik on juba nimekirjas' });
        return;
      }
      const caseToAdd = this.caseList.filter((ecase) => ecase.value === id);
      this.cases.push({ id: caseToAdd[0].value, number: caseToAdd[0].text });
      this.caseToAdd = null;
    },
    async fetchCasesList() {
      this.caseList = [];
      this.cases = [];
      this.isCaseListLoading = true;

      try {
        if (this.filters) {
          const caseItems = await fetchAllCaseItems(this.filters);

          caseItems.forEach((item) => {
            if (!item.number) {
              return;
            }

            this.caseList.push({
              text: `${item.number}`,
              value: item.id,
            });

            if (this.filters.caseSelection !== null) {
              // If case selection is active then also
              // add the case to selected cases list.
              this.cases.push(item);
            }
          });
        } else {
          const response = await this.$store.dispatch('cases/fetchAllCases');

          response.items.map((item) => {
            this.caseList.push({
              text: `${item.number}`,
              value: item.id,
            });
            return item;
          });
        }
      } finally {
        this.isCaseListLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.case-list-column {
  overflow-y: auto;
  max-height: 20vh;
}

::v-deep {
  .ui.loading.selection.dropdown > i.icon {
    // To align loading icon.
    padding: 0.9375rem 1rem !important;
  }
}
</style>
