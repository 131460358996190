import AuthApi from '../../api/auth';

const AUTHENTICATING = 'AUTHENTICATING';
const AUTHENTICATING_SUCCESS = 'AUTHENTICATING_SUCCESS';
const AUTHENTICATING_ERROR = 'AUTHENTICATING_ERROR';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_TENANT_ID = 'SET_TENANT_ID';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    token: null,
    tenant_id: null,
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    isLoading: (state) => state.isLoading,
    hasError: (state) => state.error !== null,
    error: (state) => state.error,
    token: (state) => state.token,
    tenant_id: (state) => state.tenant_id,
  },
  mutations: {
    [AUTHENTICATING](state) {
      state.isLoading = true;
      state.error = null;
      state.token = null;
      state.tenant_id = null;
    },
    [AUTHENTICATING_SUCCESS](state, data) {
      state.isLoading = false;
      state.error = null;
      state.token = data.token;
      state.tenant_id = data.tenant_id;
    },
    [AUTHENTICATING_ERROR](state, error) {
      state.isLoading = false;
      state.error = error.message;
      state.token = null;
      state.tenant_id = null;
    },
    [SET_TOKEN](state, token) {
      state.token = token;
    },
    [SET_TENANT_ID](state, tenant_id) {
      state.tenant_id = tenant_id;
    },
  },
  actions: {
    async login({ commit }, payload) {
      commit(AUTHENTICATING);
      try {
        const response = await AuthApi.login(
          payload.username,
          payload.password
        );
        commit(AUTHENTICATING_SUCCESS, response.data);
        localStorage.setItem('user-id', response.data.id);
        return response.data;
      } catch (error) {
        commit(AUTHENTICATING_ERROR, error);
        localStorage.clear();
        localStorage.removeItem('vuex');
        commit(SET_TOKEN, '');
        return null;
      }
    },
    async auth({ commit }, data) {
      commit(AUTHENTICATING);
      try {
        if (!data.token || !data.id) {
          commit(AUTHENTICATING_ERROR, 'Token puudub');
          commit(SET_TOKEN, null);
          commit(SET_TENANT_ID, null);
          localStorage.clear();
          localStorage.removeItem('vuex');
          return null;
        }

        commit(AUTHENTICATING_SUCCESS, data);
        return data;
      } catch (e) {
        commit(AUTHENTICATING_ERROR, e);
        localStorage.clear();
        localStorage.removeItem('vuex');
        commit(SET_TOKEN, null);
        return null;
      }
    },
  },
};
