import req from './lib/req';

export default {
  fetchAll() {
    return req.get('/query');
  },
  fetchByParams(params) {
    return req.get('/query', {
      params,
    });
  },
  getCount(params) {
    return req.get('/query/count', {
      params,
    });
  },
  fetchOne(id, params) {
    return req.get(`query/${id}`, { params });
  },
  findByIds(idList) {
    return req.get('/query', {
      params: {
        id: idList,
      },
    });
  },
};
