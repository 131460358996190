import req from '../lib/req';

export function getAll() {
  return req.get('/cron');
}

export function execute(command) {
  return req.post(`/cron/${command.id}/execute`);
}
export function reopen(command) {
  return req.post(`/cron/${command.id}/reopen`);
}
