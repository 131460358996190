import { ArrestState } from '@/static/enums/arrestState';

/**
 * Separate 'error' state enum that handles finding cases where there has been
 * some error while creating/updating/removing an arrest. This is not actually
 * an ArrestState used in arrest's lifecycle, but is a helping term defined and
 * used in CaseArrestsFilter.php!
 */
enum CaseArrestsFilterState {
  ERROR = 'error',
}

/**
 * Combined "enum" of values from ArrestState and CaseArrestsFilterState.
 */
export const CaseArrestsFilterStates = {
  ...ArrestState,
  ...CaseArrestsFilterState,
};
