import VueI18n from 'vue-i18n';

import { ComparisonType } from '@/components/DateFilterPicker/ComparisonType';

/**
 * Type for option to be used for selecting the desired comparison operator.
 */
export type ComparisonOption = {
  label: string;
  type: ComparisonType;
};

/**
 * Returns comparison operator options for DateFilterPicker.
 * You can see ComparisonType.ts for more details on how each type should act.
 */
export function getComparisonOptions(i18n: VueI18n): ComparisonOption[] {
  return [
    {
      label: i18n.t('dateFilterPicker.comparison.beforeStrictly').toString(),
      type: ComparisonType.EARLIER_THAN,
    },
    {
      label: i18n.t('dateFilterPicker.comparison.afterStrictly').toString(),
      type: ComparisonType.LATER_THAN,
    },
    {
      label: i18n.t('dateFilterPicker.comparison.dateRange').toString(),
      type: ComparisonType.DATE_RANGE,
    },
    {
      label: i18n.t('dateFilterPicker.comparison.dateMissing').toString(),
      type: ComparisonType.DATE_MISSING,
    },
    {
      label: i18n.t('dateFilterPicker.comparison.dateEqualTo').toString(),
      type: ComparisonType.DATE_EQUAL_TO,
    },
  ];
}
