import req from '../lib/req';

/**
 * @deprecated - Use API call functions (or add new ones) from /api/modules/classifierValue.ts instead.
 */
export function findByClassifier(classifier) {
  const params = {
    classifier,
    active: 1,
  };
  return req.get('/classifier-value', {
    params: {
      where: JSON.stringify(params),
      'order[order]': 'ASC',
    },
  });
}

/**
 * @deprecated - Use API call functions (or add new ones) from /api/modules/classifierValue.ts instead.
 */
export function findById(id) {
  return req.get(`/classifier-value/${id}`);
}

/**
 * @deprecated - Use API call functions (or add new ones) from /api/modules/classifierValue.ts instead.
 */
export function remove(classifier) {
  return req.delete(`/classifier-value/${classifier.id}`);
}
