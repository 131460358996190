import req from '../lib/req';
import { Id } from '@/types/Id';
import { CaseSelection } from '@/types/CaseSelection';
import { Single } from '@/types/ApiPlatform/Single';

/**
 * Creates a case selection entity.
 *
 * Note that this does not relate the selection to any cases,
 * use addCases for that.
 *
 * @return Id of created case selection.
 */
export async function create(): Promise<CaseSelection['id']> {
  return req
    .post('/v2/case_selections', {})
    .then(({ data }: { data: Single<CaseSelection> }) => data.id);
}

/**
 * Updates an existing case selection entity of given id,
 * by adding more cases to the selection of given numbers.
 */
export async function addCases(id: Id, caseNumbers: string[]): Promise<void> {
  return req.put(`/v2/case_selections/${id}/add_cases`, {
    caseNumbers,
  });
}

export async function remove(id: Id): Promise<void> {
  return req.delete(`/v2/case_selections/${id}`);
}
