import SolutionTypeAPI from '../../api/solutionType';

const FETCHING_SOLUTIONTYPE = 'FETCHING_SOLUTIONTYPE';
const FETCHING_SOLUTIONTYPE_SUCCESS = 'FETCHING_SOLUTIONTYPE_SUCCESS';
const FETCHING_SOLUTIONTYPE_ERROR = 'FETCHING_SOLUTIONTYPE_ERROR';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    solutionType: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasSolutionType(state) {
      return state.solutionType !== null;
    },
    solutionType(state) {
      return state.solutionType;
    },
  },
  mutations: {
    [FETCHING_SOLUTIONTYPE](state) {
      state.isLoading = true;
      state.error = null;
      state.solutionType = null;
    },
    [FETCHING_SOLUTIONTYPE_SUCCESS](state, solutionType) {
      state.isLoading = false;
      state.error = null;
      state.solutionType = solutionType;
    },
    [FETCHING_SOLUTIONTYPE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.solutionType = null;
    },
  },
  actions: {
    async findId({ commit }, solutionTypeId) {
      commit(FETCHING_SOLUTIONTYPE);
      try {
        const response = await SolutionTypeAPI.find(solutionTypeId);
        commit(FETCHING_SOLUTIONTYPE_SUCCESS, response.data);
        return response.data;
      } catch (e) {
        commit(FETCHING_SOLUTIONTYPE_ERROR, e);
        return null;
      }
    },
    async findByCaseId({ commit }, caseId) {
      commit(FETCHING_SOLUTIONTYPE);
      try {
        const response = await SolutionTypeAPI.findByCaseId(caseId);
        commit(FETCHING_SOLUTIONTYPE_SUCCESS, response.data);
        return response.data;
      } catch (e) {
        commit(FETCHING_SOLUTIONTYPE_ERROR, e);
        return null;
      }
    },
  },
};
