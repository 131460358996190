import axios from 'axios';
import { showErrorNotification } from '@/mixins/notification';
import store from '../../store';
import router from '../../router';
import { i18n } from '@/app';
import { SET_TOKEN } from '@/store/modules/auth';
import { ConstraintViolationList } from '@/static/enums/HydraType';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

const isLdJSONResponse = (error: unknown) => {
  // @ts-ignore
  if (!error.response || !error.response.headers) {
    return false;
  }
  return (
    // @ts-ignore
    error.response.headers['content-type'] ===
    'application/ld+json; charset=utf-8'
  );
};

const handleLdJSONError = (error: unknown) => {
  // @ts-ignore
  const data = error.response.data;
  if (data['@type'] === ConstraintViolationList) {
    data.violations.forEach((item: unknown) => {
      showErrorNotification({
        // @ts-ignore
        text: item.message,
      });
    });
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

const errorHandler = (error: unknown) => {
  if (isLdJSONResponse(error)) {
    return handleLdJSONError(error);
  }
  // @ts-ignore
  if (!error.response) {
    return Promise.reject(error);
  }
  // @ts-ignore
  if (error.response.status)
      // @ts-ignore
    if (error.response.status === 401) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      error.response.data.message = 'Palun logige uuesti sisse';
      store.dispatch('user/signOut').finally(() => {
        localStorage.clear();
        router.push({ name: 'SignIn' });
      });

      return Promise.reject(error);
    }
  // @ts-ignore
  if (error.response.status === 403) {
    showErrorNotification({
      text: 'Puuduvad piisavad õigused',
    });
    return Promise.reject(error);
  }
  // @ts-ignore
  if (error.response.data.message !== undefined) {
    // @ts-ignore
    let { message } = error.response.data;
    try {
      let newMessage = '';

      message = Object.entries(JSON.parse(message));
      message.forEach((item: string) => {
        newMessage = `${newMessage}\n${i18n.t(item[0])}:${item[1]}`;
      });
      showErrorNotification({
        text: newMessage,
      });
      return Promise.reject(newMessage);
    } catch (e) {
      console.error(e);
    }
    showErrorNotification({
      text: message,
    });
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

const responseHandler = (response: unknown) => {
  // @ts-ignore
  if (response.headers['refresh-token']) {
    // @ts-ignore
    store.commit(`auth/${SET_TOKEN}`, response.headers['refresh-token']);
    store.dispatch('user/verify');
  }

  return response;
};

instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
instance.interceptors.request.use((reqConfig) => {
  const token = store.getters['auth/token'];
  if (token) {
    // eslint-disable-next-line no-param-reassign
    reqConfig.headers.Authorization = `Bearer ${token}`;
  }
  return reqConfig;
});
export default instance;
