const module = {
  strict: true,
  namespaced: true,
  state: {
    pageName: '',
    search: '',
    isSidenavOpen: localStorage.getItem('isSidenavOpen') === 'true',
  },
  getters: {
    isSidenavOpen: (state) => state.isSidenavOpen,
    pageName: (state) => state.pageName,
    search: (state) => state.search,
  },
  mutations: {
    addPageName(state, name) {
      state.pageName = name;
      document.title = name;
    },
    toggleSidenav(state) {
      const pos = !state.isSidenavOpen;

      state.isSidenavOpen = pos;
      localStorage.setItem('isSidenavOpen', pos.toString());
    },
    updateSearch(state, value) {
      state.search = value;
    },
  },
  actions: {
    changePageName({ commit }, name) {
      commit('addPageName', name);
    },
  },
};

export default module;
