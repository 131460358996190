var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bulk-action-task-actions"},[(_vm.displayRetryButton)?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.$t('bulkActionTaskActions.retryTask').toString()),expression:"$t('bulkActionTaskActions.retryTask').toString()",modifiers:{"bottom":true}}],class:{
      'p-button-warning': true,
      'bulk-action-task-actions__button': true,
      'bulk-action-task-actions__button--hidden': _vm.value.status === _vm.BulkActionStatus.WIP,
    },attrs:{"icon":_vm.isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-replay',"disabled":_vm.isLoading},on:{"click":function () { return _vm.openArgumentsEditModal(_vm.value); }}}):_vm._e(),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.$t('bulkActionTaskActions.createCaseSelectionFromCase').toString()),expression:"$t('bulkActionTaskActions.createCaseSelectionFromCase').toString()",modifiers:{"bottom":true}}],class:{
      'p-button-primary': true,
      'bulk-action-task-actions__button': true,
      'bulk-action-task-actions__button--hidden':
        _vm.value.status === _vm.BulkActionStatus.WIP,
    },attrs:{"disabled":_vm.isLoading},on:{"click":function () { return _vm.createNewCaseSelection(_vm.value); }}},[(_vm.isLoading)?[_c('i',{staticClass:"pi pi-spin pi-spinner bulk-action-task-actions__icon-spinner"})]:[_c('sui-icon',{staticClass:"bulk-action-task-actions__icon-briefcase",attrs:{"name":"briefcase"}})]],2),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.$t('bulkActionTaskActions.markAsDone').toString()),expression:"$t('bulkActionTaskActions.markAsDone').toString()",modifiers:{"bottom":true}}],class:{
      'p-button-success': true,
      'bulk-action-task-actions__button': true,
      'bulk-action-task-actions__button--hidden':
        _vm.value.status !== _vm.BulkActionStatus.FAILED,
    },attrs:{"icon":_vm.isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check',"disabled":_vm.isLoading},on:{"click":function () { return _vm.markTaskAsDone(_vm.value); }}}),(_vm.bulkActionTaskToEdit)?_c('BulkActionArgsModal',{attrs:{"type":_vm.bulkActionTaskToEdit.type,"is-loading":_vm.isLoading,"bulk-action-task-to-edit":_vm.bulkActionTaskToEdit,"display-problem-notice":false,"total-cases-count":1},on:{"confirmUpdated":_vm.updateAndRetryTask,"close":_vm.closeArgumentsEditModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }