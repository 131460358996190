/**
 * Enum values for statuses of BulkActions or BulkActionTasks.
 * NB: Same values as in Entity/BulkAction.php! Keep in sync.
 */
export enum BulkActionStatus {
  WIP = 1,
  OK = 2,
  FAILED = 3,
  CANCELLED = 4,
}
