<template>
  <Modal :open="isOpen" size="mini" :scrollable="false" @close="toggleModal">
    <template slot="modal-header">
      {{ $t('document.markInvalid') }}
    </template>

    <template slot="modal-content">
      <sui-message v-if="warning" negative dismissable @dismiss="clearWarning">
        {{ warning }}
      </sui-message>

      <sui-form>
        <sui-form-field>
          <label>Kehtetuks määramise alus</label>
          <sui-dropdown
            v-model="documentStatusReason"
            :options="documentStatusReasonsList"
            search
            selection
          />
        </sui-form-field>
      </sui-form>
    </template>

    <template slot="modal-actions">
      <sui-button @click.native="toggleModal"> Katkesta </sui-button>
      <sui-button :loading="loading" primary @click.native="removeDocument">
        {{ $t('document.markInvalid') }}
      </sui-button>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '../../../../api';
import Modal from '../../../../components/Modal.vue';

export default {
  name: 'DocumentRemoveModal',
  components: {
    Modal,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: String,
      default: null,
    },
    documentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      documentStatusReason: 0,
    };
  },
  computed: {
    ...mapGetters('settings', ['documentStatusReasons']),
    documentStatusReasonsList: {
      get() {
        if (!this.documentStatusReasons) return [];

        return this.documentStatusReasons.value.map((status) => ({
          text: status.display_name,
          value: status.key,
        }));
      },
    },
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
  },
  methods: {
    clearWarning() {
      this.warning = null;
    },
    removeDocument() {
      this.loading = true;
      if (this.documentStatusReason === null) {
        this.$emit('onClosingFail');
      }
      api.document
        .updateDocument(this.documentId, {
          status_reason: this.documentStatusReason,
          status: 0,
        })
        .then(() => {
          this.$emit('onClosingSuccess');
        })
        .catch(() => {
          this.$emit('onClosingFail');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleModal() {
      this.$emit('onToggle');
    },
  },
};
</script>
