<template>
  <div
    :data-position="position"
    :data-tooltip="text"
    class="ui icon button"
    data-inverted
  >
    <sui-icon name="question circle outline" />
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    position: {
      type: String,
      default: 'top right',
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.ui.button[data-tooltip] {
  background-color: transparent;
  margin: 0;
  padding: 3px 13px;
}

[data-tooltip]:after {
  white-space: normal;
  width: 300px;
}
</style>
