/**
 * Forces the browser to download the contents of given string as file of given MIME type.
 *
 * The solution should not be used for large files, because it loads the response
 * contents into memory.
 */
export function downloadTextAsFile(text: string, filename: string, type: string): void {
  const link = document.createElement('a');

  // Adding UTF-8 BOM (byte-order-mark) identifier to help suggest which encoding
  // should be used when opening the file.
  //
  // https://stackoverflow.com/a/42466254
  // https://stackoverflow.com/a/41363077
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), text], {
    type: `${type};charset=utf-8`,
  });
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  link.dispatchEvent(new MouseEvent('click'));
}
