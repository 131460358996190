// @flow
import Upload from '../../../../components/Upload.vue';

export default {
  name: 'DocumentsTab',
  components: {
    Upload,
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    handleFileUpload(id) {
      this.files.push(id);
      this.$emit('update', this.files);
    },
    handleFileRemove(id) {
      const index = this.files.findIndex((fileId) => fileId === id);

      if (index > -1) {
        this.files.splice(index, 1);
        this.$emit('update', this.files);
      }
    },
  },
};
