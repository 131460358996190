<template>
  <vue-dropzone
    id="dropzone-top"
    :options="dropzoneOptions"
    class="dropzone"
    @vdropzone-removed-file="handleRemove"
  />
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { mapGetters } from 'vuex';

export default {
  name: 'Upload',
  props: {
    url: {
      type: String,
      default: `${process.env.VUE_APP_API_URL}/document`,
    },
    type: {
      type: String,
      default: null,
      required: false,
    },
    /**
     * How many files at once the upload container can handle.
     * If null then no limit is given.
     */
    maxFiles: {
      type: Number,
      default: null,
      required: false,
    },
    timeout: {
      type: Number,
      default: 30000,
    },
  },
  data() {
    const self = this;

    return {
      resetToggle: false,
      dropzoneOptions: {
        url: this.url,
        thumbnailWidth: 150,
        maxFilesize: 100,
        maxFiles: this.maxFiles,
        dictDefaultMessage: this.$t('files.choose'),
        addRemoveLinks: true,
        init() {
          self.$watch(
            'resetToggle',
            () => {
              this.removeAllFiles(true);
            },
            { immediate: true }
          );
        },
        sending: (file, xhr) => {
          const _send = xhr.send;
          xhr.timeout = this.timeout;
          xhr.withCredentials = true;
          xhr.setRequestHeader('Koda-File-Name', file.name);
          xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);

          if (this.type !== null) {
            xhr.setRequestHeader('Content-Type', 'text/csv');
            xhr.setRequestHeader('Koda-File-Type', this.type);
          } else {
            xhr.setRequestHeader('Content-Type', file.type);
          }
          self.$emit('onUpload');
          // Source: https://stackoverflow.com/a/38734064/766120
          xhr.send = () => _send.call(xhr, file);
        },
        success(file, document) {
          file.id = document.id;
          self.$emit('onSuccess', document);
        },
        error(file, message) {
          self.$emit('onError', { file, message });
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['token']),
  },
  methods: {
    handleRemove(file) {
      this.$emit('remove', file.id);
    },
    reset() {
      this.resetToggle = !this.resetToggle;
    },
  },
};
</script>

<style>
.vue-dropzone {
  align-items: center;
  border: 1px dashed #c8c8c8;
  display: flex;
  justify-content: center;
}

.dropzone .dz-preview {
  border: 1px solid #ededed;
}
</style>
