import { QueryType } from "@/static/enums/QueryType";
import { QueryStatus } from "@/static/enums/QueryStatus";

export const TYPE_TARN_LIST = 6;

export const queryTypes: Record<QueryType, string> = {
  [QueryType.BANKACCOUNT]: 'BankAccount',
  [QueryType.ARREST]: 'Arest',
  [QueryType.ARREST_CANCEL]: 'Aresti katkestamine',
  [QueryType.ARREST_CHANGE]: 'Aresti muutmine',
  [QueryType.RECEIVED_RESPONSES]: 'Mulle saadetud asjad',
  [QueryType.DOCUMENT_TEMPLATE]: 'Dokumendi genereerimine',
  [QueryType.TARN_LIST]: 'TARN-i toimikud',
  [QueryType.OK_SYNC]: 'OK kasutajad',
  [QueryType.TARN_EDIT]: 'TARN-i muutmine',
  [QueryType.TARN_ADD]: 'TARN-i lisamine',
  [QueryType.TARN_NTK_ADD]: 'TARN-i NTK lisamine',
  [QueryType.TARN_BALANCE]: 'TARN-i toimiku kontroll',
  [QueryType.TARN_NTK_CHANGE]: 'TARN-i nõuete ja tasude muutmine',
  [QueryType.TYPE_TARN2_SYNC_FROM_TARN]: 'E-Täitur: täitemenetluse andmete sünkroonimine',
  [QueryType.TYPE_TARN2_OPEN]: 'E-Täitur: täitemenetluse alustamine',
  [QueryType.TYPE_TARN2_TRANSFER]: 'E-Täitur: täitemenetluse üleandmine',
  [QueryType.TYPE_TARN2_CLOSE]: 'E-Täitur: täitemenetluse lõpetamine',
  [QueryType.TYPE_TARN2_PARTIAL_CLOSE]: 'E-Täitur: täitemenetluse osaline lõpetamine',
  [QueryType.TYPE_TARN2_EDIT]: 'E-Täitur: täitemenetluse muutmine',
  [QueryType.TYPE_TARN2_STOP]: 'E-Täitur: täitemenetluse peatamine',
  [QueryType.TYPE_TARN2_STOP_CANCEL]: 'E-Täitur: täitemenetluse peatamise lõpetamine',
  [QueryType.TYPE_TARN2_REOPEN]: 'E-Täitur: täitemenetluse taasavamine',
};

export const queryStatuses: Record<QueryStatus, string> = {
  [QueryStatus.STATUS_CREATED]: 'Loodud',
  [QueryStatus.STATUS_SENT]: 'Saadetud',
  [QueryStatus.STATUS_RECEIVED]: 'Vastus saadud',
  [QueryStatus.STATUS_COMPLETED]: 'Valmis',
  [QueryStatus.STATUS_FAILED]: 'Ebaõnnestus',
  [QueryStatus.STATUS_REQUIRED]: 'Uuesti lisatud',
};
