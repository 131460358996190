import req from './lib/req';

export default {
  find(params = {}) {
    return req.get('/tag', {
      params: {
        ...params,
      },
    });
  },
  fetchTagCounts() {
    return req.get('/tag/counts');
  },
};
