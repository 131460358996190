<template>
  <FeatureFlag if-enabled="FF_FILTERS_V1">
    <ui-page flex-row>
      <template slot="primary">
        <Button
          :label="$t('actions')"
          :icon="actionIcon"
          icon-pos="right"
          class="p-button-secondary"
          :disabled="isImportLoading || exportButtonLoading || excelButtonLoading"
          style="margin-right: 10px"
          @click="toggleActions"
        />
        <tiered-menu
          id="listMenu"
          ref="listMenu"
          :model="items"
          :popup="true"
          append-to="body"
        />
        <router-link v-if="!isRoleKoda()" :to="{ name: 'CaseNew' }">
          <Button icon="pi pi-plus" :label="$t('case.new')" icon-pos="right" />
        </router-link>
      </template>

      <div class="case-list--filters">
        <ui-side-panel icon="search" title="Otsing">
          <sui-form @submit.prevent="">
            <sui-form-field>
              <label v-html="$t('case.search.general')" />
              <sui-input v-model="searchValue" class="fluid" @input="handleSearchChange" />
            </sui-form-field>
          </sui-form>

          <Filters
            ref="filters"
            @change="handleFilterChange"
            @enterPressed="handleEnterPressed"
          />
        </ui-side-panel>

        <a href="#" @click.prevent="resetFiltersAndSearch">
          {{ $t('case.table.reset') }}
        </a>
      </div>

      <div class="case-list--content">
        <transfer-case-table
          v-if="transfers.length"
          :transfers="transfers"
        ></transfer-case-table>
        <h3 v-if="transfers.length">Toimikud</h3>
        <DataTable
          :value="caseList"
          responsive-layout="scroll"
          :loading="isLoading"
          :row-hover="true"
          :paginator="true"
          data-key="id"
          :scrollable="true"
          scroll-height="500px"
          :rows="perPage"
          :lazy="true"
          :total-records="totalRecords"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[10, 25, 50, 100, 500]"
          class="p-datatable-striped"
          @page="onPage($event)"
          @sort="onSort($event)"
        >
          <Column
            v-for="(col, index) of columns"
            :key="col.field + '_' + index"
            :field="col.field"
            :header="col.header"
            :header-style="col.isMoneyField ? 'width: 6rem;' : ''"
          >
            <template v-if="col.field === 'status'" #body="slotProps">
              <p v-if="!showStatusError(slotProps.data)">
                {{ getStatusText(slotProps.data.status) }}
              </p>
              <div
                v-else
                v-tooltip="getStatusTooltip(slotProps.data)"
                class="caselist-tooltip"
              >
                {{ getStatusText(slotProps.data.status) }}
                <i style="color: red" class="pi pi-question-circle"></i>
              </div>
            </template>
            <template v-else-if="col.field === 'number'" #body="slotProps">
              <router-link
                v-if="slotProps.data.tenant_id === tenant_id"
                :to="`/case/${slotProps.data.id}`"
                target="_blank"
              >
                {{ slotProps.data.number }}
              </router-link>
              <p v-else>
                {{ slotProps.data.number }}
              </p>
            </template>
            <template v-else-if="col.field === 'tenant'" #body="slotProps">
              <template v-if="slotProps.data.tenant">{{
                slotProps.data.tenant.name
              }}</template>
            </template>
            <template v-else #body="slotProps">
              <template v-if="col.isMoneyField">
                {{ getMonetaryValue(slotProps.data[col.field]) }}
              </template>
              <template v-else>
                {{ slotProps.data[col.field] }}
              </template>
            </template>
          </Column>
        </DataTable>
      </div>
      <transfer-case-modal
        :cases="shownCases"
        :open="isTransferCaseModalOpen"
        @onTransferSuccess="toggleTransferCaseModal"
        @onToggle="toggleTransferCaseModal"
      />
      <CsvImportModal
        :open="csvImportModalOpen"
        @onToggle="toggleCsvImportModal"
      ></CsvImportModal>
      <DeliveryImportModal
        :open="deliveryImportModalOpen"
        @onToggle="toggleDeliveryImportModal"
      />
    </ui-page>
  </FeatureFlag>
</template>
<script>
import { mapGetters } from 'vuex';
import api from '../../api';
import pageNameSync from '../../mixins/pageNameSync';
import Filters from './Filters.vue';
import { getStatusText } from '@/mixins/case';
import { showErrorNotification, showSuccessNotification } from '@/mixins/notification';
import { CaseStatus } from '@/static/enums/caseStatus';
import { getTenants, removeEmptyValues } from '@/mixins/util';
import { getFormattedDateTimeWithMidnight } from '@/mixins/dateTime';
import TransferCaseTable from '../../components/Case/TransferCaseTable.vue';
import TransferCaseModal from '../../components/Case/TransferCaseModal.vue';
import CsvImportModal from '../../components/CaseListLegacy/CsvImportModal.vue';
import DeliveryImportModal from '../../components/CaseListLegacy/DeliveryImportModal.vue';
import FeatureFlag from '@/components/FeatureFlag.vue';
import TieredMenu from 'primevue/tieredmenu';
export default {
  components: {
    FeatureFlag,
    Filters,
    TransferCaseTable,
    TransferCaseModal,
    CsvImportModal,
    DeliveryImportModal,
    TieredMenu,
  },
  mixins: [pageNameSync],
  beforeRouteUpdate(to, from, next) {
    const { search } = to.params;

    if (search) {
      this.search = search;
      this.handleSearchChange();
    }

    next();
  },
  data() {
    return {
      items: [
        {
          label: 'Import',
          icon: 'pi pi-fw pi-cloud-upload',
          items: [
            {
              label: 'CSV',
              icon: 'pi pi-fw pi-file',
              command: () => this.toggleCsvImportModal(),
            },
            {
              label: 'Kättetoimetamised',
              icon: 'pi pi-fw pi-file',
              command: () => this.toggleDeliveryImportModal(),
            },
          ],
        },
        {
          label: this.$t('export'),
          icon: 'pi pi-fw pi-cloud-download',
          items: [
            {
              label: 'CSV',
              icon: 'pi pi-fw pi-file',
              command: () => this.handleCsvExport(),
            },
            {
              label: 'Excel',
              icon: 'pi pi-fw pi-file-excel',
              command: () => this.handleExcel(),
            },
          ],
        },
        {
          label: 'Toimiku üleandmine',
          icon: 'pi pi-fw pi-arrows-h',
          command: () => this.handleCaseTransfer(),
          disabled: !this.isRoleKT,
        },
      ],
      caseList: [],
      totalRecords: 0,
      isErrorLogViewModalOpen: false,
      perPage: 25,
      page: 1,
      sortField: 'number',
      sortOrder: -1,
      csvImportModalOpen: false,
      deliveryImportModalOpen: false,
      shownCases: [],
      transfers: [],
      searchTimeout: null,
      filters: {
        status: CaseStatus.OPEN,
      },
      exportButtonDisabled: false,
      exportButtonLoading: false,
      excelButtonDisabled: false,
      excelButtonLoading: false,
      caseTransferLoading: false,
      isTransferCaseModalOpen: false,
      isImportLoading: false,
      isLoading: true,
      personList: [],
      columns: [
        { field: 'status', header: this.$t('case.table.status') },
        { field: 'number', header: this.$t('case.table.caseNumber') },
        { field: 'main_claim_type', header: this.$t('case.table.claimType') },
        { field: 'claimant_name', header: this.$t('case.table.claimant') },
        {
          field: 'claimant_reg_code',
          header: this.$t('case.table.claimantCode'),
        },
        { field: 'debtor_name', header: this.$t('case.table.debtor') },
        { field: 'debtor_reg_code', header: this.$t('case.table.debtorCode') },
        {
          field: 'total_claims',
          header: this.$t('case.table.claimSum'),
          isMoneyField: true,
        },
        {
          field: 'balance',
          header: this.$t('case.table.balance'),
          isMoneyField: true,
        },
        {
          field: 'start_fee',
          header: this.$t('case.table.startFee'),
          isMoneyField: true,
        },
        {
          field: 'issuer_fee',
          header: this.$t('case.table.issuerFee'),
          isMoneyField: true,
        },
        {
          field: 'table_fee',
          header: this.$t('case.table.tableFee'),
          isMoneyField: true,
        },
      ],
      tenantColumn: { field: 'tenant', header: this.$t('case.table.tenant') },
    };
  },
  computed: {
    ...mapGetters('auth', ['tenant_id']),
    ...mapGetters('app', ['search']),
    searchValue: {
      get() {
        return this.search;
      },
      set(value) {
        this.$store.commit('app/updateSearch', value);
      },
    },
    actionIcon() {
      if (this.isImportLoading || this.exportButtonLoading || this.excelButtonLoading) {
        return 'pi pi-spin pi-spinner';
      }
      return 'pi pi-chevron-down';
    },
  },
  mounted() {
    this.getCaseList();
    this.getTransferList();
  },
  beforeUpdate() {
    const queryParams = {};
    if (this.filters.all) {
      queryParams.search = this.filters.all;
    }
    if (
      queryParams &&
      Object.keys(queryParams).length !== 0 &&
      queryParams.constructor === Object
    ) {
      this.$router.push({ name: 'CaseListLegacy', query: queryParams });
    }
  },
  methods: {
    isRoleKoda() {
      return this.$store.getters['user/isRoleKoda'];
    },
    isRoleKT() {
      return this.$store.getters['user/isRoleKT'];
    },
    /**
     * Fetch case transfer list
     */
    getTransferList() {
      api.caseTransfer.fetchAll().then((transfers) => {
        this.transfers = transfers;
      });
    },
    async getCaseList() {
      this.isLoading = true;
      api.cases
        .findByParams(this.getParams())
        .then(async (response) => {
          const { data } = response;
          if (this.filters.includeOthers) {
            const tenantIds = [...data.items.map((item) => item.tenant_id)];
            const tenants = await getTenants([...new Set(tenantIds)]);
            data.items = data.items.map((item) => ({
              ...item,
              tenant: tenants.items.find((tenant) => tenant.id === item.tenant_id),
            }));
          }
          this.caseList = data.items;
          this.page = data.current_page;
          this.totalRecords = data.total_item_count;
        })
        .finally(() => {
          this.isLoading = false;
          if (this.filters.includeOthers && !this.columns.includes(this.tenantColumn)) {
            this.columns.splice(1, 0, this.tenantColumn);
          } else if (
            !this.filters.includeOthers &&
            this.columns.indexOf(this.tenantColumn) !== -1
          ) {
            this.columns.splice(this.columns.indexOf(this.tenantColumn));
          }
        });
    },
    toggleCsvImportModal() {
      this.csvImportModalOpen = !this.csvImportModalOpen;
    },
    toggleDeliveryImportModal() {
      this.deliveryImportModalOpen = !this.deliveryImportModalOpen;
    },
    getParams() {
      const queryParams = {
        group: 'case_table',
        limit: this.perPage,
        page: this.page,
        sort:
          this.sortField !== '' && this.sortField !== null ? `c.${this.sortField}` : null,
        direction: this.sortOrder === 1 ? 'ASC' : 'DESC',
        ...this.getFilters(),
      };

      if (this.searchValue) {
        queryParams.xsearch = this.searchValue;
      }
      return queryParams;
    },
    getFilters() {
      const {
        archived,
        changed,
        includeOthers,
        // eslint-disable-next-line camelcase
        date_opened_until,
        ...rest
      } = this.filters;
      const filters = removeEmptyValues(rest);
      if (
        !archived &&
        (this.filters.status === null || typeof this.filters.status === 'undefined')
      ) {
        const { ARCHIVED, ...other } = CaseStatus;

        filters.status = Object.values(other);
      }
      // eslint-disable-next-line camelcase
      if (date_opened_until) {
        filters.date_opened_until = getFormattedDateTimeWithMidnight(date_opened_until);
      }
      if (changed) {
        filters.changed = 1;
      }
      if (includeOthers) {
        filters.include_others = 1;
      }
      if (filters.status === CaseStatus.OPEN) {
        filters.status = [CaseStatus.OPEN, CaseStatus.PARTIALLY_CLOSED];
      }

      return filters;
    },
    getMonetaryValue(value) {
      if (value && value.cents !== null && typeof value !== 'undefined') {
        return value.cents / 100;
      }

      return null;
    },
    resetFiltersAndSearch() {
      this.$store.commit('app/updateSearch', null);
      this.$refs.filters.reset();

      this.getCaseList();
    },
    async handleCsvExport() {
      this.exportButtonDisabled = true;
      this.exportButtonLoading = true;
      const queryParams = {
        group: 'id',
        limit: this.totalRecords + 1,
        ...this.getFilters(),
        ...(this.searchValue && { xsearch: this.searchValue }),
      };
      const { data } = await api.cases.findByParams(queryParams);
      const ids = data.items.map((enforcementCase) => enforcementCase.id);
      api.document
        .exportCsv(ids)
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'text/csv' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = response.headers['file-name'];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          showSuccessNotification({ text: 'Eksport õnnestus' });
        })
        .catch(() => {
          showErrorNotification({ text: 'Eksport ebaõnnestus' });
        })
        .finally(() => {
          this.exportButtonDisabled = false;
          this.exportButtonLoading = false;
        });
    },
    async handleExcel() {
      this.excelButtonDisabled = true;
      this.excelButtonLoading = true;
      const queryParams = {
        group: 'id',
        limit: this.totalRecords + 1,
        ...this.getFilters(),
        ...(this.searchValue && { xsearch: this.searchValue }),
      };
      const { data } = await api.cases.findByParams(queryParams);
      const ids = data.items.map((enforcementCase) => enforcementCase.id);

      api.document
        .exportExcel(ids)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = response.headers['file-name'];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          showSuccessNotification({ text: 'Eksport õnnestus' });
        })
        .catch(() => {
          showErrorNotification({ text: 'Eksport ebaõnnestus' });
        })
        .finally(() => {
          this.excelButtonLoading = false;
          this.excelButtonDisabled = false;
        });
    },
    handleFilterChange(filters) {
      if (filters) {
        this.filters = filters;
        this.getCaseList();
      }
    },
    handleSearchChange() {
      this.isLoading = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getCaseList();
      }, 600);
    },
    async handleCaseTransfer() {
      const queryParams = {
        where: this.getFilters(),
        ...(this.searchValue && { xsearch: this.searchValue }),
      };
      const { data } = await api.cases.findByParams(queryParams);
      this.shownIds = [];
      data.items.forEach((item) => {
        this.shownIds.push(item.id);
      });
      this.toggleTransferCaseModal();
    },
    toggleTransferCaseModal() {
      this.isTransferCaseModalOpen = !this.isTransferCaseModalOpen;
    },
    onPage(event) {
      this.perPage = event.rows;
      this.sortField = event.sortField ?? 'number';
      this.sortOrder = event.sortOrder ?? 0;
      this.page = event.page ? Number(event.page) + 1 : 1;
      this.getCaseList();
    },
    onSort(event) {
      this.sortField = event.sortField ?? 'number';
      this.sortOrder = event.sortOrder ?? 1;
      this.getCaseList();
    },
    handleEnterPressed() {
      if (this.caseList.length !== 1 || !this.caseList[0].id) {
        return;
      }
      this.$router.push({ name: 'Case', params: { id: this.caseList[0].id } });
    },
    showStatusError(caseObj) {
      return !caseObj.taitis_number || caseObj.last_transition_error;
    },
    getStatusTooltip(caseObj) {
      if (caseObj.last_transition_error) {
        return caseObj.last_transition_error;
      }

      return 'Toimikul puudub TARN-i ID';
    },
    getStatusText,
    toggleActions(event) {
      this.$refs.listMenu.toggle(event);
    },
  },
};
</script>
<style>
.case-list--filters {
  min-width: 200px;
  width: 200px;
}
.case-list--content {
  width: 100%;
  margin-left: 1rem;
}
.ui.form .field > label.secondary-label {
  font-style: italic;
  font-weight: normal;
}

.header-button {
  display: inline-block;
}

.inputfile + label {
  background-color: #e0e1e2;
  border-radius: 0.28571429rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  padding: 0.7em 1.5em;
}

.case-list--filters a {
  display: block;
  text-align: center;
}
#listMenu .p-submenu-list {
  right: 100%;
  left: unset;
}
</style>
