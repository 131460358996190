import req from './lib/req';

export default {
  reOpenCase(id) {
    return req.post(`/case/${id}/re-open`);
  },
  update(id, data) {
    return req.put(`/case/${id}`, data);
  },
  transfer(caseId, personId) {
    return req.post(`/case/${caseId}/transfer`, { newPerson: personId });
  },
  fetchAll() {
    return req.get('/case');
  },
  sendToTarn(caseId) {
    return req.post(`/case/${caseId}/add-tarn`);
  },
};
