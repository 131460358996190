<template>
  <span class="currency-input-number">
    <InputNumber
      v-model="model"
      :disabled="disabled"
      :class="{
        'currency-input-number__input': true,
        'p-invalid': invalid,
      }"
      mode="currency"
      currency="EUR"
      locale="et-ee"
      @input="handleInput"
      @focus="selectAllText"
    />
    <a
      v-if="allowClearValue"
      class="currency-input-number__clear"
      @click="clearValue"
    >
      <i v-tooltip.bottom="'Tühjenda'" class="pi pi-times" />
    </a>
  </span>
</template>

<script>
export default {
  name: 'CurrencyInputNumber',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    /**
     * Whether the input contains invalid value.
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the input is allowed be able to clear the
     * current value if needed (setting the value to null).
     */
    allowClearValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: '',
      oldModel: '',
      tarnId: null,
    };
  },
  watch: {
    value(value) {
      this.getModel(value);
    },
  },
  created() {
    this.getModel(this.value);
  },
  methods: {
    getModel(value) {
      if (value) {
        this.model = Number.isInteger(value.cents) ? value.cents / 100 : 0;
        this.tarnId = value.tarn_id;
      }
      if (!value) {
        this.model = 0;
      }
    },
    handleInput(value) {
      if (!value) {
        this.updateValue(0);
        return;
      }
      if (!Number.isNaN(this.model)) {
        this.updateValue(this.model);
      }
    },
    updateValue(value) {
      this.$emit('input', {
        cents: Math.round(value * 100),
        currency: 'EUR',
        tarn_id: this.tarnId,
      });
    },
    /**
     * This is to make the currency input easier to use.
     * 90% of the time user would likely want to write a new sum into the field
     * but after selecting the white part of input the cursor would stay at end
     * of input and writing a new sum in not possible in that position.
     */
    selectAllText() {
      const numberInput = this.$el.querySelector('input');
      if (numberInput instanceof HTMLInputElement) {
        numberInput.select();
      }
    },
    clearValue() {
      this.$emit('input', null);
    },
  },
};
</script>
<style lang="scss" scoped>
.currency-input-number {
  display: flex;
}
.currency-input-number__input {
  width: 100%;
}

.currency-input-number__clear {
  cursor: pointer;

  > i {
    padding: 0.25rem;
    margin: 8px 0 0 -24px;
    font-size: 10px;
  }
}
</style>
