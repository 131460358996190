/**
 * Function to fetch file and download it in browser.
 */
export async function downloadFile(fetchFileFn: () => Promise<Blob>, fileName: string): Promise<void>
{
    fetchFileFn().then((blob) => {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = fileName;
        link.dispatchEvent(new MouseEvent('click'));
    });
}
