import VueI18n from 'vue-i18n';

import { CaseArrestsFilterComparison } from '@/api/apiPlatform/CaseArrestsFilterComparison';

export type ComparisonOption = {
  label: string;
  value: CaseArrestsFilterComparison;
};

/**
 * Returns comparison operator options for CaseArrestsCountFilterPicker.
 * Each comparison uses a different operator to compare counts.
 */
export function getComparisonOptions(i18n: VueI18n): ComparisonOption[] {
  return [
    {
      label: i18n
        .t('caseArrestsCountFilterPicker.comparison.equalTo')
        .toString(),
      // Comparison used - "count = [actual_count]".
      value: CaseArrestsFilterComparison.EQUAL_TO,
    },
    {
      label: i18n
        .t('caseArrestsCountFilterPicker.comparison.greaterThan')
        .toString(),
      // Comparison used - "count < [actual_count]".
      value: CaseArrestsFilterComparison.GREATER_THAN,
    },
    {
      label: i18n
        .t('caseArrestsCountFilterPicker.comparison.lessThan')
        .toString(),
      // Comparison used - "count > [actual_count]".
      value: CaseArrestsFilterComparison.LESS_THAN,
    },
  ];
}
