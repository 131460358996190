<template>
  <div ref="claim">
    <div class="claim__types">
      <Radio
        v-model="model.type"
        :choices="typeOptions"
        :disabled="disabled || isStopped()"
        @input="handleInput"
      ></Radio>
    </div>

    <SubClaim
      :data="model"
      :disabled="disabled || isStopped()"
      :claim-type-options="claimTypeOptions"
      @update="onUpdate"
    />

    <Sum :data="model" :disabled="disabled || isStopped()" @update="onUpdate" />

    <Interest
      :data="model.surchargeData"
      :disabled="disabled || isStopped()"
      @update="onUpdateSurcharge"
    />
    <StopCaseModal
      :case-id="model.enforcement_case_id"
      :open="caseStopModalOpen"
      @onStoppingFail="showError"
      @onStoppingSuccess="markStopped"
      @onToggle="toggleStopCaseModal"
    />
    <div v-if="!disabled" class="claim-remove__button">
      <sui-button
        v-if="canStop()"
        color="red"
        size="tiny"
        @click.prevent="toggleStopCaseModal"
      >
        {{ $t('claim.stop') }}
      </sui-button>
      <ReOpenPopover
        v-if="isStopped()"
        :text="$t('claim.reopen')"
        @confirm="reopen"
      />
      <DeletePopover :text="$t('claim.remove')" @confirm="remove" />
    </div>
  </div>
</template>

<script>
import DeletePopover from '../../DeletePopover.vue';
import ReOpenPopover from '../../ReOpenPopover.vue';
import Interest from './Interest.vue';
import SubClaim from './SubClaim.vue';
import Sum from './Sum.vue';
import Radio from '../../Radio.vue';
import {
  showSuccessNotification,
  showErrorNotification,
} from '@/mixins/notification';
import StopCaseModal from '../StopCaseModal.vue';
import { Status as ClaimStatus } from '@/static/enums/claim';
import api from '../../../api';
import { SettingType } from '@/static/enums/settingType';

export default {
  name: 'Claim',
  components: {
    DeletePopover,
    ReOpenPopover,
    Interest,
    Radio,
    SubClaim,
    Sum,
    StopCaseModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    claimTypeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      caseStopModalOpen: false,
      claimMainTypes: null,
      model: {
        dueDate: 0,
        mainClaim: true,
        type: null,
        sumCharged: {
          cents: 0,
          currency: 'EUR',
          tarnId: null,
        },
        sumFull: {
          cents: 0,
          currency: 'EUR',
          tarnId: null,
        },
        sumPeriodical: {
          cents: 0,
          currency: 'EUR',
          tarnId: null,
        },
        periodicAmountAlimony: {
          cents: 0,
          currency: 'EUR',
          tarnId: null,
        },
        content: '',
        surchargeData: {
          calculated: false,
          sum_base: {},
          type: 0,
        },
      },
    };
  },
  computed: {
    typeOptions: {
      get() {
        if (!this.claimMainTypes) return [];

        return this.claimMainTypes.value.map((type) => ({
          label: type.display_name,
          value: type.key,
        }));
      },
    },
  },
  created() {
    Promise.all([
      api.settings.fetchByType(
        this.$store.getters['auth/tenant_id'],
        SettingType.CLAIM_TYPE
      ),
    ]).then(([claimMainTypes]) => {
      [this.claimMainTypes] = claimMainTypes;
    });
    this.$watch(
      'data',
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.model = {
            ...this.model,
            ...data,
          };
        }
      },
      { immediate: true }
    );
  },
  mounted() {
    const firstElement = this.$refs.claim.querySelector('input');
    this.$emit('created', firstElement);
  },
  methods: {
    onUpdate(values) {
      this.model = { ...values };
      this.$emit('update', values);
    },
    onUpdateSurcharge(surchargeData) {
      this.model = {
        ...this.model,
        surchargeData,
      };

      this.$emit('update', this.model);
    },
    remove() {
      this.$emit('remove');
    },
    handleInput() {
      this.$emit('update', this.model);
    },
    isStopped() {
      return this.model.status === 1 || this.model.status === ''; // STATUS_STOPPED or null
    },
    reopen() {
      this.$emit('reopen');
    },
    showError() {
      showErrorNotification({
        title: this.$t('notification.caseCloseError'),
        text: this.$t('notification.caseCloseErrorDescription'),
      });
    },
    markStopped() {
      this.caseStopModalOpen = false;
      showSuccessNotification({
        title: this.$t('notification.caseStopSuccess'),
      });
      this.$emit('stop');
    },
    toggleStopCaseModal() {
      this.caseStopModalOpen = !this.caseStopModalOpen;
    },
    canStop() {
      return !this.isStopped() && this.model.status === ClaimStatus.ACTIVE;
    },
  },
};
</script>

<style scoped>
h2 {
  display: inline-block;
}

.claim__types {
  margin-top: -35px;
  text-align: right;
}

.claim-remove__button {
  margin-top: 1rem;
  text-align: right;
}
</style>
