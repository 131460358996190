/**
 * Type for a single option in MineWagePicker.
 */
export type MinWageOption = {
  key: number;
  value: string;
};

/**
 * Value to be shown when a null value is actually being passed through the picker.
 */
export const NULL_OPTION_VALUE = 'vastavalt toimikule';

export function getDefaultOptions(): MinWageOption[] {
  return [
    {
      key: 10,
      value: NULL_OPTION_VALUE,
    },
    {
      key: 1,
      value: '0',
    },
    {
      key: 2,
      value: '0.5',
    },
    {
      key: 3,
      value: '1',
    },
    {
      key: 4,
      value: '1.33333',
    },
    {
      key: 5,
      value: '1.66666',
    },
    {
      key: 6,
      value: '2',
    },
    {
      key: 7,
      value: '2.33333',
    },
    {
      key: 8,
      value: '2.66666',
    },
    {
      key: 9,
      value: '3',
    },
  ];
}
