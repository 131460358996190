import req from './lib/req';

export default {
  findOne(id) {
    return req.get(`/classifier-value/${id}`);
  },
  findAll() {
    return req.get('/classifier-value', {
      params: {
        'order[order_idx]': 'DESC',
      },
    });
  },
  findByClassifier(classifier) {
    const params = {
      classifier,
    };
    return req.get('/classifier-value', {
      params: {
        where: JSON.stringify(params),
        'order[order]': 'ASC',
      },
    });
  },
  update(classifierValue) {
    return req.put(`/classifier-value/${classifierValue.id}`, classifierValue);
  },
  create(classifierValue) {
    return req.post('/classifier-value', classifierValue);
  },
};
