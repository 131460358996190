<template>
  <sui-grid>
    <sui-grid-column :width="16" :large-screen="2" :widescreen="2">
      <h3>{{ $t('claim.interest') }}</h3>
    </sui-grid-column>

    <sui-grid-column :width="16" :large-screen="14" :widescreen="14">
      <div class="interest__types">
        <Radio
          v-model="model.type"
          :choices="interestOptions"
          :disabled="disabled"
          @input="handleInput"
        />
      </div>

      <sui-form-fields v-if="model.type === 1" :fields="4">
        <sui-form-field>
          <sui-checkbox
            v-model="model.calculated"
            :disabled="disabled"
            :label="$t('claim.interestAccounted')"
            @input="() => handleToggle('calculated')"
          />
        </sui-form-field>
      </sui-form-fields>

      <sui-form-fields v-if="model.type === 2" :fields="4">
        <sui-form-field>
          <label>{{ $t('claim.baseSum') }}</label>
          <Input
            v-model="sumBaseValue"
            :disabled="disabled"
            icon="euro sign"
            @input="handleInput"
          />
        </sui-form-field>

        <sui-form-field>
          <label>{{ $t('claim.interestUpperLimit') }}</label>
          <Input
            v-model="upperLimit"
            :disabled="disabled"
            icon="percent"
            @input="handleInput"
          />
        </sui-form-field>

        <sui-form-field>
          <label>{{ $t('claim.interestPerDay') }}</label>
          <Input
            v-model="dailyLimit"
            :disabled="disabled"
            icon="percent"
            @input="handleInput"
          />
        </sui-form-field>

        <sui-form-field>
          <label>{{ $t('claim.interestStartDate') }}</label>
          <DatePicker
            v-model="model.date_from"
            :disabled="disabled"
            @input="handleInput"
          />
        </sui-form-field>
      </sui-form-fields>
    </sui-grid-column>
  </sui-grid>
</template>

<script>
// @flow
import { mapGetters } from 'vuex';
import DatePicker from '../../DatePicker.vue';
import Input from '../../Input.vue';
import Radio from '../../Radio.vue';

export default {
  name: 'Interest',
  components: {
    DatePicker,
    Input,
    Radio,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('settings', ['claimSurchargeTypes']),
    interestOptions: {
      get() {
        if (this.claimSurchargeTypes) {
          return this.claimSurchargeTypes.value.map((type) => ({
            label: type.display_name,
            value: type.key,
          }));
        }
        return [];
      },
    },
    model: {
      get() {
        return { ...this.data };
      },
    },
    sumBaseValue: {
      get() {
        return this.model.sum_base && this.model.sum_base.cents
          ? this.model.sum_base.cents * 0.01
          : null;
      },
      set(newVal) {
        this.model.sum_base.cents = newVal * 100;
        this.model.sum_base.currency = 'EUR';
        this.$emit('update', this.model);
      },
    },
    dailyLimit: {
      get() {
        return this.model.daily_limit;
      },
      set(value) {
        this.model.daily_limit = parseInt(value, 10);
        this.$emit('update', this.model);
      },
    },
    upperLimit: {
      get() {
        return this.model.upper_limit;
      },
      set(value) {
        this.model.upper_limit = parseInt(value, 10);
        this.$emit('update', this.model);
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit('update', this.model);
    },
    handleToggle(name) {
      this.model[name] = !this.model[name];
      this.$emit('update', this.model);
    },
  },
};
</script>

<style scoped>
.interest__types {
  text-align: right;
}
</style>
