<template>
  <Dialog
    :header="$t('import.title')"
    :visible="open"
    :breakpoints="{ '960px': '60vw', '640px': '80vw' }"
    :container-style="{ width: '80rem' }"
    :closable="false"
  >
    <div class="p-grid">
      <div :class="hasErrors || hasWarnings ? 'p-col-4' : 'p-col-12'">
        <sui-message
          v-if="hasErrors"
          color="red"
          :content="$t('import.errorsFoundContent').toString()"
          :header="$t('import.errorsFoundTitle').toString()"
        />

        <sui-message
          v-if="hasWarnings"
          color="yellow"
          :content="$t('import.warningsFoundContent').toString()"
          :header="hasErrors ? $t('import.warningsFoundTitle').toString() : $t('import.warningsFoundAndImportAcceptedTitle').toString()"
        />

        <sui-form>
          <sui-form-field>
            <sui-dropdown
              v-model="selectedType"
              class="csv-import-modal__dropdown"
              :options="csvTypes"
              fluid
              search
              selection
            />
          </sui-form-field>
        </sui-form>
        <Upload
          ref="uploadContainer"
          :url="apiUrl"
          :type="selectedType"
          @onSuccess="handleFileUpload"
          @onError="handleUploadError"
        />
      </div>
      <div v-if="hasErrors || hasWarnings" class="p-col-8">
        <ScrollPanel
          style="width: 100%; height: 500px"
          class="import-error-box"
        >
          <!-- ERRORS TABLE  -->
          <sui-table v-if="hasErrors" class="csv-import-modal__errors" celled>
            <sui-table-header>
              <sui-table-header-cell :width="1"> Rida </sui-table-header-cell>
              <sui-table-header-cell :width="1"> Toimik </sui-table-header-cell>
              <sui-table-header-cell :width="1"> Tulp </sui-table-header-cell>
              <sui-table-header-cell :width="15"> Viga </sui-table-header-cell>
            </sui-table-header>
            <sui-table-body>
              <sui-table-row
                v-for="(error, key) in errors"
                :key="key"
                class="csv-import-modal__row"
              >
                <sui-table-cell class="csv-import-modal__cell csv-import-modal__cell--row-nr">
                  {{ error.row }}
                </sui-table-cell>
                <sui-table-cell class="csv-import-modal__cell">
                  {{ error.case_number }}
                </sui-table-cell>
                <sui-table-cell class="csv-import-modal__cell csv-import-modal__cell--column">
                  {{ error.column }}
                </sui-table-cell>
                <sui-table-cell class="csv-import-modal__cell">
                  {{ error.message }}
                </sui-table-cell>
              </sui-table-row>
            </sui-table-body>
          </sui-table>
          <!-- ERRORS TABLE  -->

          <!-- WARNINGS TABLE  -->
          <sui-table v-if="hasWarnings" class="csv-import-modal__warnings" celled>
            <sui-table-header>
              <sui-table-header-cell :width="1"> Rida </sui-table-header-cell>
              <sui-table-header-cell :width="1"> Toimik </sui-table-header-cell>
              <sui-table-header-cell :width="1"> Tulp </sui-table-header-cell>
              <sui-table-header-cell :width="15"> Hoiatus </sui-table-header-cell>
            </sui-table-header>
            <sui-table-body>
              <sui-table-row
                v-for="(warning, key) in warnings"
                :key="key"
                class="csv-import-modal__row"
              >
                <sui-table-cell class="csv-import-modal__cell csv-import-modal__cell--row-nr">
                  {{ warning.row }}
                </sui-table-cell>
                <sui-table-cell class="csv-import-modal__cell">
                  {{ warning.case_number }}
                </sui-table-cell>
                <sui-table-cell class="csv-import-modal__cell csv-import-modal__cell--column">
                  {{ warning.column }}
                </sui-table-cell>
                <sui-table-cell class="csv-import-modal__cell">
                  {{ warning.message }}
                </sui-table-cell>
              </sui-table-row>
            </sui-table-body>
          </sui-table>
          <!-- WARNINGS TABLE  -->
        </ScrollPanel>
      </div>
    </div>

    <template #footer>
      <Button
        v-if="hasErrors || hasWarnings"
        :label="$t('import.downloadErrors')"
        icon="pi pi-download"
        autofocus
        @click="downloadErrorsAndWarningsAsCsv"
      />
      <Button
        :label="$t('close')"
        icon="pi pi-check"
        autofocus
        @click="toggleModal"
      />
    </template>
  </Dialog>
</template>
<script>
import { DateTime } from 'luxon';
import Upload from '../Upload.vue';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../mixins/notification';
import { downloadTextAsFile } from '@/utils/downloadTextAsFile';

export default {
  name: 'CsvImportModal',
  components: {
    Upload,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedType: 'taavi',
      csvTypes: [
        { key: 'taavi', value: 'taavi', text: 'Taavi' },
        { key: 'etaitur', value: 'etaitur', text: 'ETäitur' },
      ],
      apiUrl: `${process.env.VUE_APP_API_URL}/import/taavi`,
      errors: [],
      warnings: [],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
    hasErrors() {
      return this.errors && this.errors.length > 0;
    },
    hasWarnings() {
      return this.warnings && this.warnings.length > 0;
    },
  },
  methods: {
    toggleModal() {
      this.errors = [];
      this.warnings = [];
      this.isOpen = !this.isOpen;
    },
    handleFileUpload(response) {
      this.errors = [];
      this.warnings = [];
      try {
        if (response.warnings !== undefined) {
          this.warnings = response.warnings;
        }
      } catch (e) {
        this.warnings = [];
      }
      this.$refs.uploadContainer.reset();

      showSuccessNotification({ text: 'CSV fail on töösse võetud' });
    },
    handleUploadError(data) {
      const { message } = data;
      this.$refs.uploadContainer.reset();

      try {
        const resp = JSON.parse(message.message);
        this.errors = resp.error;
        this.warnings = resp.warning;
      } catch (e) {
        showErrorNotification({ text: message.message });
      }
    },
    downloadErrorsAndWarningsAsCsv() {
      // Combine errors and warnings together.
      // This ensures that errors/warnings of same row
      // are grouped together.
      const violations = [
        ...(this.errors || []),
        ...(this.warnings || []),
      ].sort((a, b) => a.row - b.row);

      let outputCsv = 'Rida;Toimik;Tulp;Probleem';

      // Add csv line to final output for each violation message.
      violations.forEach((violation) => {
        const { row, column, case_number, message } = violation;

        const newLine = `${row};${case_number};${column};${message}`;
        outputCsv = [outputCsv, newLine].join('\n');
      });

      // Download text as csv file.
      const now = DateTime.now().toFormat('yyyyMMdd_HHmmss');
      const filename = `${this.selectedType}_import_vead_${now}.csv`;

      downloadTextAsFile(outputCsv, filename, 'text/csv');
    },
  },
};
</script>
<style lang="scss" scoped>
.ui.table thead {
  position: sticky;
  top: 0;
}

.csv-import-modal__dropdown {
  margin-bottom: 1rem;
}

.csv-import-modal__cell {
  vertical-align: top;
  line-height: 16px !important;
}

.csv-import-modal__cell--row-nr {
  text-align: center !important;
  font-weight: 600;
}

.csv-import-modal__cell--column {
  white-space: nowrap;
}

.csv-import-modal__errors thead th {
  background-color: #ffe8e6;
}

.csv-import-modal__errors tbody {
  background-color: mix(#ffe8e6, #ffffff, 15%);
}

.csv-import-modal__warnings thead th {
  background-color: #fff8db;
}

.csv-import-modal__warnings tbody {
  background-color: mix(#fff8db, #ffffff, 15%);
}

::v-deep {
  .p-dialog-content {
    scrollbar-width: none;
  }
}
</style>
