import { render, staticRenderFns } from "./ColumnHeader.vue?vue&type=template&id=486956e6&scoped=true&"
import script from "./ColumnHeader.jsx?vue&type=script&lang=js&"
export * from "./ColumnHeader.jsx?vue&type=script&lang=js&"
import style0 from "./ColumnHeader.css?vue&type=style&index=0&id=486956e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486956e6",
  null
  
)

export default component.exports