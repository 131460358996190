<template>
  <Dialog
    :visible="isOpen"
    :closable="false"
    :header="$t('claim.status_change_modal_header')"
  >
    <Calendar
      id="dateExpire"
      v-model="date"
      :month-navigator="true"
      :show-time="true"
      :year-navigator="true"
      append-to="body"
      date-format="dd.mm.yy"
      year-range="1990:2030"
    />

    <template #footer>
      <Button
        :label="$t('close')"
        icon="pi pi-check"
        autofocus
        @click="toggleModal"
      />
    </template>
  </Dialog>
</template>
<script>
export default {
  name: 'ClaimStatusChangeDialog',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    claim: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      date: null,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
  },
  mounted() {
    this.date = new Date(this.claim.date_status_change);
  },
  beforeDestroy() {
    this.claim.date_status_change = new Date(
      this.date.getTime() - this.date.getTimezoneOffset() * 60000
    );
  },
  methods: {
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
