/**
 * Enums for TARN query types.
 * NB: Same values as in Enum/Tarn.php! Keep in sync.
 */
export enum TarnType {
  'TYPE_ACCEPT' = 1,
  'TYPE_TRANSFER' = 2,
  'TYPE_END' = 3,
  'TYPE_PARTIAL_END' = 4,
  'TYPE_EXPIRATION' = 5,
  'TYPE_CHANGE' = 6,
  'TYPE_STOP' = 7,
  'TYPE_STOP_CANCEL' = 8,
  'TYPE_REOPEN' = 9,
}
