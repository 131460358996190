import req from '../lib/req';

export function findByTag(tag) {
  const params = {
    tag,
  };
  return req.get('/classifier', {
    params: {
      where: JSON.stringify(params),
    },
  });
}
