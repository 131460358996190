<template>
  <sui-modal
    ref="modal"
    v-model="open"
    :closable="true"
    :size="size"
    :scrollable="scrollable"
  >
    <sui-modal-header>
      <slot name="modal-header" />
    </sui-modal-header>

    <sui-modal-content>
      <sui-segment
        :style="scrollable ? 'overflow: auto; max-height: 60vh' : ''"
      >
        <slot name="modal-content" />
      </sui-segment>
    </sui-modal-content>

    <sui-modal-actions>
      <slot name="modal-actions" />
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'standard',
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    open(open) {
      if (open) {
        document.addEventListener('mousedown', this.handleMouseDown);
      } else {
        document.removeEventListener('mousedown', this.handleMouseDown);
      }
    },
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleMouseDown);
  },
  methods: {
    handleMouseDown(event) {
      const modal = this.$refs.modal.$el.childNodes[0];

      if (!modal.contains(event.target)) {
        this.$emit('close');
      }
    },
  },
};
</script>
