import req from './lib/req';

export default {
  update(id, data) {
    return req.put(`/person/${id}`, data);
  },
  fetchKTs() {
    return req.get('/person/kt');
  },
};
