import req from '../lib/req';
import { Id } from '@/types/Id';
import { Bank } from '@/types/Bank';
import { AxiosResponse } from '@/types/AxiosResponse';

export function findAll(): Promise<Bank[]> {
  return req.get('/bank').then((res) => res.data.items);
}
export function findAllActive(): Promise<Bank[]> {
  return req.get('/bank?active=1').then((res) => res.data.items);
}
export function find(): Promise<Bank[]> {
  return req.get('/bank?order[idx]=asc&limit=50').then((res) => res.data.items);
}

export function findById(id: Id) {
  return req.get(`/bank/${id}`);
}

export function findBankAccountById(id: Id) {
  return req.get(`/bank-account/${id}`);
}

export function getAccounts(idList: Id[]) {
  const params = {
    id: idList,
  };
  return req.get('/bank-account', {
    params: {
      where: JSON.stringify(params),
    },
  });
}

export function create(data: unknown) {
  return req.post('/bank-account', data);
}

export function update(id: Id, data: unknown) {
  return req.put(`/bank-account/${id}`, data);
}

export function createBank(data: Bank): AxiosResponse<Bank> {
  return req.post('/bank', data);
}

export function updateBank(id: Id, data: Bank): AxiosResponse<Bank> {
  return req.put(`/bank/${id}`, data);
}

export function removeBank(id: Id) {
  return req.delete(`/bank/${id}`);
}
