import req from '../lib/req';

export function findByCaseId(caseId, params) {
  return req.get(`/claim?where={"enforcementCase":"${caseId}"}`, { params });
}
export function findForTransfer(caseId) {
  return req.get(`/case-transfer/claim/${caseId}`);
}
export function create(data) {
  return req.post('/claim', data);
}

export function findMainClaimByCaseId(caseId) {
  return req.get(
    `/claim?where={"enforcementCase": "${caseId}", "mainClaim": true}`
  );
}

export function update(id, data) {
  return req.put(`/claim/${id}`, data);
}

export function deleteClaim(id) {
  return req.delete(`/claim/${id}`);
}

export function reopen(id) {
  return req.post(`/claim/${id}/reopen`);
}

export function generateRefNo(claim) {
  return req.post(`/claim/${claim.id}/generate-ref-no`);
}
