import { CaseState } from '@/static/enums/caseState';

/**
 * Map of case states and whether the case is editable
 * in that given state.
 *
 * While the case is not editable then we also assume that we should
 * request the case from api again, in order to check whether
 * its state has changed.
 */
export const caseStatesEditable: Record<CaseState, boolean> = {
  [CaseState.DRAFT]: true,
  [CaseState.OPEN]: true,
  [CaseState.PROJECT]: true,
  [CaseState.TARN_ACCEPT]: false,
  [CaseState.UNMIGRATED]: true,
};
