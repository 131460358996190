import { Filter } from '@/api/modules/bulkActionTask';

/**
 * Returns default "empty" filters for bulk action tasks.
 */
export function createDefaultBulkActionTasksFilter(): Filter {
  return {
    status: null,
    searchTerm: '',
  };
}
