import { render, staticRenderFns } from "./RemoveArrestsButton.vue?vue&type=template&id=9cd84878&scoped=true&"
import script from "./RemoveArrestsButton.vue?vue&type=script&lang=ts&"
export * from "./RemoveArrestsButton.vue?vue&type=script&lang=ts&"
import style0 from "./RemoveArrestsButton.vue?vue&type=style&index=0&id=9cd84878&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cd84878",
  null
  
)

export default component.exports