<template>
  <Dialog
    :header="$t('case.close')"
    :visible="isOpen"
    :breakpoints="{ '960px': '40vw', '640px': '80vw' }"
    :style="{ width: '20vw' }"
    :closable="false"
  >
    <sui-message v-if="warning" negative dismissable @dismiss="clearWarning">
      {{ warning }}
    </sui-message>
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12">
        <label>Lõpetamise alus</label>
        <Dropdown
          v-model="closingBasis"
          :options="closingBases"
          option-label="name"
          data-key="id"
          placeholder="Lõpetamise alus"
          :filter="true"
          append-to="body"
          class="text-left"
          @change="handleClosingBasisSelect"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value" style="float: left">
              <span>{{ slotProps.value.name }}</span>
            </div>
            <span v-else style="float: left">
              {{ slotProps.placeholder }}
            </span>
          </template>
        </Dropdown>
      </div>
      <div v-if="usesExpireDate(closingBasis)" class="p-col-12 p-md-12">
        <label>Aegumise kuupäev</label>
        <Calendar
          id="dateExpire"
          v-model="dateExpire"
          :month-navigator="true"
          :show-time="false"
          :year-navigator="true"
          append-to="body"
          date-format="dd.mm.yy"
          year-range="1990:2030"
        />
      </div>
      <div v-else-if="usesDeathDate(closingBasis)" class="p-col-12 p-md-12">
        <label>Surma kuupäev</label>
        <Calendar
          v-model="dateDeath"
          :month-navigator="true"
          :show-time="false"
          :year-navigator="true"
          append-to="body"
          date-format="dd.mm.yy"
          year-range="1990:2030"
        />
      </div>
      <div class="p-col-12 p-md-12">
        <label>Lõpetamise kuupäev</label>
        <Calendar
          id="dateEnd"
          v-model="dateEnd"
          :month-navigator="true"
          :show-time="false"
          :year-navigator="true"
          append-to="body"
          date-format="dd.mm.yy"
          year-range="1990:2030"
        />
      </div>
      <div class="p-col-12 p-md-12">
        <label>{{ $t('case.info.closeExplanation') }}</label>
        <InputText v-model="closingExplanation" />
      </div>
      <div v-if="canPartiallyEnd" class="p-col-12 p-md-12">
        <sui-checkbox
          v-model="partialEnd"
          label="Lõpeta toimik osaliselt"
          @change="handlePartialEndCheckbox"
        />
      </div>
      <div v-if="canPartiallyEnd && partialEnd" class="p-col-12 p-md-12">
        <label>Osalise lõpetamise mall</label>
        <Dropdown
          v-model="partialCloseTemplate"
          :options="partialEndTemplates"
          option-label="key"
          data-key="id"
          :placeholder="$t('choose')"
          :filter="true"
          append-to="body"
          class="text-left"
        />
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('cancel')"
        icon="pi pi-times"
        class="p-button-text"
        @click="toggleModal"
      />
      <Button
        :label="$t('case.close')"
        icon="pi pi-check"
        autofocus
        :disabled="isLoading"
        @click="closeCase"
      />
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '../../api';
import { CaseStatus } from '@/static/enums/caseStatus';
import {
  META_DEATH_DEBTOR,
  META_DEATH_CLAIMANT,
  META_EXPIRE,
} from '@/static/enums/classifierValue';
import { SettingType } from '@/static/enums/settingType';
import { FileTypes } from '@/static/enums/settings';

export default {
  name: 'CloseCaseModal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: String,
      default: null,
    },
    case: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      closingBases: [],
      closingBasis: {
        id: 'da2c80b5-57d0-45ad-ba5e-994f9659134f',
        name: 'Aegumine',
        metadata: [{ key: 'expire', value: '1' }],
      },
      partialCloseTemplate: null,
      partialEndTemplates: [],
      dateExpire: new Date(),
      dateEnd: new Date(),
      dateDeath: null,
      closingExplanation: '',
      partialEnd: false,
    };
  },
  computed: {
    ...mapGetters('cases', ['hasError', 'error']),
    isLoading() {
      return this.loading;
    },
    partialEndTemplateList: {
      get() {
        if (!this.partialEndTemplates) return [];
        return this.partialEndTemplates.map((template) => ({
          text: template.key,
          value: template.id,
        }));
      },
    },
    isOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit('onToggle');
      },
    },
    canPartiallyEnd() {
      if (!this.closingBases) {
        return false;
      }
      const list = this.closingBases.filter(
        (item) => item.id === this.closingBasis.id
      );
      if (list.length === 0 || !list[0].metadata) {
        return false;
      }
      const metaList = list[0].metadata.filter(
        (item) => item.key === 'partial_end' && item.value
      );
      return metaList.length > 0;
    },
  },
  mounted() {
    Promise.all([
      api.classifier.findByTag('close_basis').then((response) => {
        api.classifierValueLegacy
          .findByClassifier(response.data[0].id)
          .then((resp) => {
            this.closingBases = resp.data.map((item) => ({
              id: item.id,
              name: item.name,
              metadata: item.metadata,
            }));
          });
      }),
      api.claim.findByCaseId(this.case.id).then((response) => {
        const mainClaims = response.data.filter((claim) => claim.mainClaim);
        if (mainClaims.length === null) {
          this.dateExpire = new Date();
          return;
        }
        mainClaims.sort(
          (a, b) => new Date(b.date_expire) - new Date(a.date_expire)
        );
        if (mainClaims[0].date_expire !== null) {
          this.dateExpire = new Date(mainClaims[0].date_expire);
          this.dateEnd = new Date(mainClaims[0].date_expire);
        }
      }),
    ]).then(() => (this.loading = false));
  },
  methods: {
    usesExpireDate(closeBasis) {
      if (!closeBasis.metadata) {
        return false;
      }
      return (
        closeBasis.metadata.filter(
          (metaItem) => metaItem.key === META_EXPIRE && metaItem.value === '1'
        ).length > 0
      );
    },
    usesDeathDate(closeBasis) {
      if (!closeBasis.metadata) {
        return false;
      }
      return (
        closeBasis.metadata.filter(
          (metaItem) =>
            (metaItem.key === META_DEATH_DEBTOR ||
              metaItem.key === META_DEATH_CLAIMANT) &&
            metaItem.value === '1'
        ).length > 0
      );
    },
    clearWarning() {
      this.warning = null;
    },
    closeCase() {
      this.loading = true;
      let status = 1;
      let newDateDeath = null;
      let newDateExpire = null;
      const newDateEnd = new Date(
        this.dateEnd.getTime() - this.dateEnd.getTimezoneOffset() * 60000
      );

      if (this.usesExpireDate(this.closingBasis)) {
        newDateExpire = new Date(
          this.dateExpire.getTime() -
            this.dateExpire.getTimezoneOffset() * 60000
        );
      } else if (this.usesDeathDate(this.closingBasis)) {
        newDateDeath = new Date(
          this.dateDeath.getTime() - this.dateDeath.getTimezoneOffset() * 60000
        );
      }

      if (this.partialEnd) {
        status = CaseStatus.PARTIALLY_CLOSED;
      }
      api.cases
        .close(this.case.id, {
          date_expire: newDateExpire ? newDateExpire.toISOString() : null,
          close_basis: this.closingBasis.id,
          close_explanation: this.closingExplanation,
          status,
          partial: this.partialEnd,
          date_closed: newDateEnd.toISOString(),
          date_death: newDateDeath ? newDateDeath.toISOString() : null,
          close_template: this.partialCloseTemplate
            ? this.partialCloseTemplate.id
            : null,
        })
        .then(() => {
          this.$emit('onClosingSuccess');
        })
        .catch((e) => {
          this.$emit('onClosingFail', e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleModal() {
      this.$emit('onToggle');
    },
    handleClosingBasisSelect(closeBasis) {
      if (!closeBasis.value.metadata) {
        return;
      }
      const metaData = closeBasis.value.metadata;
      if (
        metaData.filter(
          (meta) => meta.key === META_DEATH_CLAIMANT && meta.value === '1'
        ).length > 0
      ) {
        api.person.findById(this.case.claimant_id).then((response) => {
          if (response.data.date_death !== null) {
            this.dateDeath = new Date(response.data.date_death);
          }
        });
      }
      if (
        metaData.filter(
          (meta) => meta.key === META_DEATH_DEBTOR && meta.value === '1'
        ).length > 0
      ) {
        api.person.findById(this.case.debtor_id).then((response) => {
          if (response.data.date_death !== null) {
            this.dateDeath = new Date(response.data.date_death);
          }
        });
      }
    },
    handlePartialEndCheckbox() {
      if (!this.partialEnd || this.partialEndTemplates.length > 0) {
        return;
      }
      api.settings
        .fetchByType(
          this.$store.getters['auth/tenant_id'],
          SettingType.DOCUMENT_LAYOUT_ARREST
        )
        .then((response) => {
          const [templates] = response;
          if (templates.value.length === 0) {
            return;
          }
          this.partialEndTemplates = templates.value.filter(
            (template) => template.value.file_type === FileTypes.PARTIALLY_CLOSE
          );
        });
    },
  },
};
</script>
