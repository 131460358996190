import req from '@/api/lib/req';
import { ProblemType } from '@/static/enums/problemType';
import { Id } from '@/types';
import { Collection } from '@/types/ApiPlatform/Collection';
import { ProblemSaved } from '@/types/Problem';

/**
 * Finds a paged result of bulk actions from a single date.
 */
export async function findByCase(
  caseId: Id,
  page?: number,
  limit?: number,
): Promise<Collection<ProblemSaved>> {
  const allProblemTypes = Object.values(ProblemType).filter(
    (keyOrValue) => typeof keyOrValue === 'number'
  ) as ProblemType[];

  // This filter is required in order to trigger fetching of only relevant types of problems.
  // It does not matter which types are passed since they are ignored in back-end.
  // See ProblemTypesFilter.php for more.
  const typesFilter = `&type[]=${allProblemTypes.join('&type[]=')}`;

  return req
    .get(`/v2/problems?case=${caseId}&page=${page || 1}&limit=${limit || 50}${typesFilter}`)
    .then((resp) => resp.data);
}